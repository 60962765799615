import { ConfigurationAccordionItem } from "./ConfigurationAccordionItem";
import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { SidebarButtonExternal } from "@dashboard/common/Sidebar/SidebarButtonExternal";
import { Route } from "../_config-sidebar";
import {
  BookIcon,
  ShieldIcon,
  PackageSearchIcon,
  SplitIcon,
  BotIcon,
  GitBranchIcon,
  TagIcon,
} from "lucide-react";

export function AiAndAutomation() {
  const { businessSlug } = Route.useParams();
  const { organization, businesses, user, trpc } = Route.useRouteContext();

  const business = businesses.find((b) => b.slug === businessSlug);

  const renderSimpleFlows = !!business?.simpleFlowConfigAvailable;

  const renderAdvancedFlows = user.isAdmin || organization.enableAdvancedFlows;

  const [productCount] = trpc.dashboard.products.getCount.useSuspenseQuery({
    businessSlug,
  });

  const showProductSearch = productCount > 0;

  return (
    <ConfigurationAccordionItem value="ai" title="AI & Automation">
      <SidebarButton
        icon={BookIcon}
        text="Knowledge"
        to="/organization/$organizationSlug/settings/$businessSlug/knowledge"
      />
      {user.isAdmin && (
        <SidebarButton
          icon={TagIcon}
          text="Customer Tags"
          to="/organization/$organizationSlug/settings/$businessSlug/customer-tags"
        />
      )}
      {showProductSearch && (
        <SidebarButton
          icon={PackageSearchIcon}
          text="Product Search"
          to="/organization/$organizationSlug/settings/$businessSlug/product-search"
        />
      )}
      {business?.enableFlows && (
        <>
          {renderSimpleFlows && (
            <SidebarButton
              icon={SplitIcon}
              text="Simple Flows"
              to="/organization/$organizationSlug/settings/$businessSlug/simple-flows"
            />
          )}
          {renderAdvancedFlows && (
            <SidebarButton
              icon={GitBranchIcon}
              text="Advanced Flows"
              to="/organization/$organizationSlug/settings/$businessSlug/advanced-flows"
            />
          )}
          <SidebarButton
            icon={ShieldIcon}
            text="Threats"
            to="/organization/$organizationSlug/settings/$businessSlug/threats"
          />
        </>
      )}
      <SidebarButtonExternal
        icon={BotIcon}
        text="Bot Playground"
        to={`https://www.octocom.ai/try?bot-name=${businessSlug}`}
      />
    </ConfigurationAccordionItem>
  );
}
