import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { CreateViewDialogContent } from "./-components/CreateViewDialogContent";
import { useDeleteViews } from "./-hooks/useDeleteViews";
import { FilterKeys } from "@be/modules/dashboard/views/views.types";
import { EditViewDialogContent } from "./-components/EditViewDialogContent";
import { ViewDialog } from "./-components/ViewDialog";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/views/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.views.getAll.ensureData({
      organizationSlug,
    });

    await trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
      organizationSlug,
    });

    await trpcClientUtils.dashboard.conversations.getLanguages.ensureData({
      organizationSlug,
    });
  },
  component: ViewsPage,
});

function ViewsPage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [views] = trpc.dashboard.views.getAll.useSuspenseQuery({
    organizationSlug,
  });

  const { deleteViews } = useDeleteViews();

  const records = views.map((view) => {
    const filters: { key: FilterKeys; value: string }[] = [];
    for (const key in view.filters) {
      const value = view.filters[key as FilterKeys];

      if (value) {
        filters.push({
          key: key as FilterKeys,
          value,
        });
      } else if (key === "status") {
        filters.push({
          key: "status",
          value: "all",
        });
      }
    }

    return {
      id: view.id,
      title: view.title,
      filters,
      titleDialog: <ViewDialog title={view.title} filters={filters} />,
    };
  });

  return (
    <div className="flex h-full w-full flex-col">
      <Table
        title="Views"
        records={records}
        columns={[
          {
            header: "Title",
            accessor: "titleDialog",
          },
        ]}
        recordName={{ singular: "View", plural: "Views" }}
        addRecordDialogContent={(setOpen) => (
          <CreateViewDialogContent setOpen={setOpen} />
        )}
        editRecordDialogContent={(record, setOpen) => (
          <EditViewDialogContent {...record} setOpen={setOpen} />
        )}
        deleteRecords={deleteViews}
        noRecordsText="No views available"
      />
    </div>
  );
}
