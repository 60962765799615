import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { ConversationDetailSection } from "./ConversationDetailSection";
import { useConversation } from "../../-hooks/useConversation.hook";
import { useFeedback } from "../../-hooks/useFeedback";
import { format } from "date-fns";
import { env } from "@dashboard/env";
import { Route } from "../..";
import { useMemo } from "react";

export function DetailsAccordion() {
  const { businesses } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const conversation = useConversation();
  const feedback = useFeedback();

  const languages = [
    ...new Set(
      conversation.messages
        .map((m) => m.language?.toUpperCase())
        .filter((l): l is string => !!l),
    ),
  ];

  const business = useMemo(
    () => businesses.find((b) => b.id === conversation.businessId),
    [businesses, conversation.businessId],
  );

  if (!business) {
    throw new Error("Business not found");
  }
  return (
    <Accordion
      type="multiple"
      className="divide-gray-lightest w-full space-y-5 divide-y"
    >
      <AccordionItem value="conversation" className="border-b-0 !shadow-none">
        <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
          Conversation Details
        </AccordionTrigger>
        <AccordionContent>
          <ConversationDetailSection
            details={[
              {
                label: "Business:",
                value: business.name,
              },
              {
                label: "URL:",
                value: `${env.DASHBOARD_BASE_URL}/organization/${organizationSlug}/conversation/${conversation.publicId}`,
                copy: true,
              },
              {
                label: "ID:",
                value: conversation.publicId,
                copy: true,
              },
              ...(conversation.rating?.rating && conversation.rating.rating > 3
                ? [
                    {
                      label: "Customer rating:",
                      value: conversation.rating.rating,
                    },
                    ...(conversation.rating.comment
                      ? [
                          {
                            label: "Customer rating comment:",
                            value: conversation.rating.comment,
                          },
                        ]
                      : []),
                  ]
                : []),
              {
                label: "Conversation type:",
                value:
                  conversation.type.charAt(0).toUpperCase() +
                  conversation.type.slice(1),
              },
              {
                label: "Start date:",
                value: format(new Date(conversation.createdAt), "PPp"),
                copy: true,
              },
              ...(languages.length > 0
                ? [
                    {
                      label:
                        languages.length === 1 ? "Language:" : "Languages:",
                      value: languages.join(", "),
                    },
                  ]
                : []),
              {
                label: "Message count:",
                value: conversation.messages.length,
              },
              {
                label: "Channel:",
                value: conversation.latestChannel
                  .split("-")
                  .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                  .join(" "),
              },
              {
                label: "Is closed:",
                value: conversation.closedAt ? "Yes" : "No",
              },
              ...(conversation.closedAt
                ? [
                    {
                      label: "Closed at:",
                      value: format(new Date(conversation.closedAt), "PPp"),
                      copy: true,
                    },
                  ]
                : []),
              {
                label: "Is handed off:",
                value: conversation.handedOffAt ? "Yes" : "No",
              },
              ...(conversation.handedOffAt
                ? [
                    {
                      label: "Handed off at:",
                      value: format(new Date(conversation.handedOffAt), "PPp"),
                      copy: true,
                    },
                  ]
                : []),
              {
                label: "Order ID:",
                value: conversation.orderId ?? "Unknown",
                copy: true,
              },
            ]}
          />
        </AccordionContent>
      </AccordionItem>

      {conversation.topics.length > 0 && (
        <AccordionItem value="topics" className="border-b-0 !shadow-none">
          <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
            Topics
          </AccordionTrigger>
          <AccordionContent>
            <ConversationDetailSection
              details={conversation.topics.map((topic, idx) => ({
                label: `Topic ${idx + 1}:`,
                value: topic,
                copy: true,
              }))}
            />
          </AccordionContent>
        </AccordionItem>
      )}

      {feedback && (
        <AccordionItem value="feedback" className="border-b-0 !shadow-none">
          <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
            Conversation Feedback
          </AccordionTrigger>
          <AccordionContent>
            <ConversationDetailSection
              details={[
                {
                  label: "Correct:",
                  value: feedback.correctAction ? "Yes" : "No",
                },
                {
                  label: "Comment:",
                  value: feedback.comment,
                },
              ]}
            />
          </AccordionContent>
        </AccordionItem>
      )}

      <AccordionItem value="system" className="border-b-0 !shadow-none">
        <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
          System Details
        </AccordionTrigger>

        <AccordionContent>
          <ConversationDetailSection
            details={[
              {
                label: "CID:",
                value: conversation.id,
                copy: true,
              },
              {
                label: "BID:",
                value: business.id,
                copy: true,
              },
              {
                label: "OID:",
                value: business.organizationId,
                copy: true,
              },
            ]}
          />
        </AccordionContent>
      </AccordionItem>

      {conversation.browserSessionUrls.length > 0 && (
        <AccordionItem value="visitedPages" className="border-b-0 !shadow-none">
          <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
            Visited Pages
          </AccordionTrigger>
          <AccordionContent>
            <ConversationDetailSection
              details={conversation.browserSessionUrls.map((url) => ({
                label: url.createdAt.toLocaleString(),
                value: (
                  <a
                    href={url.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {url.url}
                  </a>
                ),
              }))}
            />
          </AccordionContent>
        </AccordionItem>
      )}
    </Accordion>
  );
}
