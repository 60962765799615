import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";

import { UseFormReturn } from "react-hook-form";
import { Input } from "@dashboard/common/ui/input";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { UserForm } from "../-utils/userFormSchema";
import { Switch } from "@dashboard/common/ui/switch";

interface UserFormBodyProps {
  form: UseFormReturn<UserForm>;
  onSubmit: (data: UserForm) => void;
  onError: (errors: unknown) => void;
}

export function UserFormBody({ form, onSubmit, onError }: UserFormBodyProps) {
  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Full Name</FormLabel>
              <FormControl>
                <Input placeholder="Full Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex gap-2">
          <FormField
            control={form.control}
            name="isManager"
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start justify-center gap-2">
                <FormLabel>Permissions</FormLabel>
                <FormControl>
                  <div className="border-gray-light flex w-full items-center justify-start gap-4 rounded-lg border p-2 shadow-sm">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <span className="w-40 text-sm font-medium">
                      {field.value ? "Admin" : "Basic"}
                    </span>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isAgent"
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start justify-center gap-2">
                <FormLabel>Agent</FormLabel>
                <FormControl>
                  <div className="border-gray-light flex w-full items-center justify-start gap-4 rounded-lg border p-2 shadow-sm">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <span className="w-40 text-sm font-medium">
                      {field.value ? "Yes" : "No"}
                    </span>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <DialogFooter className="lg:space-x-2.5">
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
