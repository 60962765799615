import { z } from "zod";

const injectedEnv = process.env;
const CI = injectedEnv.CI === "true";

export const env = z
  .object({
    CHATBOT_TRPC_WS_URL: z.string().url(),
    // allow empty string for local dev
    CHATBOT_FRONTEND_URL: CI ? z.string().url() : z.string().default(""),
    CHATBOT_BASE_API_URL: z.string().url(),
    CHATBOT_SENTRY_DSN: z
      .string()
      .default(
        "https://80c0345de6ce640c8bc25b1395d0b5f4@o4506240732889088.ingest.sentry.io/4506257643732992",
      ),
    CHATBOT_COMMIT_HASH: z.string().optional(),
  })
  .parse(injectedEnv);
