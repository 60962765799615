import {
  Outlet,
  createFileRoute,
  redirect,
  useRouterState,
} from "@tanstack/react-router";
import { Sidebar } from "@dashboard/common/Sidebar/Sidebar";
import { Select } from "@dashboard/common/ui/select";
import { ConfigurationAccordion } from "./-components/ConfigurationAccordion";
import { useBanner } from "../../../-hooks/useBanner";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar",
)({
  beforeLoad: async ({
    params: { organizationSlug, businessSlug },
    context: { businesses, trpcClientUtils },
  }) => {
    const business = businesses.find(
      (business) => business.slug === businessSlug,
    );

    if (!business) {
      throw redirect({
        to: "/organization/$organizationSlug/settings/$businessSlug/business",
        params: { organizationSlug, businessSlug: businesses[0].slug },
        replace: true,
      });
    }

    const externalTicketConfig =
      await trpcClientUtils.dashboard.externalTickets.getConfiguration.ensureData(
        {
          organizationSlug,
        },
      );

    return { business, hasExternalTicketConfig: !!externalTicketConfig };
  },
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.shopify.isPublicApp.ensureData({
      businessSlug,
    });

    await trpcClientUtils.dashboard.products.getCount.ensureData({
      businessSlug,
    });
  },
  component: ConfigurationLayout,
});

function ConfigurationLayout() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { businesses } = Route.useRouteContext();
  const routerState = useRouterState();

  const options = businesses.map((business) => ({
    label: business.name,
    value: business.slug,
  }));

  const groups = [
    {
      title: "Businesses",
      options,
    },
  ];

  const navigate = Route.useNavigate();
  const setBusiness = (value: string) => {
    const endpoint = routerState.location.pathname
      .split(`${businessSlug}/`)
      .pop();

    void navigate({
      to: `/organization/$organizationSlug/settings/$businessSlug/${endpoint}`,
      params: { organizationSlug, businessSlug: value },
    });
  };

  function SidebarHeader() {
    return <h1 className="text-xl font-semibold">Settings</h1>;
  }

  const { height: bannerHeight, shouldShow: bannerVisible } = useBanner();

  return (
    <div className="flex h-full">
      <Sidebar
        header={<SidebarHeader />}
        banner={{ height: bannerHeight, isVisible: bannerVisible }}
      >
        {businesses.length > 1 && (
          <>
            <Select
              className="mb-4"
              variant="dropdown"
              value={businessSlug}
              onChange={(value) => setBusiness(value)}
              groups={groups}
            />
          </>
        )}

        <ConfigurationAccordion />
      </Sidebar>
      <div className="h-full flex-grow">
        <Outlet />
      </div>
    </div>
  );
}
