import { Table } from "@dashboard/common/Table/Table";
import { Button } from "@dashboard/common/ui/button";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Plus } from "lucide-react";
import { useDeleteTags } from "./-hooks/useDeleteTags";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.customers.getTagKeysAndValues.ensureData({
      businessSlug,
    });
  },

  component: CustomerTagsPage,
});

function CustomerTagsPage() {
  const { businessSlug, organizationSlug } = Route.useParams();
  const { trpc, business } = Route.useRouteContext();

  const [tags] = trpc.dashboard.customers.getTagKeysAndValues.useSuspenseQuery({
    businessSlug,
  });

  const records = tags.map((tag) => ({
    id: tag.id,
    title: (
      <Link
        to="/organization/$organizationSlug/settings/$businessSlug/customer-tags/$tagId"
        params={{ organizationSlug, businessSlug, tagId: tag.id }}
        className="font-semibold hover:underline"
      >
        {tag.key}
      </Link>
    ),
    llmInstructions: (
      <div className="w-full max-w-96 justify-start truncate">
        <TruncatedTooltip
          text={tag.llmInstructions}
          tooltipClassName="max-w-3xl truncate"
        />
      </div>
    ),
  }));

  const { deleteTags } = useDeleteTags();

  return (
    <div className="relative py-0">
      <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
        <h1 className="text-xl font-medium text-black">
          Customer Tags
          <span className="text-gray"> {`for ${business.name}`}</span>
        </h1>
        <div className="flex items-center gap-2">
          <Link
            to="/organization/$organizationSlug/settings/$businessSlug/customer-tags/new"
            params={{ organizationSlug, businessSlug }}
          >
            <Button
              className="flex items-center justify-between gap-2"
              type="button"
            >
              <Plus className="h-4 w-4" strokeWidth={3} />
              Create a new tag
            </Button>
          </Link>
        </div>
      </div>
      <Table
        records={records}
        columns={[
          {
            header: "Title",
            accessor: "title",
          },
          {
            header: "Instructions",
            accessor: "llmInstructions",
          },
        ]}
        deleteRecords={deleteTags}
        recordName={{ singular: "Tag", plural: "Tags" }}
        noRecordsText="No tags available"
      />
    </div>
  );
}
