import { z } from "zod";

export const simpleFlowOptionSchema = z.enum([
  "disabled",
  "notAllowed",
  "handOff",
  "holdAndHandOff",
  "automatic",
  "automaticNoEmail",
]);

export const simpleFlowConfigurationSchema = z.object({
  orderTracking: simpleFlowOptionSchema,
  orderCancelation: simpleFlowOptionSchema,
  shippingAddressUpdate: simpleFlowOptionSchema,
  contactDetailsUpdate: simpleFlowOptionSchema,
  orderItemsUpdate: simpleFlowOptionSchema,
});
