import { useInitializeConversations } from "@chatbot/hooks/conversations.hooks";
import { SettingsModal } from "./Settings/SettingsModal";
import { Input } from "./Input";
import { TopBar } from "./TopBar";
import Container from "./Container";
import { TailSpin } from "react-loader-spinner";

interface ConversationProviderProps {
  configId: string;
  browserSessionId: string;
}

export function ConversationProvider({
  configId,
  browserSessionId,
}: ConversationProviderProps) {
  const isInitialized = useInitializeConversations({
    configId,
    browserSessionId,
  });

  if (!isInitialized) {
    return (
      <>
        <TopBar />
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex items-center gap-2">
            <span className="z-10">
              <TailSpin
                color="black"
                height={40}
                width={40}
                strokeWidth={1.5}
              />
            </span>
            <div className="absolute z-0 h-[40px] w-[40px] rounded-full border border-gray-300 opacity-85" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <TopBar />
      <Container />
      <Input />
      <SettingsModal />
    </>
  );
}
