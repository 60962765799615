import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { Route } from "../_config-sidebar";
import { ConfigurationAccordionItem } from "./ConfigurationAccordionItem";
import {
  Building2Icon,
  CreditCardIcon,
  UsersIcon,
  BlocksIcon,
} from "lucide-react";

export function Workspace() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [isPublic] = trpc.dashboard.shopify.isPublicApp.useSuspenseQuery({
    businessSlug,
  });

  const renderBillingPage = !!isPublic;

  return (
    <ConfigurationAccordionItem value="workspace" title="Workspace">
      <SidebarButton
        icon={Building2Icon}
        text="Business Details"
        to="/organization/$organizationSlug/settings/$businessSlug/business"
      />

      {renderBillingPage && (
        <SidebarButton
          icon={CreditCardIcon}
          text="Billing"
          to="/organization/$organizationSlug/settings/$businessSlug/billing"
        />
      )}

      <SidebarButton
        icon={UsersIcon}
        text="Team Members"
        to="/organization/$organizationSlug/settings/$businessSlug/team"
      />

      <SidebarButton
        icon={BlocksIcon}
        text="Integrations"
        to="/organization/$organizationSlug/settings/$businessSlug/integrations"
      />
    </ConfigurationAccordionItem>
  );
}
