import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { MacroDialog } from "./-components/MacroDialog";
import { CreateMacroDialogContent } from "./-components/CreateMacroDialogContent";
import { EditMacroDialogContent } from "./-components/EditMacroDialogContent";
import { useDeleteMacros } from "./-hooks/useDeleteMacros";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/macros/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.macros.getAvailable.ensureData({
      organizationSlug,
    });

    await trpcClientUtils.dashboard.conversations.tags.getOrganizationTags.ensureData(
      {
        organizationSlug,
      },
    );

    await trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
      organizationSlug,
    });
  },
  component: MacrosPage,
});

function MacrosPage() {
  const { trpc, businesses } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [macros] = trpc.dashboard.macros.getAvailable.useSuspenseQuery({
    organizationSlug,
  });

  const { deleteMacros } = useDeleteMacros();

  const showBusinessName = businesses.length > 1;

  const records = macros.map((macro) => {
    let businessName = "All";
    if (macro.businessId) {
      const business = businesses.find(
        (business) => business.id === macro.businessId,
      );

      businessName = business?.name ?? "All";
    }

    const titleDialog = MacroDialog({
      title: macro.title,
      content: macro.content,
      actions: macro.actions,
      files: macro.files,
      availableForValue: macro.availableFor ? "Myself" : "Everyone",
      businessName,
    });

    return {
      id: macro.id,
      title: macro.title,
      content: macro.content,
      businessId: macro.businessId ?? "all",
      businessName,
      availableFor: macro.availableFor ?? "everyone",
      availableForValue: macro.availableFor ? "Myself" : "Everyone",
      actions: macro.actions,
      files: macro.files,
      titleDialog,
    };
  });

  return (
    <div className="flex h-full flex-col">
      <Table
        title="Macros"
        records={records}
        columns={[
          {
            header: "Title",
            accessor: "titleDialog",
          },
          ...(showBusinessName
            ? [
                {
                  header: "Business",
                  accessor: "businessName" as const,
                },
              ]
            : []),
          {
            header: "Available For",
            accessor: "availableForValue",
          },
        ]}
        addRecordDialogContent={(setOpen) => (
          <CreateMacroDialogContent setOpen={setOpen} />
        )}
        editRecordDialogContent={(record, setOpen) => (
          <EditMacroDialogContent {...record} setOpen={setOpen} />
        )}
        deleteRecords={deleteMacros}
        recordName={{ singular: "Macro", plural: "Macros" }}
        noRecordsText="No macros available"
      />
    </div>
  );
}
