import { Route } from "..";
import { toast } from "sonner";

export function useDeleteTags() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.customers.deleteKeys.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.customers.getTagKeysAndValues.invalidate({
        businessSlug,
      });

      const { keyIds } = variables;
      const message =
        keyIds.length > 1
          ? "Tags deleted successfully."
          : "Tag deleted successfully.";

      toast.success(message);
    },
    onError: (error, variables) => {
      const { keyIds } = variables;
      const message =
        keyIds.length > 1 ? "Failed to delete tags." : "Failed to delete tag.";

      toast.error(message);
      console.error(error);
    },
  });

  return {
    deleteTags: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, keyIds: ids });
    },
  };
}
