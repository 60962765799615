import { Route } from "../_navbar";

export function useUpdateAgentAvailability() {
  const { trpc, user } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const updateMutation =
    trpc.dashboard.users.updateAgentAvailability.useMutation();

  return () => {
    if (!user.isAgent) return;

    updateMutation.mutate({ organizationSlug });
  };
}
