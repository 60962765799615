import { proxy, useSnapshot } from "valtio";
import type {} from "@redux-devtools/extension";
import { devtools } from "valtio/utils";
import { MessagePart, Event } from "@be/modules/channels/channels.types";
import { WebChatMessage } from "@be/modules/channels/webChat/webChat.types";
import { DeepReadonlyObject } from "@chatbot/utils/types.utils";
import { trpc } from "@chatbot/utils/trpc";
import { useEffect } from "react";
import { useMarketId } from "./hacks.hooks";
import { recordGtmEvent } from "@chatbot/utils/ga4";
import { z } from "zod";
import { useDisableInput } from "./browserSessions.hooks";

export interface Conversation {
  createdAt: string;
  messages: [WebChatMessage, ...WebChatMessage[]];
  publicId?: string;
}

interface PendingConversation {
  status: "pending";
}

interface BotResponseProgress {
  sentMessageId: string;
  collectedDeltas: string[];
}

interface ActiveConversation extends Conversation {
  status: "active";
  id: string;
  botResponseProgress: BotResponseProgress | undefined;
  publicId: string | undefined;
}

type CurrentConversation = PendingConversation | ActiveConversation;

const conversationStore = proxy({
  conversations: undefined as
    | undefined
    | {
        email?: string;
        agreedToTermsAndConditions?: boolean;
        initialBotMessage: string;
        browserSessionId: string;
        current: CurrentConversation;
        past: Conversation[];
        isLoading: boolean;
        isHandedOff: boolean;
        hide: boolean;
        publicId?: string;
      },
});

devtools(conversationStore, {
  name: "Conversation Store",
  enabled: process.env.NODE_ENV === "development",
});

export function useInitializeConversations({
  configId,
  browserSessionId,
}: {
  configId: string | undefined;
  browserSessionId: string;
}) {
  const getConversationDataQuery = trpc.webChat.getConversationData.useQuery(
    {
      browserSessionId,
      url: window.location.href,
      configId: configId ?? "",
      testValue: `test-${configId ?? "unknown"}`,
    },
    { enabled: !!browserSessionId && !!configId },
  );

  trpc.webChat.onBotResponseMessagePart.useSubscription(
    { browserSessionId },
    { onData: handleReceivedBotMessagePart, enabled: !!browserSessionId },
  );

  trpc.webChat.onAgentMessage.useSubscription(
    { browserSessionId },
    {
      onData: handleReceivedAgentMessage,
      enabled: !!browserSessionId,
    },
  );

  trpc.webChat.onEvent.useSubscription(
    { browserSessionId },
    {
      onData: handleEvent,
      enabled: !!browserSessionId,
    },
  );

  useEffect(() => {
    if (getConversationDataQuery.data) {
      if (!browserSessionId) {
        throw new Error(
          "InvalidState: browserSessionId and initialBotMessage should be initialized",
        );
      }

      const {
        activeConversation,
        pastConversations,
        initialBotMessage,
        email,
      } = getConversationDataQuery.data;

      const oldCurrent = conversationStore.conversations?.current;
      if (
        oldCurrent?.status === "active" &&
        oldCurrent.id === activeConversation?.id &&
        oldCurrent.messages.length === activeConversation.messages.length
      ) {
        // Do not update conversations if the active conversation is the same
        // and the messages are the same
        // * This assumes that the messages are immutable
        return;
      }

      const shouldHideConversations =
        new URL(window.location.href).searchParams.get("octocom-no-bill") !==
        null;

      conversationStore.conversations = {
        email,
        agreedToTermsAndConditions: activeConversation ? true : undefined,
        initialBotMessage,
        browserSessionId,
        current: activeConversation
          ? {
              status: "active",
              botResponseProgress: undefined,
              ...activeConversation,
            }
          : { status: "pending" },
        past: pastConversations,
        isLoading: false,
        isHandedOff: activeConversation?.isHandedOff ?? false,
        hide: shouldHideConversations,
      };
    }
    // We do not want to reset our conversations when initialBotMessage changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getConversationDataQuery.data]);

  const { conversations } = useSnapshot(conversationStore);

  const conversationsManager = useConversationsOptional();

  useEffect(() => {
    const handleCustomEvent = (event: CustomEvent) => {
      const parsedDetail = z
        .object({ content: z.string() })
        .safeParse(event.detail);

      if (parsedDetail.success && conversationsManager?.isReadyToSendMessage) {
        conversationsManager.sendMessage({
          text: parsedDetail.data.content,
          fileIds: [],
        });
      }
    };

    document.addEventListener(
      "sendChatMessageEvent",
      handleCustomEvent as EventListener,
    );

    return () => {
      document.removeEventListener(
        "sendChatMessageEvent",
        handleCustomEvent as EventListener,
      );
    };
  }, [conversationsManager]);

  return !!conversations;
}

function useConversationsOptional() {
  const { conversations } = conversationStore;

  const marketId = useMarketId();

  const newConversationMutation = trpc.webChat.newConversation.useMutation();

  const closeActiveConversationMutation =
    trpc.webChat.closeActiveConversation.useMutation();

  const sendMessageMutation = trpc.webChat.sendMessage.useMutation();

  const clearHistoryMutation = trpc.webChat.clearHistory.useMutation();

  const inputDisabled = useDisableInput();

  if (!conversations) {
    return undefined;
  }

  const {
    current,
    past,
    browserSessionId,
    initialBotMessage,
    isLoading,
    email,
    agreedToTermsAndConditions,
    isHandedOff,
  } = conversations;

  return {
    browserSessionId,
    email,
    agreedToTermsAndConditions,

    setEmail: (email: string) => {
      conversations.email = email;
    },

    setAgreedToTermsAndConditions: (agreedToTermsAndConditions: boolean) => {
      conversations.agreedToTermsAndConditions = agreedToTermsAndConditions;
    },

    isLoading,

    get activeConversationId() {
      return current.status === "active" ? current.id : undefined;
    },

    get activeConversationPublicId() {
      const host = window.location.hostname;
      if (host === "localhost" || host === "www.octocom.ai") {
        return current.status === "active" ? current.publicId : undefined;
      }
      return undefined;
    },

    get conversations() {
      const conversations: DeepReadonlyObject<Conversation>[] = [...past];

      if (current.status === "pending") {
        // Pending conversations do not have any user messages yet (and are not persisted on the backend).
        conversations.push({
          createdAt: new Date().toISOString(),
          messages: [
            {
              sender: "bot",
              text: initialBotMessage,
            },
          ],
        });
      } else {
        if (!current.botResponseProgress || isHandedOff) {
          // No active bot response, just include the current conversation as is
          conversations.push(current);
        } else {
          // Include the active bot response as a part of the current conversation
          conversations.push({
            ...current,
            messages: [
              ...current.messages,
              {
                sender: "bot",
                text: current.botResponseProgress.collectedDeltas.join(""),
              },
            ],
          });
        }
      }

      return conversations;
    },

    get isActive(): boolean {
      return current.status === "active";
    },

    get isReadyToSendMessage(): boolean {
      return (
        (current.status === "pending" ||
          current.botResponseProgress === undefined ||
          isHandedOff) &&
        !inputDisabled
      );
    },

    get isGeneratingBotResponse(): boolean {
      return (
        current.status === "active" &&
        !isHandedOff &&
        !!current.botResponseProgress
      );
    },

    sendMessage: ({ text, fileIds }: { text: string; fileIds: string[] }) => {
      recordGtmEvent({ eventAction: "Send Message" });

      if (current.status === "pending") {
        conversations.current = {
          status: "active",
          id: "new",
          createdAt: new Date().toISOString(),
          botResponseProgress: undefined,
          publicId: undefined,
          messages: [
            { sender: "bot", text: conversations.initialBotMessage },
            {
              sender: "customer",
              text,
            },
          ],
        };

        void newConversationMutation
          .mutateAsync({
            browserSessionId,
            firstCustomerMessage: text,
            metadata: {
              url: window.location.href,
              attentionGrabberId: undefined,
              attentionGrabberSuggestionId: undefined,
              marketId,
            },
            email: conversations.email,
            fileIds,
            hide: conversations.hide,
            customerUrl: window.location.href,
          })
          .then(({ conversationId, sentMessage, publicId }) => {
            conversations.current = {
              status: "active",
              id: conversationId,
              createdAt: new Date().toISOString(),
              botResponseProgress: {
                sentMessageId: sentMessage.id,
                collectedDeltas: [],
              },
              publicId,
              messages: [
                { sender: "bot", text: conversations.initialBotMessage },
                { sender: "customer", text, files: sentMessage.files },
              ],
            };
          });
      } else {
        current.messages.push({
          sender: "customer",
          text,
        });

        void sendMessageMutation
          .mutateAsync({
            conversationId: current.id,
            text,
            fileIds,
            customerUrl: window.location.href,
          })
          .then(({ sentMessage }) => {
            current.botResponseProgress = {
              sentMessageId: sentMessage.id,
              collectedDeltas: [],
            };
            current.messages[current.messages.length - 1].files =
              sentMessage.files;
          });
      }
    },

    closeConversation: () => {
      if (current.status === "pending") {
        return;
      }

      newPendingConversation();
      void closeActiveConversationMutation.mutateAsync({
        browserSessionId,
      });
    },

    clearHistory: () => {
      if (current.status === "pending" && past.length === 0) {
        return;
      }

      conversations.isLoading = true;
      void clearHistoryMutation
        .mutateAsync({
          browserSessionId,
        })
        .then(() => {
          conversations.current = { status: "pending" };
          conversations.past = [];
          conversations.email = undefined;
          conversations.agreedToTermsAndConditions = undefined;
        })
        .finally(() => {
          conversations.isLoading = false;
        });
    },
  };
}

export function useConversations() {
  const conversations = useConversationsOptional();

  if (!conversations) {
    throw new Error("Conversations not initialized");
  }

  return conversations;
}

function handleReceivedAgentMessage(message: WebChatMessage): void {
  const { conversations } = conversationStore;

  if (!conversations) {
    throw new Error("Conversations not initialized");
  }

  const { current } = conversations;

  if (current.status === "active") {
    current.messages.push(message);
    current.botResponseProgress = undefined;
  }
}

function handleEvent(eventType: Event): void {
  const { conversations } = conversationStore;

  console.log("Handling event: ", eventType);

  if (!conversations) {
    throw new Error("Conversations not initialized");
  }

  switch (eventType) {
    case "close":
      setTimeout(() => {
        newPendingConversation();
      }, 1000);
      break;
    case "takeover":
      conversations.isHandedOff = true;
      break;
    default:
      break;
  }
}

function handleReceivedBotMessagePart(part: MessagePart): void {
  console.debug("Received bot message part: ", part);
  const { conversations } = conversationStore;

  if (!conversations) {
    throw new Error("Conversations not initialized");
  }

  const { current } = conversations;

  if (
    current.status === "pending" ||
    current.botResponseProgress === undefined ||
    current.botResponseProgress.sentMessageId !== part.inResponseToMessageId
  ) {
    if (part.type !== "delta") {
      // If we receive an end or error message for an unexpected message,
      // we should reload the conversations. Reloading the conversations
      // will set
      // TODO: Reload conversations
    }
    return;
  }

  const botResponseProgress = current.botResponseProgress;

  if (part.type === "error") {
    // If we receive an error for an expected message, we should reset the collected deltas
    botResponseProgress.collectedDeltas = [];
    return;
  }

  if (part.type === "end") {
    current.botResponseProgress = undefined;

    if (
      botResponseProgress.collectedDeltas.length !== part.totalParts ||
      botResponseProgress.collectedDeltas.some(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (delta) => delta === undefined,
      )
    ) {
      // Received end message with different totalParts than the collected count
      // or with some missing parts
      // TODO: Reload conversations
      return;
    }

    current.messages.push({
      sender: "bot",
      text: botResponseProgress.collectedDeltas.join(""),
    });

    if (part.handOffConversation) {
      conversations.isHandedOff = true;
    }

    if (part.closeConversation) {
      newPendingConversation();
    }

    return;
  }

  botResponseProgress.collectedDeltas[part.index] = part.delta;
}

function newPendingConversation() {
  const conversations = conversationStore.conversations;

  if (!conversations) {
    throw new Error("InvalidState: conversations should be initialized");
  }

  const { current, past } = conversations;

  if (current.status === "active") {
    past.push(current);
  }

  conversations.isHandedOff = false;

  conversations.current = { status: "pending" };
}
