import { toast } from "sonner";
import { Route } from "..";
import { useInvalidateConversationData } from "../../../-hooks/useInvalidateConversationData";
import { MacroAction } from "@be/modules/macros/macros.types";

export function useCreateExternalConversation() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  const invalidate = useInvalidateConversationData({ publicId });

  const createMutation =
    trpc.dashboard.conversations.external.create.useMutation({
      onSuccess: async (data) => {
        await invalidate();
        void navigate({
          to: "/organization/$organizationSlug/conversation/$publicId",
          params: { organizationSlug, publicId: data.publicId },
          search,
        });
      },
      onError: (error) => {
        console.error("Error sending message", error);
        toast.error("Error creating external thread");
      },
    });

  return ({
    text,
    emailTo,
    subject,
    fileIds,
    actions,
  }: {
    text: string;
    emailTo: string[];
    subject: string;
    fileIds?: string[];
    actions: MacroAction[];
  }) =>
    createMutation.mutate({
      organizationSlug,
      publicId,
      text,
      emailTo,
      subject,
      fileIds,
      actions,
    });
}
