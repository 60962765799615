import { toast } from "sonner";
import { Route } from "..";

export const useSelectBotTone = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.bots.updateBotTone.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.bots.getOrThrow.invalidate({
        businessSlug: organizationSlug,
      });

      onSuccess?.();
    },
    onError: (error) => {
      toast.error("Failed to update bot tone.");
      console.error(error);
    },
  });

  return {
    updateTone: (data: { tone: string }) => {
      updateMutation.mutate({
        businessSlug: organizationSlug,
        tone: data.tone,
      });
    },
  };
};
