import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { UseFormReturn } from "react-hook-form";
import { TemplateForm } from "../-utils/templateFormSchema";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";

interface TemplateFormBodyProps {
  form: UseFormReturn<TemplateForm>;
  onSubmit: (data: TemplateForm) => void;
}

export function TemplateFormBody({ form, onSubmit }: TemplateFormBodyProps) {
  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter the name of the template"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="content"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Rule</FormLabel>
              <FormControl>
                <Textarea
                  rows={8}
                  placeholder="Enter the content of the template"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="lg:space-x-2.5">
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
