import { createFileRoute } from "@tanstack/react-router";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@dashboard/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Textarea } from "@dashboard/common/ui/textarea";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import { RatingForm, ratingFormSchema } from "./-hooks/useSubmitForm";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@dashboard/common/ui/card";

const RATING_IMAGES = {
  1: {
    src: "https://octocomstorage.blob.core.windows.net/prod/terrible.png",
    title: "Terrible",
  },
  2: {
    src: "https://octocomstorage.blob.core.windows.net/prod/bad.png",
    title: "Bad",
  },
  3: {
    src: "https://octocomstorage.blob.core.windows.net/prod/normal.png",
    title: "OK",
  },
  4: {
    src: "https://octocomstorage.blob.core.windows.net/prod/good.png",
    title: "Great",
  },
  5: {
    src: "https://octocomstorage.blob.core.windows.net/prod/great.png",
    title: "Amazing",
  },
} as const;

export const Route = createFileRoute("/public-routes/rate-conversation/")({
  beforeLoad: async ({ search, context: { trpcClientUtils } }) => {
    await trpcClientUtils.dashboard.conversations.ratings.getConfig.ensureData({
      publicId: search.publicId,
    });
  },
  validateSearch: z.object({
    publicId: z.string(),
    rating: z.number().min(1).max(5),
    customerEmail: z.string(),
  }),
  component: RateConversation,
});

function RateConversation() {
  const { trpc } = Route.useRouteContext();
  const { publicId, rating, customerEmail } = Route.useSearch();
  const [config] =
    trpc.dashboard.conversations.ratings.getConfig.useSuspenseQuery({
      publicId,
    });

  const form = useForm<RatingForm>({
    resolver: zodResolver(ratingFormSchema),
    defaultValues: {
      rating: rating,
      comment: null,
    },
  });

  const { onSubmit } = useSubmitForm({
    publicId,
    customerEmail,
  });

  return (
    <>
      <div className="flex h-full min-h-screen flex-col overscroll-none">
        <div className="mx-auto flex w-full flex-grow flex-col items-center justify-center px-4 pt-10 sm:px-20">
          <Card>
            <CardHeader className="flex flex-col items-center justify-center">
              <CardTitle className="font-normal">
                Rate your conversation
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Form {...form}>
                <form
                  onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
                  className="mt-3 flex flex-col gap-6"
                >
                  <FormField
                    control={form.control}
                    name="rating"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex flex-wrap justify-center gap-2 text-sm text-gray-500">
                          {[1, 2, 3, 4, 5].map((num) => (
                            <Button
                              key={num}
                              type="button"
                              variant="ghost"
                              className={`px-3 py-6 text-2xl sm:px-4 sm:py-8 sm:text-4xl ${
                                field.value === num
                                  ? "bg-secondary"
                                  : "hover:bg-gray-100"
                              } ${field.value === num ? "" : "opacity-40"}`}
                              onClick={() => field.onChange(num)}
                            >
                              <img
                                src={
                                  RATING_IMAGES[
                                    num as keyof typeof RATING_IMAGES
                                  ].src
                                }
                                alt={
                                  RATING_IMAGES[
                                    num as keyof typeof RATING_IMAGES
                                  ].title
                                }
                                className="h-8 w-8 sm:h-12 sm:w-12"
                              />
                            </Button>
                          ))}
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {config.ratingCommentEnabled && (
                    <FormField
                      control={form.control}
                      name="comment"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Textarea
                              placeholder="Add a comment (optional)"
                              className="min-h-[100px]"
                              {...field}
                              value={field.value ?? ""}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  <div className="flex justify-center">
                    <Button
                      type="submit"
                      className="w-full sm:w-fit"
                      disabled={!form.watch("rating")}
                    >
                      Submit Feedback
                    </Button>
                  </div>
                </form>
              </Form>
            </CardContent>
          </Card>
        </div>

        <footer className="p-4 text-center text-xs text-gray-500 sm:p-6">
          Powered by{" "}
          <a
            href="https://www.octocom.ai"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium hover:underline"
          >
            Octocom
          </a>
        </footer>
      </div>
    </>
  );
}
