import { Route } from "..";
import { toast } from "sonner";
import { BusinessConfigForm } from "../-utils/formSchema";

export function useSubmitForm() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const updateMutation = trpc.dashboard.business.update.useMutation({
    onSuccess: () => {
      toast.success("Successfully updated web chat settings.");
    },
    onError: () => {
      toast.error("Failed to update the web chat settings. Please try again.");
    },
  });

  return {
    onSubmit: (data: BusinessConfigForm) => {
      updateMutation.mutate({
        businessSlug,
        data: { ...data },
      });
    },

    onError: (errors: unknown) => {
      console.error(errors);
      toast.success("Failed to update business settings.");
    },

    isLoading: updateMutation.isPending,
  };
}
