import { Route } from "..";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import { workflowExportSchema } from "../-utils/workflowExportSchema";
import { z } from "zod";
import { getOrThrow } from "@dashboard/utils/map.utils";

export function useExportWorkflows() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [flows] = trpc.dashboard.flows.getAll.useSuspenseQuery({
    businessSlug,
  });

  const [threats] = trpc.dashboard.threats.getAll.useSuspenseQuery({
    businessSlug,
  });

  return {
    exportWorkflows: (ids: string[]) => {
      const selectedFlows = flows.filter((flow) => ids.includes(flow.id));

      const allThreatIds = new Set(
        selectedFlows.flatMap((flow) =>
          flow.variants.flatMap((variant) => [
            ...variant.associatedThreatIds,
            ...variant.ignoredThreatIds,
          ]),
        ),
      );

      const usedThreats = threats.filter((threat) =>
        allThreatIds.has(threat.id),
      );

      const originalThreatIdToNewThreadId = new Map<string, string>(
        usedThreats.map((t) => [t.id, uuidv4()]),
      );

      const data: z.infer<typeof workflowExportSchema> = {
        threats: usedThreats.map((t) => ({
          ...t,
          id: getOrThrow(originalThreatIdToNewThreadId, t.id),
          businessId: undefined,
          createdAt: undefined,
          updatedAt: undefined,
        })),
        workflows: selectedFlows.map((w) => ({
          ...w,
          id: undefined,
          businessId: undefined,
          variants: w.variants.map((v) => ({
            ...v,
            id: undefined,
            associatedThreatIds: v.associatedThreatIds.map((id) =>
              getOrThrow(originalThreatIdToNewThreadId, id),
            ),
            ignoredThreatIds: v.ignoredThreatIds.map((id) =>
              getOrThrow(originalThreatIdToNewThreadId, id),
            ),
            flowId: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            position: undefined,
          })),
        })),
      };

      const jsonString = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `octocom-workflows-${businessSlug}-${timestamp}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success("Workflows exported successfully");
    },
  };
}
