import { Routepath } from "@dashboard/App";
import { ReactNode } from "react";
import { Link } from "@tanstack/react-router";
import { cn } from "@dashboard/utils/ui";

export interface RenderProps {
  className: string;
  activeClassName: string;
  inactiveClassName: string;
  children: ReactNode;
}

export function renderNavbarLink({
  to,
  active,
  search,
}: {
  to: Routepath;
  active?: boolean;
  search?: Record<string, string>;
}) {
  return function NavbarLink({
    className,
    activeClassName,
    inactiveClassName,
    children,
  }: RenderProps) {
    if (active === true) {
      className = cn(className, activeClassName);
    } else if (active === false) {
      className = cn(className, inactiveClassName);
    }

    return (
      <Link
        to={to}
        search={search}
        className={className}
        activeProps={{
          className: activeClassName,
        }}
        inactiveProps={{ className: inactiveClassName }}
      >
        {children}
      </Link>
    );
  };
}
