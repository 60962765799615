import { Route } from "..";
import { toast } from "sonner";

export function useMarkTopicAsIrrelevant() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const mutation = trpc.dashboard.bots.markTopicAsIrrelevant.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.bots.getKnowledgeSummary.invalidate({
        businessSlug: organizationSlug,
      });

      toast.success("Topic marked as irrelevant");
    },
    onError: (error) => {
      toast.error("Failed to mark topic as irrelevant, please try again.");
      console.error(error);
    },
  });

  return {
    markTopicAsIrrelevant: ({ topic }: { topic: string }) => {
      mutation.mutate({ businessSlug: organizationSlug, topic });
    },
  };
}
