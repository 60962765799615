import { Button } from "@dashboard/common/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { useState } from "react";
import { Route } from "../_navbar";
import { Switch } from "@dashboard/common/ui/switch";
import { Label } from "@dashboard/common/ui/label";
import { Separator } from "@dashboard/common/ui/separator";

import { useUpdateAgentAvailability } from "../-hooks/useUpdateAgentAvailability";
import { useSetAgentAsUnavailable } from "../-hooks/useSetAgentAsUnavailable";
import { usePingEffect } from "../-hooks/usePingEffect";
import { cn } from "@dashboard/utils/ui";
import { useLogout } from "../-hooks/useLogout";

export function UserButton() {
  const [open, setOpen] = useState(false);

  const { user, hasExternalTicketConfig } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const navigate = Route.useNavigate();
  const logout = useLogout();

  const updateAgentAvailability = useUpdateAgentAvailability();
  const setAgentAsUnavailable = useSetAgentAsUnavailable();

  usePingEffect();

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      sessionStorage.setItem("agentIsAvailable", "true");
      updateAgentAvailability();
    } else {
      sessionStorage.setItem("agentIsAvailable", "false");
      setAgentAsUnavailable();
    }
  };

  const navigateToAccountSettings = () => {
    void navigate({
      to: "/organization/$organizationSlug/account-settings",
      params: { organizationSlug },
    });

    setOpen(false);
  };

  const agentIsAvailable = sessionStorage.getItem("agentIsAvailable");
  const defaultChecked = agentIsAvailable ? agentIsAvailable === "true" : true;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="flex h-11 w-full items-center justify-center">
        <div className="relative flex h-[30px] w-[30px] items-center justify-center rounded-full bg-blue-900">
          <span className="text-sm text-white">
            {user.fullName.substring(0, 2).toUpperCase()}
          </span>
          <div
            className={cn(
              "absolute bottom-[1.5px] right-[1.5px] h-[7px] w-[7px] rounded-full",
              defaultChecked ? "bg-green-500" : "bg-orange-400",
            )}
          ></div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[230px] p-0" side="right" align="end">
        <div className="flex flex-col gap-y-2 p-2">
          <div className="flex flex-col gap-y-1 px-2">
            <div className="flex flex-row items-center space-x-3">
              <div className="relative flex h-[35px] w-[35px] shrink-0 items-center justify-center rounded-full bg-blue-900">
                <span className="text-base text-white">
                  {user.fullName.substring(0, 2).toUpperCase()}
                </span>

                <div
                  className={cn(
                    "absolute bottom-[1.5px] right-[1.5px] h-[8px] w-[8px] rounded-full",
                    defaultChecked ? "bg-green-500" : "bg-orange-400",
                  )}
                ></div>
              </div>
              <div className="flex flex-col overflow-x-scroll py-2">
                <div className="text-sm font-medium text-black">
                  {user.fullName}
                </div>
                <div className="text-gray text-xs">{user.email}</div>
              </div>
            </div>

            {user.isAgent && !hasExternalTicketConfig && (
              <div className="flex items-center justify-between space-x-2">
                <Label htmlFor="agent-available" className="text-gray text-sm">
                  Available
                </Label>
                <Switch
                  className="data-[state=checked]:bg-green-500 data-[state=unchecked]:bg-orange-400"
                  id="agent-available"
                  onCheckedChange={handleSwitchChange}
                  defaultChecked={defaultChecked}
                />
              </div>
            )}
          </div>

          <Separator />

          <div className="flex flex-col gap-y-2">
            <Button
              type="button"
              variant="ghost"
              className="w-full justify-start pl-2 text-left text-black"
              onClick={navigateToAccountSettings}
            >
              Account Settings
            </Button>
          </div>

          <Separator />

          <div className="flex w-full flex-col">
            <Button
              type="button"
              variant="ghost"
              className="w-full justify-start pl-2 text-left text-black"
              onClick={logout}
            >
              Log Out
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
