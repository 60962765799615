import { MacroForm, MacroFormPayload } from "./macroFormSchema";

export function formatPayload(data: MacroForm): MacroFormPayload {
  return {
    ...data,
    availableFor: data.availableFor === "everyone" ? null : data.availableFor,
    businessId: data.businessId === "all" ? null : data.businessId,
    fileIds: data.files.map((file) => file.id),
  };
}
