import { Select } from "@dashboard/common/ui/select";
import { useState } from "react";
import { Route } from "../..";

export function IntervalSelect() {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  const [interval, setInterval] = useState<"day" | "hour">(
    search.newConversationsInterval ?? "day",
  );

  const options = [
    {
      label: "Group by Day",
      value: "day" as const,
    },
    {
      label: "Group by Hour",
      value: "hour" as const,
    },
  ];

  const setSearch = (value: "day" | "hour") => {
    setInterval(value);

    void navigate({ search: { ...search, newConversationsInterval: value } });
  };

  return (
    <Select
      variant="dropdown"
      value={interval}
      onChange={(value) => setSearch(value)}
      options={options}
    />
  );
}
