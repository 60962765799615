import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@dashboard/common/ui/alert-dialog";
import { useEffect, useState } from "react";

interface AlertDialogDisplayRequest {
  title: string;
  description: string;
  onAction: () => void;
  onCancel?: () => void;
  actionText?: string;
  cancelText?: string;
}

let globalRequestCallback:
  | ((request: AlertDialogDisplayRequest) => void)
  | null = null;

export function requestAlertDialogDisplay(request: AlertDialogDisplayRequest) {
  if (globalRequestCallback) {
    globalRequestCallback(request);
  } else {
    console.warn("GlobalAlertDialog is not mounted");
  }
}

export function GlobalAlertDialog() {
  const [open, setOpen] = useState(false);
  const [dialogQueue, setDialogQueue] = useState<AlertDialogDisplayRequest[]>(
    [],
  );
  const [currentDialog, setCurrentDialog] =
    useState<AlertDialogDisplayRequest | null>(null);

  useEffect(() => {
    globalRequestCallback = (request) => {
      setDialogQueue((queue) => [...queue, request]);
    };
    return () => {
      globalRequestCallback = null;
    };
  }, []);

  useEffect(() => {
    if (!open && dialogQueue.length > 0) {
      const [nextDialog, ...remainingQueue] = dialogQueue;
      setCurrentDialog(nextDialog);
      setDialogQueue(remainingQueue);
      setOpen(true);
    }
  }, [open, dialogQueue]);

  const onClickAction = () => {
    currentDialog?.onAction();
    setOpen(false);
  };

  const onClickCancel = () => {
    currentDialog?.onCancel?.();
    setOpen(false);
  };

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{currentDialog?.title ?? ""}</AlertDialogTitle>
          <AlertDialogDescription>
            {(currentDialog?.description ?? "").split("\n").map((line, i) => {
              if (line === "") {
                return <br key={i} />;
              }
              return <p key={i}>{line}</p>;
            })}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClickCancel}>
            {currentDialog?.cancelText ?? "Cancel"}
          </AlertDialogCancel>
          <AlertDialogAction onClick={onClickAction}>
            {currentDialog?.actionText ?? "Continue"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
