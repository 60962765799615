import { useMemo } from "react";
import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { MessageAvatar } from "../Message/MessageAvatar";
import { useConversation } from "../../-hooks/useConversation.hook";
import { Route } from "../..";

type Part = ReturnType<
  typeof useConversation
>["relatedConversations"][number]["parts"][number];
type PreviewMessage = Extract<Part, { type: "message" }>;

interface PreviewMessageProps {
  message: PreviewMessage;
  useTranslation: boolean;
}

export function PreviewMessage({
  message,
  useTranslation,
}: PreviewMessageProps) {
  const { user } = Route.useRouteContext();
  const isCustomer = message.sender === "customer";

  const filesMd = useMemo(() => {
    return message.files
      .map((file) => {
        if (file.contentType.startsWith("image")) {
          return `![${file.name}](${file.url})`;
        } else {
          return `[${file.name}](${file.url})`;
        }
      })
      .join("\n\n");
  }, [message]);

  let translation: string | undefined;

  if (useTranslation) {
    translation = message.translations.find(
      (t) => t.translatedTo === user.translationLanguage,
    )?.translation;
  }

  const text = translation ?? message.text;

  const content = `${text}\n\n${filesMd}`;

  const agentName = message.sender === "agent" ? message.agentName : undefined;

  return (
    <div
      className={`flex ${
        !isCustomer ? "flex-row-reverse" : "flex-row"
      } gap-x-2 p-2`}
    >
      <div className="flex flex-col-reverse pb-2">
        <MessageAvatar
          sender={message.sender}
          agentName={agentName ?? undefined}
        />
      </div>

      <div
        className={`max-w-md rounded-lg px-4 pb-2 pt-3 xl:max-w-lg ${
          !isCustomer
            ? "bg-[#DCE1F9] hover:bg-[#CED6F7]"
            : "bg-gray-lightest hover:bg-gray-light"
        }`}
      >
        <MarkdownContent content={content} className="break-words text-sm" />
        <div className="mt-1 flex flex-row items-end">
          <span className="text-gray text-[13px]">via {message.channel}</span>
        </div>
      </div>
    </div>
  );
}
