import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TemplateForm, templateFormSchema } from "../-utils/templateFormSchema";
import { TemplateFormBody } from "./TemplateFormBody";
import { useCreateTemplate } from "../-hooks/useCreateTemplate";

export function CreateTemplateForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const form = useForm<TemplateForm>({
    resolver: zodResolver(templateFormSchema),
  });

  const { onSubmit: onSubmitMutation } = useCreateTemplate({ setOpen });

  const onSubmit = (data: TemplateForm) => {
    onSubmitMutation(data);

    form.reset();
  };

  return <TemplateFormBody form={form} onSubmit={onSubmit} />;
}
