import { z } from "zod";

export const filterKeysSchema = z.enum([
  "timeRange",
  "businessId",
  "assignee",
  "language",
  "channel",
  "handedOff",
  "tag",
  "status",
  "type",
]);

export const filtersSchema = z.object({
  timeRange: z
    .union([
      z.literal("today"),
      z.literal("this-week"),
      z.literal("this-month"),
      z.literal("last-2-days"),
      z.literal("last-7-days"),
      z.literal("last-14-days"),
      z.literal("last-30-days"),
      z.literal("last-90-days"),
    ])
    .nullish(),
  channel: z
    .union([
      z.literal("email"),
      z.literal("web"),
      z.literal("instagram"),
      z.literal("instagramComment"),
      z.literal("messenger"),
      z.literal("facebookComment"),
    ])
    .nullish(),
  handedOff: z.union([z.literal("yes"), z.literal("no")]).nullish(),
  language: z.string().nullish(),
  assignee: z.string().nullish(),
  status: z
    .union([z.literal("open"), z.literal("closed"), z.literal("snoozed")])
    .nullish(),
  tag: z.string().nullish(),
  type: z.union([z.literal("all"), z.literal("external")]).nullish(),
  businessId: z.string().nullish(),
});

export const customViewSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  title: z.string(),
  filters: filtersSchema,
});

export const customViewUpdateSchema = customViewSchema
  .omit({
    organizationId: true,
  })
  .partial()
  .required({
    id: true,
  });
