import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import logo from "@dashboard/assets/octocom-black.svg";
import { cn } from "@dashboard/utils/ui";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding",
)({
  beforeLoad: async ({
    params: { organizationSlug },
    context: { trpcClientUtils },
  }) => {
    const organization =
      await trpcClientUtils.dashboard.business.getOrganizationOrThrow.ensureData(
        {
          organizationSlug,
        },
      );

    if (
      organization.onboardingStatus === "COMPLETED" ||
      organization.onboardingStatus === "SETUP"
    ) {
      throw redirect({
        to: "/organization/$organizationSlug/conversation",
        params: { organizationSlug },
      });
    }
  },
  component: OnboardingPage,
});

function OnboardingPage() {
  const isFeaturesPage = window.location.pathname.includes("/features");

  return (
    <div className="flex h-full min-h-screen flex-col overscroll-none">
      <div className="w-full px-16 pt-10">
        <img alt="Octocom logo" className="w-40" src={logo} />
      </div>
      <div
        className={cn(
          "mx-auto flex w-full flex-grow flex-col items-center justify-center px-20 pt-10",
          !isFeaturesPage && "max-w-4xl",
        )}
      >
        <Outlet />
      </div>
    </div>
  );
}
