import { useInitializeConfig } from "@chatbot/hooks/config.hooks";
import { useInitializeBrowserSession } from "@chatbot/hooks/browserSessions.hooks";
import { useSuntouchedHack, useZendeskHack } from "@chatbot/hooks/hacks.hooks";
import { Coordinator } from "@chatbot/components/Coordinator";
import { useEffect, useState } from "react";

export function BrowserSessionProvider({
  presetConfigId,
  containerMode,
}: {
  presetConfigId?: string;
  containerMode?: boolean;
}) {
  const [currentPathname, setCurrentPathname] = useState(
    window.location.pathname,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.location.pathname !== currentPathname) {
        setCurrentPathname(window.location.pathname);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [currentPathname]);

  const config = useInitializeConfig({ presetConfigId });

  useSuntouchedHack({
    enabled: new URL(window.location.href).hostname === "suntouched.co",
  });

  useZendeskHack({
    enabled: new URL(window.location.href).hostname === "drinklemonkind.com",
  });

  const browserSessionId = useInitializeBrowserSession({
    configId: config?.id,
  });

  if (!config || !browserSessionId) {
    return null;
  }

  console.log("currentPathname", currentPathname);

  if (!presetConfigId) {
    const currentHost = new URL(window.location.href).hostname.replace(
      /^www\./,
      "",
    );

    const deployment = config.deployments.find(
      (d) => d.hostname?.replace(/^www\./, "") === currentHost,
    );

    if (
      deployment?.pathRegex &&
      !new RegExp(deployment.pathRegex).test(currentPathname)
    ) {
      return null;
    }
  }

  return (
    <Coordinator
      browserSessionId={browserSessionId}
      config={config}
      containerMode={containerMode}
    />
  );
}
