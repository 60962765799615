import { z } from "zod";

export const articleExportSchema = z.array(
  z.object({
    title: z.string().min(1),
    content: z.string().min(1),
    categoryId: z.string().min(1).optional(),
    isPublic: z.boolean().optional(),
  }),
);
