import { useConversation } from "../../-hooks/useConversation.hook";
import { ConversationTopBar } from "../../../../-components/ConversationTopBar";
import { ConversationDetailSection } from "./ConversationDetailSection";
import { AssigneeSelect } from "./AssigneeSelect";
import { TagSection } from "./ConversationTags/TagSection";
import { Route } from "../..";
import { useState } from "react";
import { DetailsAccordion } from "./DetailsAccordion";
import { RecentConversations } from "./RecentConversations";
import { Button } from "@dashboard/common/ui/button";
import { PanelRightClose, PanelRightOpen } from "lucide-react";
import { cn } from "@dashboard/utils/ui";
import { Orders } from "./Orders/Orders";
import { Subscriptions } from "./Subscriptions/Subscriptions";
import { GiftCards } from "./GiftCards/GiftCards";
import { Returns } from "./Returns/Returns";
import { CustomerTagsSection } from "./CustomerTags/CustomerTagsSection";
export function ConversationDetails() {
  const { hasExternalTicketConfig } = Route.useRouteContext();
  const conversation = useConversation();
  const [open, setOpen] = useState(true);

  const { customer } = conversation;

  return (
    <>
      <div className="fixed right-0 top-0 z-50 flex h-16 w-10 items-center justify-center">
        <Button
          variant="ghost"
          className="hover:text-blue hover:bg-blue-light h-auto w-auto p-1 text-black "
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <PanelRightClose className="h-5 w-5" />
          ) : (
            <PanelRightOpen className="h-5 w-5" />
          )}
        </Button>
      </div>

      <div
        className={cn(
          "border-b-gray-lightest flex h-16 w-0 items-center justify-center border-b transition-all duration-200 ease-in-out",
          !open && "w-4",
        )}
      />
      <div
        className={cn(
          "bg-background border-l-gray-light flex h-full w-[24%] min-w-[250px] max-w-[400px] flex-col overflow-x-auto border-l pb-5 transition-all duration-200 ease-in-out",
          !open && "w-0 min-w-0",
        )}
      >
        <ConversationTopBar>
          <h2 className="text-xl font-medium text-black">Details</h2>
        </ConversationTopBar>

        <div className="divide-gray-lightest space-y-5 divide-y overflow-y-auto">
          {!hasExternalTicketConfig && (
            <>
              {conversation.isHandedOff && (
                <ConversationDetailSection
                  details={[
                    {
                      label: "Assignee:",
                      value: <AssigneeSelect />,
                    },
                  ]}
                />
              )}
            </>
          )}

          <TagSection />

          {customer && (
            <div>
              <div className="pl-7 pt-4 text-xs font-medium uppercase">
                Customer Details
              </div>
              <ConversationDetailSection
                details={[
                  ...(customer.name
                    ? [
                        {
                          label: "Name:",
                          value: customer.name,
                          copy: true,
                        },
                      ]
                    : []),
                  {
                    label: "Email:",
                    value: customer.email,
                    copy: true,
                  },
                  ...(customer.phone
                    ? [
                        {
                          label: "Phone:",
                          value: customer.phone,
                          copy: true,
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          )}

          <CustomerTagsSection />

          {customer && <Orders />}
          {customer && <Subscriptions />}
          {customer && <GiftCards />}
          {customer && <Returns />}

          <RecentConversations />

          <DetailsAccordion />
        </div>
      </div>
    </>
  );
}
