import { createContext, useContext, useState } from "react";

interface SelectionContextType {
  selectedCards: Set<string>;
  setSelectedCards: (
    value: Set<string> | ((prev: Set<string>) => Set<string>),
  ) => void;
  deselectAll: () => void;
}

const SelectionContext = createContext<SelectionContextType | null>(null);

export function SelectionProvider({ children }: { children: React.ReactNode }) {
  const [selectedCards, setSelectedCards] = useState<Set<string>>(new Set());

  const deselectAll = () => {
    setSelectedCards(new Set());
  };

  return (
    <SelectionContext.Provider
      value={{ selectedCards, setSelectedCards, deselectAll }}
    >
      {children}
    </SelectionContext.Provider>
  );
}

export function useSelection() {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error("useSelection must be used within SelectionProvider");
  }
  return context;
}
