import { createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@dashboard/common/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import {
  Form,
  FormControl,
  FormField,
  FormMessage,
} from "@dashboard/common/ui/form";
import { FlowOption } from "./-components/FlowOption";
import { flowConfigFormSchema, type FlowConfigForm } from "./-utils/formSchema";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import {
  Mail,
  Ban,
  UserRound,
  Clock,
  Link,
  RefreshCw,
  PackageSearch,
  Truck,
  CreditCard,
  Package,
} from "lucide-react";
import { divainBusinessSlugs } from "@be/modules/integrations/clients/divain/divain.const";
import { z } from "zod";

const FLOW_LABELS = {
  orderCancelation: "Order Cancellations",
  shippingAddressUpdate: "Order Shipping Address Updates",
  contactDetailsUpdate: "Order Contact Details Updates",
  orderItemsUpdate: "Order Items Updates",
  orderTracking: "Order Tracking",
};

const FLOW_OPTION_LABELS = {
  disabled: "Disabled",
  notAllowed: "Not Allowed",
  handOff: "Hand Off",
  holdAndHandOff: "Hold and Hand Off",
  automatic: "Process Automatically (Confirmation via Email)",
  automaticNoEmail: "Process Automatically (Confirmation via Chat)",
};

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/simple-flows/",
)({
  validateSearch: z.object({
    open: z
      .enum([
        "orderCancelation",
        "shippingAddressUpdate",
        "contactDetailsUpdate",
        "orderItemsUpdate",
        "orderTracking",
        "",
      ])
      .optional(),
  }),
  component: FlowsPage,
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.emails.getConfig.ensureData({
      businessSlug,
    });

    await trpcClientUtils.dashboard.flows.getSimpleFlowConfiguration.ensureData(
      {
        businessSlug,
      },
    );

    await trpcClientUtils.dashboard.flows.getAvailableSimpleFlowOptions.ensureData(
      {
        businessSlug,
      },
    );
  },
});

function FlowsPage() {
  const { trpc, business } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const { open } = Route.useSearch();

  const [flowConfig] =
    trpc.dashboard.flows.getSimpleFlowConfiguration.useSuspenseQuery({
      businessSlug,
    });

  const [availableFlowOptions] =
    trpc.dashboard.flows.getAvailableSimpleFlowOptions.useSuspenseQuery({
      businessSlug,
    });

  const [emailConfig] = trpc.dashboard.emails.getConfig.useSuspenseQuery({
    businessSlug,
  });

  const automaticOrderItemsUpdateEnabled =
    divainBusinessSlugs.includes(businessSlug) ||
    businessSlug === "test-business" ||
    businessSlug === "fake-business" ||
    businessSlug === "miravia-demo" ||
    businessSlug === "super-fake-business";

  const defaultValues = {
    orderTracking: flowConfig.orderTracking,
    orderCancelation: flowConfig.orderCancelation,
    shippingAddressUpdate: flowConfig.shippingAddressUpdate,
    contactDetailsUpdate: flowConfig.contactDetailsUpdate,
    orderItemsUpdate: flowConfig.orderItemsUpdate,
  };

  const form = useForm<FlowConfigForm>({
    resolver: zodResolver(flowConfigFormSchema),
    defaultValues,
  });

  const { onSubmit, isLoading } = useSubmitForm();

  const isSubmitting = isLoading || form.formState.isSubmitting;

  const navigate = Route.useNavigate();

  const handleOpenChange = (value: string | undefined) => {
    void navigate({
      search: {
        open: value as
          | "orderTracking"
          | "orderCancelation"
          | "shippingAddressUpdate"
          | "contactDetailsUpdate"
          | "orderItemsUpdate"
          | ""
          | undefined,
      },
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-8"
      >
        <div className="relative py-0">
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              Customer Support Flows
              <span className="text-gray"> {`for ${business.name}`}</span>
            </h1>
            <Button variant="publish" className="ml-auto" type="submit">
              {isSubmitting ? "Saving..." : "Save Changes"}
            </Button>
          </div>

          <div className="max-w-screen-xl px-10 py-5">
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-4"
              value={open}
              onValueChange={(value) => {
                handleOpenChange(value);
              }}
            >
              {availableFlowOptions.orderTracking.length > 0 && (
                <FormField
                  control={form.control}
                  name="orderTracking"
                  render={({ field }) => (
                    <AccordionItem
                      value="orderTracking"
                      className="rounded-lg border"
                    >
                      <AccordionTrigger className="px-4 py-3">
                        <div className="flex flex-col items-start text-left">
                          <div className="text-base font-medium">
                            {FLOW_LABELS.orderTracking}
                          </div>
                          <div className="text-sm text-gray-500">
                            Process Automatically
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="space-y-4 px-4">
                        <FormControl>
                          <div className="space-y-2 py-1">
                            <FlowOption
                              available={availableFlowOptions.orderTracking.includes(
                                "disabled",
                              )}
                              value="disabled"
                              selected={field.value === "disabled"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.disabled}
                              description={[
                                {
                                  text: "Bot will not provide order tracking information",
                                  icon: Ban,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.orderTracking.includes(
                                "automatic",
                              )}
                              value="automatic"
                              selected={field.value === "automatic"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.automatic}
                              description={[
                                {
                                  text: "Bot will look up the order tracking information",
                                  icon: Package,
                                },
                                {
                                  text: "Bot will provide tracking number and carrier details",
                                  icon: Truck,
                                },
                                {
                                  text: "Bot will share a direct link to track the package",
                                  icon: Link,
                                },
                              ]}
                            />
                          </div>
                        </FormControl>
                      </AccordionContent>
                    </AccordionItem>
                  )}
                />
              )}

              {availableFlowOptions.orderCancelation.length > 0 && (
                <FormField
                  control={form.control}
                  name="orderCancelation"
                  render={({ field }) => (
                    <AccordionItem
                      value="orderCancelation"
                      className="rounded-lg border"
                    >
                      <AccordionTrigger className="px-4 py-3">
                        <div className="flex flex-col items-start text-left">
                          <div className="text-base font-medium">
                            {FLOW_LABELS.orderCancelation}
                          </div>
                          <div className="text-sm text-gray-500">
                            {FLOW_OPTION_LABELS[field.value]}
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="space-y-4 px-4">
                        <FormControl>
                          <div className="space-y-2 py-1">
                            <FlowOption
                              available={availableFlowOptions.orderCancelation.includes(
                                "disabled",
                              )}
                              value="disabled"
                              selected={field.value === "disabled"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.disabled}
                              description={[
                                {
                                  text: "Bot will not handle order cancellation requests",
                                  icon: Ban,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.orderCancelation.includes(
                                "notAllowed",
                              )}
                              value="notAllowed"
                              selected={field.value === "notAllowed"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.notAllowed}
                              description={[
                                {
                                  text: "Bot will inform users that cancellations are not possible",
                                  icon: Ban,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.orderCancelation.includes(
                                "handOff",
                              )}
                              value="handOff"
                              selected={field.value === "handOff"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.handOff}
                              description={[
                                {
                                  text: "Bot will hand off the conversation to an agent",
                                  icon: UserRound,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.orderCancelation.includes(
                                "holdAndHandOff",
                              )}
                              value="holdAndHandOff"
                              selected={field.value === "holdAndHandOff"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.holdAndHandOff}
                              description={[
                                {
                                  text: "Bot will hold the fulfillment of the order",
                                  icon: Clock,
                                },
                                {
                                  text: "Bot will hand the conversation off to an agent",
                                  icon: UserRound,
                                },
                              ]}
                              condition="If the order has already been shipped, the bot will inform the customer that the order cannot be cancelled anymore"
                            />
                            <FlowOption
                              available={availableFlowOptions.orderCancelation.includes(
                                "automatic",
                              )}
                              value="automatic"
                              selected={field.value === "automatic"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.automatic}
                              description={[
                                {
                                  text: "The bot will hold the fulfillment of the order",
                                  icon: Clock,
                                },
                                {
                                  text: "The bot will generate a URL for the customer to confirm the cancellation and send it via email",
                                  icon: Link,
                                },
                                {
                                  text: "The order will be cancelled automatically and a full refund will be issued once the customer confirms",
                                  icon: RefreshCw,
                                },
                                {
                                  text: "If not confirmed within 30 minutes, the order fulfillment hold will be removed and link will no longer be valid",
                                  icon: Clock,
                                },
                              ]}
                              condition="If the order has already been shipped, the bot will inform the customer that the order cannot be cancelled anymore"
                              disabled={{
                                isDisabled: !emailConfig,
                                message: {
                                  text: "An email integration is required for this option",
                                  icon: Mail,
                                },
                              }}
                            />
                            <FlowOption
                              available={availableFlowOptions.orderCancelation.includes(
                                "automaticNoEmail",
                              )}
                              value="automaticNoEmail"
                              selected={field.value === "automaticNoEmail"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.automaticNoEmail}
                              description={[
                                {
                                  text: "The bot will hold the fulfillment of the order",
                                  icon: Clock,
                                },
                                {
                                  text: "The bot will generate a URL for the customer to confirm the cancellation and provide it via chat",
                                  icon: Link,
                                },
                                {
                                  text: "The order will be cancelled automatically and a full refund will be issued once the customer confirms",
                                  icon: RefreshCw,
                                },
                                {
                                  text: "If not confirmed within 30 minutes, the order fulfillment hold will be removed and link will no longer be valid",
                                  icon: Clock,
                                },
                              ]}
                              condition="If the order has already been shipped, the bot will inform the customer that the order cannot be cancelled anymore"
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </AccordionContent>
                    </AccordionItem>
                  )}
                />
              )}

              {availableFlowOptions.shippingAddressUpdate.length > 0 && (
                <FormField
                  control={form.control}
                  name="shippingAddressUpdate"
                  render={({ field }) => (
                    <AccordionItem
                      value="shippingAddressUpdate"
                      className="rounded-lg border"
                    >
                      <AccordionTrigger className="px-4 py-3">
                        <div className="flex flex-col items-start text-left">
                          <div className="text-base font-medium">
                            {FLOW_LABELS.shippingAddressUpdate}
                          </div>
                          <div className="text-sm text-gray-500">
                            {FLOW_OPTION_LABELS[field.value]}
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="space-y-4 px-4">
                        <FormControl>
                          <div className="space-y-2 py-1">
                            <FlowOption
                              available={availableFlowOptions.shippingAddressUpdate.includes(
                                "disabled",
                              )}
                              value="disabled"
                              selected={field.value === "disabled"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.disabled}
                              description={[
                                {
                                  text: "Bot will not handle shipping address update requests",
                                  icon: Ban,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.shippingAddressUpdate.includes(
                                "handOff",
                              )}
                              value="handOff"
                              selected={field.value === "handOff"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.handOff}
                              description={[
                                {
                                  text: "Bot will collect the new shipping address",
                                  icon: PackageSearch,
                                },
                                {
                                  text: "Bot will hand off the conversation to an agent",
                                  icon: UserRound,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.shippingAddressUpdate.includes(
                                "holdAndHandOff",
                              )}
                              value="holdAndHandOff"
                              selected={field.value === "holdAndHandOff"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.holdAndHandOff}
                              description={[
                                {
                                  text: "Bot will collect the new shipping address",
                                  icon: PackageSearch,
                                },
                                {
                                  text: "Bot will hold the fulfillment of the order",
                                  icon: Clock,
                                },
                                {
                                  text: "Bot will hand the conversation off to an agent",
                                  icon: UserRound,
                                },
                              ]}
                              condition="If the order has already been shipped, the bot will inform the customer that the order shipping address cannot be changed anymore and that they should contact the shipping carrier"
                            />
                            <FlowOption
                              available={availableFlowOptions.shippingAddressUpdate.includes(
                                "automatic",
                              )}
                              value="automatic"
                              selected={field.value === "automatic"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.automatic}
                              description={[
                                {
                                  text: "Bot will hold the fulfillment of the order",
                                  icon: Clock,
                                },
                                {
                                  text: "Bot will generate a URL for the customer to a page where they can enter their new shipping address and send it via email",
                                  icon: Link,
                                },
                                {
                                  text: "The order shipping address will be updated automatically once the customer confirms the new address",
                                  icon: Truck,
                                },
                                {
                                  text: "The fulfillment hold will be removed automatically once the new address is confirmed",
                                  icon: RefreshCw,
                                },
                                {
                                  text: "If not confirmed within 30 minutes, the order fulfillment hold will be removed and link will no longer be valid",
                                  icon: Clock,
                                },
                              ]}
                              condition="If the order has already been shipped, the bot will inform the customer that the order shipping address cannot be changed anymore and that they should contact the shipping carrier"
                              disabled={{
                                isDisabled: !emailConfig,
                                message: {
                                  text: "An email integration is required for this option",
                                  icon: Mail,
                                },
                              }}
                            />
                            <FlowOption
                              available={availableFlowOptions.shippingAddressUpdate.includes(
                                "automaticNoEmail",
                              )}
                              value="automaticNoEmail"
                              selected={field.value === "automaticNoEmail"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.automaticNoEmail}
                              description={[
                                {
                                  text: "Bot will hold the fulfillment of the order",
                                  icon: Clock,
                                },
                                {
                                  text: "Bot will generate a URL for the customer to a page where they can enter their new shipping address and provide it via chat",
                                  icon: Link,
                                },
                                {
                                  text: "The order shipping address will be updated automatically once the customer confirms the new address",
                                  icon: Truck,
                                },
                                {
                                  text: "The fulfillment hold will be removed automatically once the new address is confirmed",
                                  icon: RefreshCw,
                                },
                                {
                                  text: "If not confirmed within 30 minutes, the order fulfillment hold will be removed and link will no longer be valid",
                                  icon: Clock,
                                },
                              ]}
                              condition="If the customer does not have access to the email address they used to place the order, the bot will transfer the conversation to an agent"
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </AccordionContent>
                    </AccordionItem>
                  )}
                />
              )}

              {availableFlowOptions.contactDetailsUpdate.length > 0 && (
                <FormField
                  control={form.control}
                  name="contactDetailsUpdate"
                  render={({ field }) => (
                    <AccordionItem
                      value="contactDetailsUpdate"
                      className="rounded-lg border"
                    >
                      <AccordionTrigger className="px-4 py-3">
                        <div className="flex flex-col items-start text-left">
                          <div className="text-base font-medium">
                            {FLOW_LABELS.contactDetailsUpdate}
                          </div>
                          <div className="text-sm text-gray-500">
                            {FLOW_OPTION_LABELS[field.value]}
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="space-y-4 px-4">
                        <FormControl>
                          <div className="space-y-2 py-1">
                            <FlowOption
                              available={availableFlowOptions.contactDetailsUpdate.includes(
                                "disabled",
                              )}
                              value="disabled"
                              selected={field.value === "disabled"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.disabled}
                              description={[
                                {
                                  text: "Bot will not handle contact details update requests",
                                  icon: Ban,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.contactDetailsUpdate.includes(
                                "handOff",
                              )}
                              value="handOff"
                              selected={field.value === "handOff"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.handOff}
                              description={[
                                {
                                  text: "Bot will collect the new contact details (email and / or phone number)",
                                  icon: PackageSearch,
                                },
                                {
                                  text: "Bot will hand off the conversation to an agent",
                                  icon: UserRound,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.contactDetailsUpdate.includes(
                                "automatic",
                              )}
                              value="automatic"
                              selected={field.value === "automatic"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.automatic}
                              description={[
                                {
                                  text: "Bot will collect the new contact details (email and / or phone number)",
                                  icon: PackageSearch,
                                },
                                {
                                  text: "Bot will generate a URL for the customer that they can use to confirm the new contact details and send it via email",
                                  icon: Link,
                                },
                                {
                                  text: "The contact details will be updated automatically once the customer confirms the new details",
                                  icon: RefreshCw,
                                },
                              ]}
                              condition="If the customer does not have access to the email address they used to place the order, the bot will transfer the conversation to an agent"
                              disabled={{
                                isDisabled: !emailConfig,
                                message: {
                                  text: "An email integration is required for this option",
                                  icon: Mail,
                                },
                              }}
                            />
                            <FlowOption
                              available={availableFlowOptions.contactDetailsUpdate.includes(
                                "automaticNoEmail",
                              )}
                              value="automaticNoEmail"
                              selected={field.value === "automaticNoEmail"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.automaticNoEmail}
                              description={[
                                {
                                  text: "Bot will collect the new contact details (email and / or phone number)",
                                  icon: PackageSearch,
                                },
                                {
                                  text: "Bot will generate a URL for the customer that they can use to confirm the new contact details and provide it via chat",
                                  icon: Link,
                                },
                                {
                                  text: "The contact details will be updated automatically once the customer confirms the new details",
                                  icon: RefreshCw,
                                },
                              ]}
                              condition="If the customer does not have access to the email address they used to place the order, the bot will transfer the conversation to an agent"
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </AccordionContent>
                    </AccordionItem>
                  )}
                />
              )}

              {availableFlowOptions.orderItemsUpdate.length > 0 && (
                <FormField
                  control={form.control}
                  name="orderItemsUpdate"
                  render={({ field }) => (
                    <AccordionItem
                      value="orderItemsUpdate"
                      className="rounded-lg border"
                    >
                      <AccordionTrigger className="px-4 py-3">
                        <div className="flex flex-col items-start text-left">
                          <div className="text-base font-medium">
                            {FLOW_LABELS.orderItemsUpdate}
                          </div>
                          <div className="text-sm text-gray-500">
                            {FLOW_OPTION_LABELS[field.value]}
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="space-y-4 px-4">
                        <FormControl>
                          <div className="space-y-2 py-1">
                            <FlowOption
                              available={availableFlowOptions.orderItemsUpdate.includes(
                                "disabled",
                              )}
                              value="disabled"
                              selected={field.value === "disabled"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.disabled}
                              description={[
                                {
                                  text: "Bot will not handle order items update requests",
                                  icon: Ban,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.orderItemsUpdate.includes(
                                "notAllowed",
                              )}
                              value="notAllowed"
                              selected={field.value === "notAllowed"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.notAllowed}
                              description={[
                                {
                                  text: "Bot will inform the customer that order modifications are not allowed once an order has been made",
                                  icon: Ban,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.orderItemsUpdate.includes(
                                "handOff",
                              )}
                              value="handOff"
                              selected={field.value === "handOff"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.handOff}
                              description={[
                                {
                                  text: "Bot will collect the desired changes",
                                  icon: PackageSearch,
                                },
                                {
                                  text: "Bot will hand off the conversation to an agent",
                                  icon: UserRound,
                                },
                              ]}
                            />
                            <FlowOption
                              available={availableFlowOptions.orderItemsUpdate.includes(
                                "holdAndHandOff",
                              )}
                              value="holdAndHandOff"
                              selected={field.value === "holdAndHandOff"}
                              onSelect={field.onChange}
                              label={FLOW_OPTION_LABELS.holdAndHandOff}
                              description={[
                                {
                                  text: "Bot will collect the desired changes",
                                  icon: PackageSearch,
                                },
                                {
                                  text: "Bot will hold the fulfillment of the order",
                                  icon: Clock,
                                },
                                {
                                  text: "Bot will hand off the conversation to an agent",
                                  icon: UserRound,
                                },
                              ]}
                              condition="If the order has already been shipped, the bot will inform the customer that the order items cannot be changed anymore."
                            />
                            {automaticOrderItemsUpdateEnabled && (
                              <>
                                <FlowOption
                                  available={availableFlowOptions.orderItemsUpdate.includes(
                                    "automatic",
                                  )}
                                  value="automatic"
                                  selected={field.value === "automatic"}
                                  onSelect={field.onChange}
                                  label={FLOW_OPTION_LABELS.automatic}
                                  description={[
                                    {
                                      text: "Bot will hold the fulfillment of the order",
                                      icon: Clock,
                                    },
                                    {
                                      text: "Bot will generate a URL for the customer to a page where they can modify their order and send it via email",
                                      icon: Link,
                                    },
                                    {
                                      text: "The order will be updated automatically once the customer confirms the changes",
                                      icon: Truck,
                                    },
                                    {
                                      text: "If changes increase the order total, the customer will be redirected to process the additional payment. Any refunds will be processed automatically",
                                      icon: CreditCard,
                                    },
                                    {
                                      text: "The fulfillment hold will be removed automatically once the changes are confirmed and payment is processed",
                                      icon: RefreshCw,
                                    },
                                    {
                                      text: "If not confirmed, the order fulfillment hold will be removed after 30 minutes",
                                      icon: Clock,
                                    },
                                  ]}
                                  condition="If the order has already been shipped, the bot will inform the customer that the order items cannot be changed anymore."
                                  disabled={{
                                    isDisabled: !emailConfig,
                                    message: {
                                      text: "An email integration is required for this option",
                                      icon: Mail,
                                    },
                                  }}
                                />
                                <FlowOption
                                  available={availableFlowOptions.orderItemsUpdate.includes(
                                    "automaticNoEmail",
                                  )}
                                  value="automaticNoEmail"
                                  selected={field.value === "automaticNoEmail"}
                                  onSelect={field.onChange}
                                  label={FLOW_OPTION_LABELS.automaticNoEmail}
                                  description={[
                                    {
                                      text: "Bot will hold the fulfillment of the order",
                                      icon: Clock,
                                    },
                                    {
                                      text: "Bot will generate a URL for the customer to a page where they can modify their order and provide it via chat",
                                      icon: Link,
                                    },
                                    {
                                      text: "The order will be updated automatically once the customer confirms the changes",
                                      icon: Truck,
                                    },
                                    {
                                      text: "If changes increase the order total, the customer will be redirected to process the additional payment. Any refunds will be processed automatically",
                                      icon: CreditCard,
                                    },
                                    {
                                      text: "The fulfillment hold will be removed automatically once the changes are confirmed and payment is processed",
                                      icon: RefreshCw,
                                    },
                                    {
                                      text: "If not confirmed, the order fulfillment hold will be removed after 30 minutes",
                                      icon: Clock,
                                    },
                                  ]}
                                  condition="If the order has already been shipped, the bot will inform the customer that the order items cannot be changed anymore."
                                />
                              </>
                            )}
                          </div>
                        </FormControl>
                        <FormMessage />
                      </AccordionContent>
                    </AccordionItem>
                  )}
                />
              )}
            </Accordion>
          </div>
        </div>
      </form>
    </Form>
  );
}
