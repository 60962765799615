import { toast } from "sonner";
import { Route } from "..";

export function useCancelSubscription() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const cancelMutation = trpc.dashboard.shopify.cancelSubscription.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.shopify.getSubscription.invalidate({
        businessSlug,
      });

      toast.success("Subscription cancelled successfully.");
    },
    onError: (error) => {
      toast.error("Failed to cancel subscription, please try again.");
      console.error(error);
    },
  });

  return {
    cancelSubscription: () => {
      cancelMutation.mutate({ businessSlug });
    },
  };
}
