import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MacroForm, macroFormSchema } from "../-utils/macroFormSchema";
import { MacroFormBody } from "./MacroFormBody";

import { useEditMacro } from "../-hooks/useEditMacro";
import { toast } from "sonner";
import { formatPayload } from "../-utils/formatPayload";
import { MacroAction } from "@be/modules/macros/macros.types";

interface EditMacroFormProps {
  id: string;
  title: string;
  content: string | null;
  availableFor: string;
  businessId: string | null;
  actions: MacroAction[];
  files: { id: string; name: string }[];
  setOpen: (opened: boolean) => void;
}

export function EditMacroForm({
  id,
  title,
  content,
  availableFor,
  businessId,
  actions,
  files,
  setOpen,
}: EditMacroFormProps) {
  const form = useForm<MacroForm>({
    resolver: zodResolver(macroFormSchema),
    defaultValues: {
      title,
      content,
      availableFor,
      businessId,
      actions,
      files,
    },
  });

  const { onSubmit: onSubmitMutation } = useEditMacro({ setOpen });

  function onSubmit(data: MacroForm) {
    const payload = formatPayload(data);
    onSubmitMutation({ ...payload, id });

    form.reset();
  }

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to update macro");
  };

  return <MacroFormBody form={form} onSubmit={onSubmit} onError={onError} />;
}
