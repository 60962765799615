import { toast } from "sonner";
import { Route } from "..";

export function useDeleteProducts() {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const deleteMutation = trpc.dashboard.products.deleteMany.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.products.getAll.invalidate({
        businessSlug,
      });

      await trpcUtils.dashboard.products.getCount.invalidate({
        businessSlug,
      });

      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Products deleted successfully."
          : "Product deleted successfully.";

      toast(message);
    },

    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Failed to delete products."
          : "Failed to delete product.";

      toast(message);
      console.error(error);
    },
  });

  return {
    deleteProducts: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, ids: ids });
    },
  };
}
