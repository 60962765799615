import { Route } from "../..";
import { HideModal } from "./HideModal";
import { DebugModal } from "./DebugModal";
import { Button } from "@dashboard/common/ui/button";
import { Bug, Trash2, Eye, EyeOff, MoreVertical } from "lucide-react";
import { DeletionModal } from "./DeletionModal";
import { useConversation } from "../../-hooks/useConversation.hook";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@dashboard/common/ui/dropdown-menu";
import { useState } from "react";

export function AdminMenu() {
  const [debugModalOpen, setDebugModalOpen] = useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const [hideModalOpen, setHideModalOpen] = useState(false);

  const { user } = Route.useRouteContext();
  const conversation = useConversation();

  if (!user.isAdmin) {
    return null;
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button variant="outline" size="toggle">
            <MoreVertical height={18} width={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent collisionPadding={10} className="space-y-1">
          <DropdownMenuItem
            className="flex w-full items-center gap-2"
            onSelect={() => setDebugModalOpen(true)}
          >
            <Bug className="h-4 w-4" />
            Debug conversation
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex w-full items-center gap-2"
            onSelect={() => setDeletionModalOpen(true)}
          >
            <Trash2 className="h-4 w-4" />
            Delete conversation
          </DropdownMenuItem>
          {conversation.isHidden ? (
            <DropdownMenuItem
              className="flex w-full items-center gap-2"
              onSelect={() => setHideModalOpen(true)}
            >
              <Eye className="h-4 w-4" />
              Unhide conversation
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              className="flex w-full items-center gap-2"
              onSelect={() => setHideModalOpen(true)}
            >
              <EyeOff className="h-4 w-4" />
              Hide conversation
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <DebugModal open={debugModalOpen} setOpen={setDebugModalOpen} />
      <DeletionModal open={deletionModalOpen} setOpen={setDeletionModalOpen} />
      <HideModal open={hideModalOpen} setOpen={setHideModalOpen} />
    </>
  );
}
