import { z } from "zod";

export const customerTagFormSchema = z.object({
  key: z
    .string({
      required_error: "Key is required",
      invalid_type_error: "Key is required",
    })
    .min(1, {
      message: "Key is required",
    }),
  values: z
    .string({
      required_error: "Allowed values are required",
      invalid_type_error: "Allowed values are required",
    })
    .min(1, {
      message: "Allowed values are required",
    }),
  llmInstructions: z
    .string({
      required_error: "Instructions are required",
      invalid_type_error: "Instructions are required",
    })
    .min(1, {
      message: "Instructions are required",
    }),
});

export type CustomerTagForm = z.infer<typeof customerTagFormSchema>;
