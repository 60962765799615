import { Route } from "..";
import { toast } from "sonner";
import { articleExportSchema } from "../-utils/articleExportSchema";
import { z } from "zod";

export function useExportArticles() {
  const { businessSlug } = Route.useParams();
  const search = Route.useSearch();
  const { searchQuery } = search;
  const { trpc } = Route.useRouteContext();

  const [articles] = trpc.dashboard.articles.getAll.useSuspenseQuery({
    searchQuery,
    businessSlug,
  });

  return {
    exportArticles: (ids: string[]) => {
      const selectedArticles = articles.filter((article) =>
        ids.includes(article.id),
      );

      const data: z.infer<typeof articleExportSchema> = selectedArticles.map(
        (article) => ({
          title: article.title,
          content: article.content,
          categoryId: article.categoryId ?? undefined,
          isPublic: article.isPublic,
        }),
      );

      const jsonString = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `octocom-articles-${businessSlug}-${timestamp}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success("Articles exported successfully");
    },
  };
}
