import { Link } from "@tanstack/react-router";
import { Route } from "..";

export function FlowLink({ title, flowId }: { title: string; flowId: string }) {
  const { organizationSlug, businessSlug } = Route.useParams();
  return (
    <Link
      to="/organization/$organizationSlug/settings/$businessSlug/advanced-flows/$flowId"
      params={{ organizationSlug, businessSlug, flowId }}
      className="font-semibold hover:underline"
    >
      {title}
    </Link>
  );
}
