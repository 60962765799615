import { useBanner } from "../../../-hooks/useBanner";
import { BusinessSelect } from "./BusinessSelect";
import RangeSelect from "./RangeSelect";

export function Topbar() {
  const { height: bannerHeight, shouldShow: bannerVisible } = useBanner();

  return (
    <>
      <div className="h-16" />
      <div
        className="border-gray-light fixed top-0 z-10 flex w-full flex-row items-center justify-start border-b bg-white px-10 py-4"
        style={{
          top: bannerVisible ? bannerHeight : "0px",
        }}
      >
        <div className="flex flex-row items-center">
          <h1 className="text-xl font-medium text-black">Usage & Metrics</h1>
        </div>
        <div className="flex items-center gap-2 px-10">
          <BusinessSelect />
          <RangeSelect />
        </div>
      </div>
    </>
  );
}
