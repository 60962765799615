import { useEffect } from "react";
import { z } from "zod";
import { proxy, useSnapshot } from "valtio";
import { env } from "@chatbot/env";

// ?? Why is the disable input state here?
const disableInputStore = proxy({
  inputDisabled: false,
});

export function useDisableInput() {
  return useSnapshot(disableInputStore).inputDisabled;
}

const browserSessionStore = proxy({
  id: undefined as undefined | string,
});

export function useInitializeBrowserSession({
  configId,
}: {
  configId: string | undefined;
}) {
  useEffect(() => {
    if (!configId) return;

    // Try to load existing session from localStorage
    const existingSessionId = loadPersistedBrowserSessionId({ configId });

    if (existingSessionId) {
      // Verify the session still exists on the server
      fetch(
        `${env.CHATBOT_BASE_API_URL}/web-chat/browser-session-exists?browserSessionId=${existingSessionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Commit-Hash": env.CHATBOT_COMMIT_HASH ?? "unknown",
          },
        },
      )
        .then((res) => res.json())
        .then((data) => {
          const parsedData = z.object({ exists: z.boolean() }).safeParse(data);

          if (!parsedData.success) {
            return createNewSession({ configId });
          }

          if (parsedData.data.exists) {
            browserSessionStore.id = existingSessionId;
            return;
          }

          return createNewSession({ configId });
        })
        .catch(() => createNewSession({ configId }));
    } else {
      void createNewSession({ configId });
    }
  }, [configId]);

  return useStoredBrowserSession();
}

async function createNewSession({ configId }: { configId: string }) {
  const response = await fetch(
    `${env.CHATBOT_BASE_API_URL}/web-chat/new-browser-session`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Commit-Hash": env.CHATBOT_COMMIT_HASH ?? "unknown",
      },
      body: JSON.stringify({
        configId,
        userAgent: navigator.userAgent,
      }),
    },
  );

  const data = (await response.json()) as unknown;

  const parsedData = z.object({ browserSessionId: z.string() }).safeParse(data);

  if (!parsedData.success) {
    return;
  }

  browserSessionStore.id = parsedData.data.browserSessionId;
  persistBrowserSessionId({
    configId,
    browserSessionId: parsedData.data.browserSessionId,
  });
}

export function useStoredBrowserSession() {
  return useSnapshot(browserSessionStore).id;
}

function loadPersistedBrowserSessionId({
  configId,
}: {
  configId: string;
}): string | null {
  const value = localStorage.getItem(`octocom-browser-session-id:${configId}`);
  if (!value) return null;

  const parsedUuid = z.string().uuid().safeParse(value);

  if (!parsedUuid.success) {
    return null;
  }

  return parsedUuid.data;
}

function persistBrowserSessionId({
  configId,
  browserSessionId,
}: {
  configId: string;
  browserSessionId: string;
}) {
  localStorage.setItem(
    `octocom-browser-session-id:${configId}`,
    browserSessionId,
  );
}
