import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { formDefaults } from "../../-utils/formDefaults";
import { WebChatConfigForm } from "../../-utils/formSchema";

interface AttentionGrabberSuggestionProps {
  index: number;
}

function AttentionGrabberSuggestion({
  index,
}: AttentionGrabberSuggestionProps) {
  if (index < 0 || index > 2) {
    throw new Error("AttentionGrabberSuggestion index must be between 0 and 2");
  }

  const { watch } = useFormContext<WebChatConfigForm>();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const attentionGrabberSuggestionBubbleBackgroundColor =
    watch("attentionGrabberSuggestionBubbleBackgroundColor") ??
    formDefaults.attentionGrabberSuggestionBubbleBackgroundColor;

  const attentionGrabberSuggestionBubbleTextColor =
    watch("attentionGrabberSuggestionBubbleTextColor") ??
    formDefaults.attentionGrabberSuggestionBubbleTextColor;

  const attentionGrabberSuggestionBubbleHoverBackgroundColor =
    watch("attentionGrabberSuggestionBubbleHoverBackgroundColor") ??
    formDefaults.attentionGrabberSuggestionBubbleHoverBackgroundColor;

  const attentionGrabberSuggestionBubbleHoverTextColor =
    watch("attentionGrabberSuggestionBubbleHoverTextColor") ??
    formDefaults.attentionGrabberSuggestionBubbleHoverTextColor;

  const buttonStyle = {
    backgroundColor: isHovered
      ? attentionGrabberSuggestionBubbleHoverBackgroundColor
      : attentionGrabberSuggestionBubbleBackgroundColor,
    color: isHovered
      ? attentionGrabberSuggestionBubbleHoverTextColor
      : attentionGrabberSuggestionBubbleTextColor,
  };

  const displayText = watch("attentionGrabberSuggestions").at(
    index,
  )?.displayText;

  return (
    <>
      {displayText && (
        <button
          type="button"
          className="w-fit max-w-[470px] rounded-lg px-2.5 py-2.5 text-sm"
          style={buttonStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {displayText}
        </button>
      )}
    </>
  );
}

export default AttentionGrabberSuggestion;
