import { Button } from "@dashboard/common/ui/button";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";
import { Separator } from "@dashboard/common/ui/separator";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { Bookmark } from "lucide-react";
import { useState } from "react";
import { MacroPreview } from "./MacroPreview";
import { MacroAction } from "@be/modules/macros/macros.types";
import { useMacros } from "../../-hooks/useMacros";
import { applyMacro } from "../../-utils/applyMacro";
import { MacroData } from "../../-utils/macroTypes";

export function MacroCommand({
  setInput,
  setActions,
  setFiles,
  macroData,
  businessId,
  disabled = false,
}: {
  setInput: (input: string) => void;
  setActions: (actions: MacroAction[]) => void;
  setFiles: (files: { fileId: string; filename: string }[]) => void;
  macroData?: MacroData;
  businessId?: string;
  disabled?: boolean;
}) {
  const macros = useMacros({ businessId });

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string | undefined>(macros[0]?.title);

  const selectedMacro = macros.find((macro) => macro.title === value);

  if (macros.length === 0) {
    return null;
  }

  function handleSelect() {
    setOpen(false);
    if (selectedMacro) {
      applyMacro({
        macro: selectedMacro,
        setInput,
        setActions,
        setFiles,
        macroData,
      });
    }
  }

  return (
    <>
      <Tooltip
        triggerAsChild
        trigger={
          <Button
            variant="select"
            onClick={() => setOpen(true)}
            disabled={disabled}
            className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
          >
            <Bookmark className="h-5 w-5" />
          </Button>
        }
        content="Use Macro"
      />

      <CommandDialog
        open={open}
        onOpenChange={setOpen}
        value={value}
        onValueChange={(v) => setValue(v)}
        className="h-[50vh] w-[1000px] max-w-[1000px]"
      >
        <CommandInput icon={Bookmark} placeholder="Use Macro..." />
        <div className="flex h-full">
          <CommandList className="h-full max-h-full w-[40%] shrink-0">
            <CommandEmpty className="flex items-center justify-center">
              No Macros found
            </CommandEmpty>
            <CommandGroup className="h-full">
              {macros.map((macro) => (
                <CommandItem key={macro.id} onSelect={handleSelect}>
                  {macro.title}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          <Separator orientation="vertical" />
          {selectedMacro && <MacroPreview macro={selectedMacro} />}
        </div>
      </CommandDialog>
    </>
  );
}
