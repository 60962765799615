import { createFileRoute } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@dashboard/common/ui/form";
import { Button } from "@dashboard/common/ui/button";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  RecommendationSettingsForm,
  recommendationSettingsFormSchema,
} from "./-utils/recommendationSettingsFormSchema";
import { Textarea } from "@dashboard/common/ui/textarea";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import { useEffect } from "react";
import { Trash2 } from "lucide-react";
import { Separator } from "@dashboard/common/ui/separator";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/product-search/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.products.getConfig.ensureData({
      businessSlug,
    });
  },

  component: RecommendationSettingsPage,
});

function RecommendationSettingsPage() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [config] = trpc.dashboard.products.getConfig.useSuspenseQuery({
    businessSlug,
  });

  let defaultValues: RecommendationSettingsForm = {
    collections: null,
    metafields: null,
    nameHints: [],
    productPickingHints: [],
    collectionPickingHints: [],
  };

  if (config) {
    defaultValues = {
      collections: config.publicCollectionSlugs?.join(",") ?? null,
      metafields: config.publicProductMetafieldKeys?.join(",") ?? null,
      nameHints:
        config.nameHints?.map((hint) => {
          return { hint };
        }) ?? [],
      productPickingHints:
        config.productPickingHints?.map((hint) => {
          return { hint };
        }) ?? [],
      collectionPickingHints:
        config.collectionPickingHints?.map((hint) => {
          return { hint };
        }) ?? [],
    };
  }

  const form = useForm<RecommendationSettingsForm>({
    resolver: zodResolver(recommendationSettingsFormSchema),
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [config]);

  const {
    fields: nameFields,
    append: appendName,
    remove: removeName,
  } = useFieldArray({
    control: form.control,
    name: "nameHints",
  });

  const {
    fields: productFields,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    control: form.control,
    name: "productPickingHints",
  });

  const {
    fields: collectionFields,
    append: appendCollection,
    remove: removeCollection,
  } = useFieldArray({
    control: form.control,
    name: "collectionPickingHints",
  });

  const removeHint = ({
    index,
    type,
  }: {
    index: number;
    type: "name" | "product" | "collection";
  }) => {
    switch (type) {
      case "name":
        removeName(index);
        break;
      case "product":
        removeProduct(index);
        break;
      case "collection":
        removeCollection(index);
        break;
    }
  };

  const addHint = ({ type }: { type: "name" | "product" | "collection" }) => {
    switch (type) {
      case "name":
        appendName({ hint: "" });
        break;
      case "product":
        appendProduct({ hint: "" });
        break;
      case "collection":
        appendCollection({ hint: "" });
        break;
    }
  };

  const { onSubmit, onError } = useSubmitForm();

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}
        className="space-y-4"
      >
        <div className="flex h-full flex-col text-justify">
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              Product Search Settings
            </h1>
            <Button variant="publish" className="ml-auto" type="submit">
              {form.formState.isSubmitting ? "Submitting..." : "Save"}
            </Button>
          </div>

          <div className="px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="collections"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Whitelisted Collections</FormLabel>
                    <FormDescription>
                      The slugs of the collections that are active on your
                      store. The slugs need to match the values your store
                      backend uses.
                    </FormDescription>
                    <FormControl>
                      <Textarea
                        rows={4}
                        placeholder="Enter the collections of your online store you would like to whitelist (optional)"
                        name={field.name}
                      />
                    </FormControl>
                    <FormDescription>
                      Enter the collections as a comma-separated list (e.g.
                      collection1,collection2,...)
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="metafields"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Whitelisted Metafields</FormLabel>
                    <FormDescription>
                      The active metafields used for filtering products on your
                      store. The metafields need to match the values your store
                      backend uses. e.g. &#34;color&#34;, &#34;size&#34;, etc.
                    </FormDescription>
                    <FormControl>
                      <Textarea
                        rows={4}
                        placeholder="Enter the metafields of your online store you would like to whitelist (optional)"
                        name={field.name}
                      />
                    </FormControl>
                    <FormDescription>
                      Enter the metafields as a comma-separated list (e.g.
                      metafield1,metafield2,...)
                    </FormDescription>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <Separator />

              <div className="w-full">
                <FormLabel>Hints for the Recommendation Engine</FormLabel>
                <FormDescription>
                  Fine-tune the recommendation engine by providing hints that
                  explain the context of your product area.
                </FormDescription>
              </div>

              <Separator />

              <div className="w-full">
                <FormLabel>Name Hints</FormLabel>
                <FormDescription>
                  Hints that help the recommendation engine understand any
                  intricacies in the product naming.
                </FormDescription>
              </div>

              {nameFields.map((field, index) => (
                <FormField
                  key={field.id}
                  control={form.control}
                  name={`nameHints.${index}.hint`}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <div className="flex w-full items-center justify-between gap-2.5">
                        <FormControl>
                          <Textarea
                            rows={2}
                            placeholder="Enter the hint"
                            name={field.name}
                          />
                        </FormControl>
                        <Button
                          type="button"
                          variant="destructive"
                          onClick={() => removeHint({ index, type: "name" })}
                          className="px-2 py-2"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}

              <Button
                type="button"
                variant="secondary"
                onClick={() => addHint({ type: "name" })}
              >
                Add Hint
              </Button>

              <Separator />

              <div className="w-full">
                <FormLabel>Product Picking Hints</FormLabel>
                <FormDescription>
                  Hints that help the recommendation engine pick the appropriate
                  products when making recommendations.
                </FormDescription>
              </div>

              {productFields.map((field, index) => (
                <FormField
                  key={field.id}
                  control={form.control}
                  name={`productPickingHints.${index}.hint`}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <div className="flex w-full items-center justify-between gap-2.5">
                        <FormControl>
                          <Textarea
                            rows={2}
                            placeholder="Enter the hint"
                            name={field.name}
                          />
                        </FormControl>
                        <Button
                          type="button"
                          variant="destructive"
                          onClick={() => removeHint({ index, type: "product" })}
                          className="px-2 py-2"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}

              <Button
                type="button"
                variant="secondary"
                className="w-full"
                onClick={() => addHint({ type: "product" })}
              >
                Add Hint
              </Button>

              <Separator />

              <div className="w-full">
                <FormLabel>Collection Picking Hints</FormLabel>
                <FormDescription>
                  Hints that help the recommendation engine pick the appropriate
                  product collections when making recommendations.
                </FormDescription>
              </div>

              {collectionFields.map((field, index) => (
                <FormField
                  key={field.id}
                  control={form.control}
                  name={`collectionPickingHints.${index}.hint`}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <div className="flex w-full items-center justify-between gap-2.5">
                        <FormControl>
                          <Textarea
                            rows={2}
                            placeholder="Enter the hint"
                            name={field.name}
                          />
                        </FormControl>

                        <Button
                          type="button"
                          variant="destructive"
                          onClick={() =>
                            removeHint({ index, type: "collection" })
                          }
                          className="px-2 py-2"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}

              <Button
                type="button"
                variant="secondary"
                className="w-full"
                onClick={() => addHint({ type: "collection" })}
              >
                Add Hint
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
