import { Subscription } from "@be/modules/subscriptions/subscriptions.types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { format } from "date-fns";
import { ItemDetailSection } from "../Orders/OrderDetailSection";
import { Button } from "@dashboard/common/ui/button";
import { useSubscriptionCancel } from "../../../-hooks/subscriptions/useSubscriptionCancel";
import { useSkipSubscriptionMonth } from "../../../-hooks/subscriptions/useSkipSubscriptionMonth";
import { useState } from "react";
import { cn } from "@dashboard/utils/ui";

export function SubscriptionDetails({
  subscription,
}: {
  subscription: Subscription;
}) {
  const { cancelSubscription } = useSubscriptionCancel();
  const { skipSubscriptionMonth } = useSkipSubscriptionMonth();

  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] =
    useState(false);

  const [skipSubscriptionDialogOpen, setSkipSubscriptionDialogOpen] =
    useState(false);

  return (
    <AccordionItem
      value={subscription.subscriptionId}
      className={`data-[state=open]:bg-offWhite rounded-xl border-none !shadow-none`}
    >
      <AccordionTrigger className="hover:bg-offWhite flex justify-between rounded-xl px-3 py-2">
        <div className="flex w-full items-center justify-between gap-4">
          <span className="text-xs font-medium">{subscription.planName}</span>
          {subscription.status && (
            <span
              className={cn(
                "ml-auto rounded-full bg-gray-200 px-3 py-0.5 text-[10px] font-semibold text-gray-800",
                // TODO: The status colors should come from variables like isActive, isCanceled, since each system may use different terms
                subscription.status === "active"
                  ? "bg-[#CDFFD0] text-[#008000]"
                  : undefined,
                subscription.status === "canceled"
                  ? "bg-[#FFBCBC] text-[#B30000]"
                  : undefined,
              )}
            >
              {subscription.status}
            </span>
          )}
        </div>
      </AccordionTrigger>
      <AccordionContent className="px-3 pb-1 pt-1 text-xs">
        <div className="mb-2 flex items-center justify-center gap-2 rounded-lg bg-gray-200 p-1">
          <Dialog
            open={cancelSubscriptionDialogOpen}
            onOpenChange={setCancelSubscriptionDialogOpen}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Cancel Subscription</DialogTitle>
              </DialogHeader>
              <div>
                Are you sure you want to cancel the subscription with plan
                name:&nbsp;
                {subscription.planName} for {subscription.email}?
              </div>
              <DialogFooter>
                <Button
                  variant="secondary"
                  onClick={() => setCancelSubscriptionDialogOpen(false)}
                >
                  Close
                </Button>
                <Button
                  variant="destructive"
                  onClick={() => {
                    cancelSubscription({
                      subscriptionId: subscription.subscriptionId,
                    });
                    setCancelSubscriptionDialogOpen(false);
                  }}
                >
                  Cancel Subscription
                </Button>
              </DialogFooter>
            </DialogContent>
            <Button
              onClick={() => setCancelSubscriptionDialogOpen(true)}
              variant="secondary"
              className="h-6 flex-grow px-2 py-1 text-xs font-normal"
            >
              Cancel Subscription
            </Button>
          </Dialog>
        </div>
        {subscription.skippable && subscription.nextChargeDate !== null && (
          <div className="mb-2 flex items-center justify-center gap-2 rounded-lg bg-gray-200 p-1">
            <Dialog
              open={skipSubscriptionDialogOpen}
              onOpenChange={setSkipSubscriptionDialogOpen}
            >
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Skip month</DialogTitle>
                </DialogHeader>
                <div>
                  Are you sure you want to skip a month of the subscription with
                  plan name:&nbsp;
                  {subscription.planName} for {subscription.email}?
                </div>

                <DialogFooter>
                  <Button
                    variant="secondary"
                    onClick={() => setSkipSubscriptionDialogOpen(false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      skipSubscriptionMonth({
                        subscriptionId: subscription.subscriptionId,
                        nextChargeDate:
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          subscription.nextChargeDate! as unknown as string,
                      });
                      setSkipSubscriptionDialogOpen(false);
                    }}
                  >
                    Skip Month
                  </Button>
                </DialogFooter>
              </DialogContent>
              <Button
                onClick={() => setSkipSubscriptionDialogOpen(true)}
                variant="secondary"
                className="h-6 flex-grow px-2 py-1 text-xs font-normal"
              >
                Skip Month
              </Button>
            </Dialog>
          </div>
        )}
        <div
          className={
            subscription.products
              ? "data-[state=open]:border-gray-light border-gray-light border-b pb-2"
              : ""
          }
        >
          <ItemDetailSection
            details={[
              {
                label: "Subscription ID:",
                value: subscription.subscriptionId,
              },
              ...(subscription.email
                ? [
                    {
                      label: "Customer email:",
                      value: subscription.email,
                    },
                  ]
                : []),
              ...(subscription.createdAt
                ? [
                    {
                      label: "Created at:",
                      value: format(
                        subscription.createdAt,
                        "MMM d, yyyy HH:mm",
                      ),
                    },
                  ]
                : []),
              ...(subscription.nextChargeDate
                ? [
                    {
                      label: "Next charge date:",
                      value: format(
                        subscription.nextChargeDate,
                        "MMM d, yyyy HH:mm",
                      ),
                    },
                  ]
                : []),
              ...(subscription.intervalType
                ? [
                    {
                      label: "Interval type:",
                      value: subscription.intervalType,
                    },
                  ]
                : []),
              ...(subscription.intervalCount
                ? [
                    {
                      label: "Interval count:",
                      value: subscription.intervalCount,
                    },
                  ]
                : []),
              ...(subscription.price
                ? [
                    {
                      label: "Price:",
                      value: subscription.price + " " + subscription.currency,
                    },
                  ]
                : []),
            ]}
          />
        </div>
        <Accordion
          type="multiple"
          className="w-full"
          defaultValue={["products", "addOns"]}
        >
          {subscription.products && (
            <AccordionItem
              value="products"
              className={`my-1 !shadow-none ${
                subscription.addOns
                  ? "data-[state=open]:border-gray-light border-gray-light"
                  : "border-none"
              }`}
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Products
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                {subscription.products.map((product) => (
                  <ItemDetailSection
                    key={product.slug}
                    details={[
                      {
                        label: "Name:",
                        value: product.name,
                      },
                      {
                        label: "Price:",
                        value: product.price,
                      },
                      {
                        label: "Quantity:",
                        value: product.quantity,
                      },
                    ]}
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          )}
          {subscription.addOns && (
            <AccordionItem
              value="addOns"
              className={`my-1 !shadow-none ${
                subscription.metafields
                  ? "data-[state=open]:border-gray-light border-gray-light"
                  : "border-none"
              }`}
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Add-ons
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                {subscription.addOns.map((addOn) => (
                  <ItemDetailSection
                    key={addOn.slug}
                    details={[
                      {
                        label: "Name:",
                        value: addOn.name,
                      },
                      {
                        label: "Price:",
                        value: addOn.price,
                      },
                      {
                        label: "Quantity:",
                        value: addOn.quantity,
                      },
                    ]}
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          )}
          {subscription.metafields && (
            <AccordionItem
              value="metafields"
              className="my-1 border-none !shadow-none"
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Custom fields
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                {subscription.metafields.map((metafield) => (
                  <ItemDetailSection
                    key={metafield.key}
                    details={[
                      {
                        label: `${metafield.key}:`,
                        value: metafield.value,
                      },
                    ]}
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          )}
        </Accordion>
      </AccordionContent>
    </AccordionItem>
  );
}
