import { Route } from "..";
import { toast } from "sonner";

export function useMarkQuestionAsIrrelevant() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const mutation = trpc.dashboard.bots.markQuestionAsIrrelevant.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.bots.getKnowledgeSummary.invalidate({
        businessSlug: organizationSlug,
      });

      toast.success("Question marked as irrelevant");
    },
    onError: (error) => {
      toast.error("Failed to mark question as irrelevant, please try again.");
      console.error(error);
    },
  });

  return {
    markQuestionAsIrrelevant: ({ questionId }: { questionId: string }) => {
      mutation.mutate({ businessSlug: organizationSlug, questionId });
    },
  };
}
