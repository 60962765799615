import { Mail, Check } from "lucide-react";
import {
  FaFacebookMessenger,
  FaFacebookSquare,
  FaInstagram,
  FaWhatsapp,
  FaTelegram,
} from "react-icons/fa";
import { Link } from "@tanstack/react-router";
import { Route } from "../..";
import { cn } from "@dashboard/utils/ui";
import { Button } from "@dashboard/common/ui/button";
import { useCompleteOnboardingStep } from "../../-hooks/useCompleteOnboardingStep";

interface SetupChannelsProps {
  organizationSlug: string;
  businessSlug: string;
  completed: boolean;
  includeEmailChannel: boolean;
}

export function SetupChannels({
  businessSlug,
  organizationSlug,
  completed,
  includeEmailChannel,
}: SetupChannelsProps) {
  const { trpc } = Route.useRouteContext();

  const [installedIntegrations] =
    trpc.dashboard.integrations.getInstalledIntegrations.useSuspenseQuery({
      businessSlug,
    });

  const completeStep = useCompleteOnboardingStep();

  const channels = [
    ...(includeEmailChannel
      ? [
          {
            name: "Email",
            link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=smtpImap",
            icon: Mail,
            description:
              "Let our AI handle email inquiries automatically, with smart routing and response suggestions for your team when needed.",
            installed: installedIntegrations.some((ii) =>
              ["smtpImap", "outlook", "google"].includes(ii),
            ),
          },
        ]
      : []),
    {
      name: "Messenger",
      link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=messenger",
      icon: FaFacebookMessenger,
      description:
        "Meet your customers on Facebook Messenger with instant automated responses and seamless handoff to human agents.",
      installed: installedIntegrations.includes("messenger"),
    },
    {
      name: "Facebook",
      link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=messenger",
      icon: FaFacebookSquare,
      description:
        "Never miss a Facebook comment with AI-powered monitoring and responses that keep your social engagement high.",
      installed: installedIntegrations.includes("messenger"),
    },
    {
      name: "Instagram",
      link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=instagram",
      icon: FaInstagram,
      description:
        "Boost Instagram engagement with intelligent comment monitoring and responses that maintain your brand voice.",
      installed: installedIntegrations.includes("instagram"),
    },
    {
      name: "WhatsApp",
      link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=whatsapp",
      icon: FaWhatsapp,
      description:
        "Transform your WhatsApp Business account with automated responses, order updates, and seamless customer support.",
      installed: installedIntegrations.includes("whatsapp"),
    },
    {
      name: "Telegram",
      link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=telegram",
      icon: FaTelegram,
      description:
        "Leverage Telegram's features with automated support, broadcast messages, and group chat management.",
      installed: installedIntegrations.includes("telegram"),
    },
  ];
  return (
    <div className="space-y-6">
      <div className="prose prose-sm max-w-none">
        <p className="text-black">
          The Octocom bot can handle conversations across multiple channels. Set
          up the ones that best match how your customers prefer to communicate.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        {channels.map((channel) => (
          <Link
            key={channel.name}
            to={channel.link}
            params={{
              organizationSlug,
              businessSlug,
            }}
            className={cn(
              "border-blue-light hover:bg-blue-light hover:border-blue flex cursor-pointer flex-col items-start justify-start gap-2 rounded-lg border p-4 transition-all duration-200 ease-in-out hover:shadow-lg",
              {
                "bg-blue-light": channel.installed,
                "bg-white": !channel.installed,
              },
            )}
          >
            <div className="flex w-full items-center justify-between">
              <div
                className={cn("bg-primary/10 rounded-md p-2", {
                  "bg-blue text-white": channel.installed,
                })}
              >
                <channel.icon className="h-5 w-5" strokeWidth={1.5} />
              </div>
              {channel.installed && (
                <div className="bg-blue flex items-center gap-1 rounded-lg px-2 py-1 text-sm text-white">
                  <Check className="h-4 w-4" />
                  Installed
                </div>
              )}
            </div>
            <h3 className="text-lg font-medium text-gray-800">
              {channel.name}
            </h3>
            <p className="text-left text-gray-600">{channel.description}</p>
          </Link>
        ))}
      </div>

      <div className="prose prose-sm max-w-none">
        <p className="text-black">
          Each channel integration expands your bot&apos;s reach, ensuring
          consistent and efficient customer service wherever your audience is.
          Configure each channel&apos;s settings to optimize the experience for
          your specific business needs.
        </p>
      </div>
      {!completed ? (
        <Button
          onClick={() => completeStep({ step: "channels" })}
          variant="secondary"
          className="w-full"
        >
          Complete
        </Button>
      ) : (
        <Button className="w-full">
          <Check className="mr-2 h-4 w-4" />
          Completed
        </Button>
      )}
    </div>
  );
}
