import { toast } from "sonner";
import { Route } from "../../_conversationList";

export function useAssignConversations() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { view, filters, status, sortBy, searchQuery } = Route.useSearch();
  const trpcUtils = trpc.useUtils();

  const assignConversationsMutation =
    trpc.dashboard.conversations.handOffAndAssignMany.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.conversations.getAll.invalidate({
          organizationSlug,
          view,
          filters,
          status,
          sortBy,
          searchQuery,
        });

        await trpcUtils.dashboard.conversations.getCount.invalidate({
          organizationSlug,
          view,
          status,
          filters,
          searchQuery,
        });

        await trpcUtils.dashboard.conversations.get.invalidate({
          organizationSlug,
        });

        toast.success("Conversations assigned");
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to assign conversations");
      },
    });

  const assignConversations = ({
    publicIds,
    assigneeId,
  }: {
    publicIds: string[];
    assigneeId: string;
  }) => {
    assignConversationsMutation.mutate({
      organizationSlug,
      publicIds,
      assigneeId,
    });
  };

  return assignConversations;
}
