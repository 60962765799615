import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { ConfigurationAccordionItem } from "./ConfigurationAccordionItem";
import { MessageSquareIcon, MailIcon } from "lucide-react";

export function Channels() {
  return (
    <ConfigurationAccordionItem value="channels" title="Channels">
      <SidebarButton
        icon={MessageSquareIcon}
        text="Web Chat"
        to="/organization/$organizationSlug/settings/$businessSlug/web-chat"
      />
      <SidebarButton
        icon={MailIcon}
        text="Email"
        to="/organization/$organizationSlug/settings/$businessSlug/email"
      />
    </ConfigurationAccordionItem>
  );
}
