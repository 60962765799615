import { toast } from "sonner";
import { Route } from "..";
import { TRPCClientErrorBase } from "@trpc/client";
import { DefaultErrorShape } from "@trpc/server/unstable-core-do-not-import";
import { z } from "zod";
import { useNavigate } from "@tanstack/react-router";

export const ratingFormSchema = z.object({
  rating: z.number().min(1).max(5),
  comment: z.string().optional().nullable(),
});

export type RatingForm = z.infer<typeof ratingFormSchema>;

export function useSubmitForm({
  publicId,
  customerEmail,
}: {
  publicId: string;
  customerEmail: string;
}) {
  const { trpc } = Route.useRouteContext();
  const navigate = useNavigate();

  const createMutation =
    trpc.dashboard.conversations.ratings.create.useMutation({
      onSuccess: () => {
        void navigate({
          to: "/public-routes/rate-conversation/success",
        });
      },
      onError: (error: TRPCClientErrorBase<DefaultErrorShape>) => {
        if (error.data?.code === "CONFLICT") {
          toast.error(
            "You have already submitted a rating for this conversation",
          );
        } else {
          toast.error(
            "Failed to submit conversation rating, please try again later.",
          );
        }
      },
    });

  return {
    onSubmit: (data: RatingForm) => {
      createMutation.mutate({
        publicId,
        customerEmail,
        rating: data.rating,
        comment: data.comment === "" ? null : data.comment,
      });
    },
  };
}
