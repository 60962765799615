import ReactMarkdown from "react-markdown";
import { cn } from "@dashboard/utils/ui";
import { ImageDialog } from "./ImageDialog";
import "@dashboard/styles/markdownContent.css";
import remarkGfm from "remark-gfm";

interface MarkdownContentProps {
  content: string;
  className?: string;
}

export function MarkdownContent({ content, className }: MarkdownContentProps) {
  return (
    <div className={cn("markdown-content", className)}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          img: ({ src, alt, title }) => {
            if (!src) return null;
            return <ImageDialog src={src} alt={alt} title={title} />;
          },
          table: ({ children }) => (
            <table className="my-4 w-full rounded-lg border border-gray-400">
              {children}
            </table>
          ),
          th: ({ children }) => (
            <th className="border border-gray-400 bg-gray-100 px-4 py-2 text-left">
              {children}
            </th>
          ),
          td: ({ children }) => (
            <td className="border border-gray-400 px-4 py-2">{children}</td>
          ),
          code: ({ children }) => (
            <code className="text-wrap rounded bg-gray-100 px-1 text-sm">
              {children}
            </code>
          ),
          a: ({ children, href }) => (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}
