import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TemplateForm, templateFormSchema } from "../-utils/templateFormSchema";
import { TemplateFormBody } from "./TemplateFormBody";
import { useEditTemplate } from "../-hooks/useEditTemplate";

export function EditTemplateForm({
  id,
  name,
  content,
  setOpen,
}: {
  id: string;
  name: string;
  content: string;
  setOpen: (opened: boolean) => void;
}) {
  const form = useForm<TemplateForm>({
    resolver: zodResolver(templateFormSchema),
    defaultValues: {
      name,
      content,
    },
  });

  const { onSubmit: onSubmitMutation } = useEditTemplate({ setOpen });

  function onSubmit(data: TemplateForm) {
    onSubmitMutation({ ...data, id });

    form.reset();
  }

  return <TemplateFormBody form={form} onSubmit={onSubmit} />;
}
