export function getStatusColor(status?: string) {
  if (!status) return "bg-gray-100 text-gray-600";

  const statusMap: Record<string, string> = {
    PENDING: "bg-yellow-100 text-yellow-800",
    UNFULFILLED: "bg-gray-100 text-gray-800",
    ON_HOLD: "bg-orange-100 text-orange-800",
    PARTIALLY_FULFILLED: "bg-blue-100 text-blue-800",
    REQUEST_DECLINED: "bg-red-100 text-red-800",
    IN_TRANSIT: "bg-purple-100 text-purple-800",
    DELIVERED: "bg-green-100 text-green-800",
    LABEL_PRINTED: "bg-blue-100 text-blue-800",
    LABEL_PURCHASED: "bg-blue-100 text-blue-800",
    LABEL_VOIDED: "bg-gray-100 text-gray-800",
    MARKED_AS_FULFILLED: "bg-green-100 text-green-800",
    NOT_DELIVERED: "bg-red-100 text-red-800",
    OUT_FOR_DELIVERY: "bg-purple-100 text-purple-800",
    PICKED_UP: "bg-blue-100 text-blue-800",
    READY_FOR_PICKUP: "bg-yellow-100 text-yellow-800",
    SUBMITTED: "bg-blue-100 text-blue-800",
  };

  return statusMap[status] || "bg-gray-100 text-gray-600";
}
