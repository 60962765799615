import { createFileRoute, useNavigate } from "@tanstack/react-router";

import octocomLogo from "@dashboard/assets/octocom-logo.svg";
import gorgiasLogo from "@dashboard/assets/gorgias.svg";
import zendeskLogo from "@dashboard/assets/zendesk.svg";
import { Check, Mail, CircleHelp } from "lucide-react";
import { Button } from "@dashboard/common/ui/button";
import { useUpdateOnboardingStatus } from "../-hooks/useUpdateOnboardingStatus";
import { useOrganization } from "../-hooks/useOrganization";
import { useExternalTicketConfig } from "../-hooks/useExternalTicketConfig";
import { useRemoveExternalTicketConfig } from "../-hooks/useRemoveExternalTicketConfig";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { cn } from "@dashboard/utils/ui";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/help-desk/",
)({
  component: HelpDeskPage,
});

function HelpDeskPage() {
  const { organizationSlug } = Route.useParams();

  const organization = useOrganization();
  const externalTicketConfig = useExternalTicketConfig();
  const stepCompleted = organization.onboardingStatus !== "HELP-DESK";

  let selectedOption: string | null = null;

  if (externalTicketConfig) {
    selectedOption = externalTicketConfig.system;
  } else if (stepCompleted) {
    selectedOption = "octocom";
  }

  const removeConfiguration = useRemoveExternalTicketConfig();
  const navigate = useNavigate();

  const { update } = useUpdateOnboardingStatus({
    onSuccess: async () => {
      await removeConfiguration();
      void navigate({
        to: "/organization/$organizationSlug/onboarding/knowledge",
        params: { organizationSlug },
      });
    },
  });

  const options = [
    {
      name: "octocom",
      label: "Octocom (Built-in and free)",
      logo: octocomLogo,
      onClick: () => {
        update("KNOWLEDGE");
      },
    },
    {
      name: "gorgias",
      label: "Gorgias",
      logo: gorgiasLogo,
      onClick: () => {
        void navigate({
          to: "/organization/$organizationSlug/onboarding/help-desk/gorgias",
          params: { organizationSlug },
        });
      },
    },
    {
      name: "zendesk",
      label: "Zendesk",
      logo: zendeskLogo,
      onClick: () => {
        void navigate({
          to: "/organization/$organizationSlug/onboarding/help-desk/zendesk",
          params: { organizationSlug },
        });
      },
    },
    {
      name: "email",
      label: "Email",
      logo: Mail,
      onClick: () => {
        void navigate({
          to: "/organization/$organizationSlug/onboarding/help-desk/email",
          params: { organizationSlug },
        });
      },
    },
  ];

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Where should AI forward unhandled tickets?
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Select where the AI should send tickets it cannot resolve. You can use
        Octocom’s free built-in help desk, connect to a platform you already
        use, or forward tickets to email.
      </h2>
      <div className="flex w-full max-w-3xl flex-col gap-4 py-8">
        {options.map((option) => (
          <div key={option.name} className="relative">
            <Button
              onClick={option.onClick}
              variant="outline"
              className="h-10 w-full justify-between border-[#041627] font-semibold"
            >
              <div className="flex items-center">
                {option.name === "email" ? (
                  <option.logo className="mr-4 h-6 w-6" strokeWidth={1.5} />
                ) : (
                  <img
                    src={option.logo as string}
                    alt={`${option.label} Logo`}
                    className="mr-4 h-6 w-6"
                  />
                )}
                {option.label}
              </div>
              {option.name === selectedOption && (
                <Check className="h-6 w-6" strokeWidth={1.5} />
              )}
            </Button>
            {option.name === "email" && (
              <HoverCard>
                <HoverCardTrigger asChild>
                  <CircleHelp
                    className={cn(
                      "absolute top-1/2 -translate-y-1/2 transform cursor-pointer",
                      option.name === selectedOption ? "right-12" : "right-3",
                    )}
                    strokeWidth={1.5}
                  />
                </HoverCardTrigger>
                <HoverCardContent className="w-[400px]" side="top">
                  <div className="space-y-4 text-sm leading-relaxed text-gray-800">
                    <h4 className="font-semibold">
                      How Email Forwarding Works
                    </h4>

                    <p>
                      When the AI cannot resolve a ticket, it automatically
                      forwards the conversation to your specified email address.
                    </p>

                    <div className="space-y-2">
                      <p className="font-semibold">
                        What's included in the email:
                      </p>
                      <ul className="ml-4 list-disc space-y-1">
                        <li>Complete chat history for full context</li>
                        <li>Customer contact details (email, phone)</li>
                        <li>
                          Order IDs and other configurable info (return reason,
                          new shipping address, etc.)
                        </li>
                      </ul>
                    </div>

                    <p>
                      Emails are delivered instantly, allowing you to manage
                      customer support directly from your inbox - no additional
                      tools required.
                    </p>
                  </div>
                </HoverCardContent>
              </HoverCard>
            )}
          </div>
        ))}
        <p className="text-center text-sm text-gray-600">
          All of these options take just 30 seconds to set up. You can change
          this later in the settings.
        </p>

        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/tone"
          nextPath="/organization/$organizationSlug/onboarding/knowledge"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}
