import { DialogHeader, DialogTitle } from "@dashboard/common/ui/dialog";
import { EditTemplateForm } from "./EditTemplateForm";

export function EditTemplateContent({
  id,
  name,
  content,
  setOpen,
}: {
  id: string;
  name: string;
  content: string;
  setOpen: (opened: boolean) => void;
}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle className="mx-auto">Edit Template</DialogTitle>
      </DialogHeader>
      <EditTemplateForm
        id={id}
        name={name}
        content={content}
        setOpen={setOpen}
      />
    </>
  );
}
