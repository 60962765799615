import { z } from "zod";

export const integrationServiceNameSchema = z.union([
  // Help desks
  z.literal("zendesk"),
  z.literal("gorgias"),
  z.literal("dixa"),
  z.literal("hubspot"),
  z.literal("richpanel"),
  // z.literal("kustomer"),
  // z.literal("reamaze"),
  // z.literal("liveAgent"),
  // z.literal("freshdesk"),

  // Ecom Platforms
  z.literal("shopify"),
  z.literal("wix"),
  // z.literal("netoHq"),
  // z.literal("prestashop"),
  // z.literal("bitrix"),
  // z.literal("woocommerce"),
  // z.literal("magento"),
  z.literal("bigCommerce"),
  z.literal("aftership"),

  // Email Marketing
  z.literal("klaviyo"),
  z.literal("braze"),

  // Channels
  z.literal("smtpImap"),
  z.literal("telegram"),
  z.literal("messenger"),
  z.literal("instagram"),
  z.literal("whatsapp"),
  z.literal("outlook"),
  z.literal("google"),
]);
