import { Route } from "..";
import { SignUpFormSchema } from "../-utils/signUpFormSchema";

export function useSignUp({ setError }: { setError: (error: string) => void }) {
  const { trpc } = Route.useRouteContext();

  const signUpMutation = trpc.dashboard.auth.signUp.useMutation({
    onSuccess: (data) => {
      if (data.success) {
        window.location.href = "/organization";
      } else {
        setError(data.error);
      }
    },
    onError: () => {
      setError("Failed to sign up. Please try again.");
    },
  });

  return {
    signUp: async (values: SignUpFormSchema) => {
      await signUpMutation.mutateAsync(values);
    },

    isLoading: signUpMutation.isPending,
  };
}
