import { toast } from "sonner";
import { Route } from "../../..";
import { MacroAction } from "@be/modules/macros/macros.types";

export function useSendNote() {
  const { trpc, user } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const trpcUtils = trpc.useUtils();

  const sendNoteMutation = trpc.dashboard.conversations.sendNote.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.conversations.get.invalidate({
        organizationSlug,
        publicId,
      });
    },
    onError: (error) => {
      console.error("Error sending note", error);
      toast.error("Error sending note");
    },
  });

  return ({
    text,
    actions,
    fileIds,
  }: {
    text: string;
    actions: MacroAction[];
    fileIds: string[];
  }) =>
    sendNoteMutation.mutate({
      organizationSlug,
      publicId,
      text,
      actions,
      fileIds,
      userId: user.id,
    });
}
