import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { Sidebar } from "@dashboard/common/Sidebar/Sidebar";
import { useState } from "react";
import { SidebarHeader } from "./-components/SidebarHeader";
import { searchParamsSchema } from "./-utils/searchParamSchema";
import { SidebarSearch } from "./-components/SidebarSearch";
import { SidebarViews } from "./-components/SidebarViews";
import { useTabNotificationEffect } from "./-hooks/useTabNotificationEffect";
import { useBanner } from "../../-hooks/useBanner";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar",
)({
  validateSearch: searchParamsSchema,
  loaderDeps: ({ search: { filters, view } }) => ({ filters, view }),
  loader: async ({
    context: { trpcClientUtils, user, hasExternalTicketConfig },
    params: { organizationSlug },
    deps: { filters, view },
  }) => {
    if (!view) {
      view = "your-inbox";

      if (user.isAdmin || hasExternalTicketConfig) {
        view = "bot";
      }

      const search = new URLSearchParams(window.location.search);
      const searchObject = Object.fromEntries(search.entries());

      if (typeof searchObject.filters === "string") {
        searchObject.filters = JSON.parse(searchObject.filters) as string;
      }

      const searchString = JSON.stringify(searchObject);
      const parsedSearch = searchParamsSchema.parse(JSON.parse(searchString));

      throw redirect({
        search: { ...parsedSearch, view },
      });
    }

    const baseViews = [
      "your-inbox",
      "unassigned",
      "handed-off",
      "bot",
      "threads",
    ];

    const customViews = await trpcClientUtils.dashboard.views.getAll.ensureData(
      {
        organizationSlug,
      },
    );

    const users = await trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
      organizationSlug,
    });

    const businessId = filters?.businessId;

    await Promise.all([
      trpcClientUtils.dashboard.conversations.getLanguages.ensureData({
        organizationSlug,
      }),

      ...baseViews.map((view) =>
        trpcClientUtils.dashboard.conversations.getCount.ensureData({
          organizationSlug,
          view,
          filters: { businessId },
          status: "open",
        }),
      ),

      ...customViews.map((view) =>
        trpcClientUtils.dashboard.conversations.getCount.ensureData({
          organizationSlug,
          view: view.id,
        }),
      ),

      ...users.map((user) =>
        trpcClientUtils.dashboard.conversations.getCount.ensureData({
          organizationSlug,
          view: `user-${user.id}`,
          status: "open",
        }),
      ),

      trpcClientUtils.dashboard.conversations.getCount.ensureData({
        organizationSlug,
        filters: { businessId, replyChannel: "web", assignee: null },
      }),
    ]);
  },
  component: ConversationSidebarLayout,
});

function ConversationSidebarLayout() {
  const search = Route.useSearch();

  const [searchActive, setSearchActive] = useState(search.view === "search");

  useTabNotificationEffect();

  const { height: bannerHeight, shouldShow: bannerVisible } = useBanner();

  return (
    <div className="flex h-full w-full">
      <Sidebar
        header={
          <SidebarHeader
            setSearchActive={setSearchActive}
            searchActive={searchActive}
          />
        }
        banner={{ height: bannerHeight, isVisible: bannerVisible }}
      >
        {searchActive ? <SidebarSearch /> : <SidebarViews />}
      </Sidebar>
      <Outlet />
    </div>
  );
}
