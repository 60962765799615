import { AftershipReturn } from "@be/modules/integrations/thirdParty/aftership/aftership.schema";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { ItemDetailSection } from "../Orders/OrderDetailSection";
import { format } from "date-fns";
import { Separator } from "@dashboard/common/ui/separator";

export function ReturnDetails({ returnItem }: { returnItem: AftershipReturn }) {
  return (
    <AccordionItem
      value={returnItem.data.id}
      className={`data-[state=open]:bg-offWhite rounded-xl border-none !shadow-none`}
    >
      <AccordionTrigger className="hover:bg-offWhite flex justify-between rounded-xl px-3 py-2">
        <div className="flex w-full items-center justify-between gap-4">
          <span className="text-xs font-medium">
            {returnItem.data.rma_number}
          </span>
          <span className="ml-auto rounded-full bg-gray-200 px-3 py-0.5 text-[10px] font-semibold text-gray-800">
            {returnItem.data.approval_status}
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent className="px-3 pb-1 pt-1 text-xs">
        <div className="data-[state=open]:border-gray-light border-gray-light border-b pb-2">
          <ItemDetailSection
            details={[
              {
                label: "Filed at:",
                value: format(returnItem.data.created_at, "MMM d, yyyy HH:mm"),
              },
              {
                label: "Filed by:",
                value: returnItem.data.filed_by,
              },
              {
                label: "Approved at:",
                value: returnItem.data.approved_at
                  ? format(returnItem.data.approved_at, "MMM d, yyyy HH:mm")
                  : "N/A",
              },
              {
                label: "Auto approved:",
                value: returnItem.data.auto_approved ? "Yes" : "No",
              },
              ...(returnItem.data.rejected_at
                ? [
                    {
                      label: "Rejected at:",
                      value: format(
                        returnItem.data.rejected_at,
                        "MMM d, yyyy HH:mm",
                      ),
                    },
                  ]
                : []),
              ...(returnItem.data.auto_rejected
                ? [
                    {
                      label: "Auto rejected:",
                      value: "Yes",
                    },
                  ]
                : []),
              ...(returnItem.data.resolved_at
                ? [
                    {
                      label: "Resolved at:",
                      value: format(
                        returnItem.data.resolved_at,
                        "MMM d, yyyy HH:mm",
                      ),
                    },
                  ]
                : []),
              ...(returnItem.data.auto_resolved
                ? [
                    {
                      label: "Auto resolved:",
                      value: "Yes",
                    },
                  ]
                : []),
              {
                label: "Refunded at:",
                value: returnItem.data.refunded_at
                  ? format(returnItem.data.refunded_at, "MMM d, yyyy HH:mm")
                  : "N/A",
              },
              {
                label: "Auto refunded:",
                value: returnItem.data.auto_refunded ? "Yes" : "No",
              },
              ...(returnItem.data.auto_received
                ? [
                    {
                      label: "Auto received:",
                      value: "Yes",
                    },
                  ]
                : []),
              {
                label: "Expired at:",
                value: returnItem.data.expired_at
                  ? format(returnItem.data.expired_at, "MMM d, yyyy HH:mm")
                  : "N/A",
              },
              ...(returnItem.data.reject_reason
                ? [
                    {
                      label: "Reject reason:",
                      value: returnItem.data.reject_reason,
                    },
                  ]
                : []),
              ...(returnItem.data.merchant_note
                ? [
                    {
                      label: "Merchant note:",
                      value: returnItem.data.merchant_note,
                    },
                  ]
                : []),

              {
                label: "Return method:",
                value: returnItem.data.return_method.name,
              },
              {
                label: "Return method zone:",
                value: returnItem.data.return_method.zone.name,
              },
              {
                label: "Cost of return:",
                value: returnItem.data.cost_of_return?.value
                  ? `${returnItem.data.cost_of_return.value.amount} ${returnItem.data.cost_of_return.value.currency}`
                  : "N/A",
              },
              {
                label: "Cost of return charged:",
                value: returnItem.data.cost_of_return?.charged
                  ? `${returnItem.data.cost_of_return.charged.amount} ${returnItem.data.cost_of_return.charged.currency}`
                  : "N/A",
              },
              {
                label: "Estimated refund total:",
                value: returnItem.data.estimated_refund_total
                  ? `${returnItem.data.estimated_refund_total.amount} ${returnItem.data.estimated_refund_total.currency}`
                  : "N/A",
              },
            ]}
          />
        </div>
        <Accordion type="multiple" className="w-full" defaultValue={["order"]}>
          <AccordionItem
            className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
            value="order"
          >
            <AccordionTrigger className="py-2 text-xs font-medium">
              Order
            </AccordionTrigger>
            <AccordionContent className="pb-2 text-xs">
              <ItemDetailSection
                details={[
                  {
                    label: "External ID:",
                    value: returnItem.data.order.external_id,
                  },
                  {
                    label: "Order number:",
                    value: returnItem.data.order.order_number,
                  },
                  {
                    label: "Order name:",
                    value: returnItem.data.order.order_name,
                  },
                  {
                    label: "Customer:",
                    value: returnItem.data.order.customer.emails.join(", "),
                  },
                  {
                    label: "Country:",
                    value: returnItem.data.order.country,
                  },
                  {
                    label: "Store platform:",
                    value: returnItem.data.order.store.platform,
                  },
                  {
                    label: "Store external ID:",
                    value: returnItem.data.order.store.external_id,
                  },
                  {
                    label: "Placed at:",
                    value: returnItem.data.order.placed_at
                      ? format(
                          returnItem.data.order.placed_at,
                          "MMM d, yyyy HH:mm",
                        )
                      : "N/A",
                  },
                ]}
              />
            </AccordionContent>
          </AccordionItem>
          {returnItem.data.gift_return && (
            <AccordionItem
              value="giftReturn"
              className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Gift return
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                <ItemDetailSection
                  details={[
                    {
                      label: "Recipient name:",
                      value: returnItem.data.gift_return.recipient.name,
                    },
                    {
                      label: "Recipient email:",
                      value: returnItem.data.gift_return.recipient.email,
                    },
                    {
                      label: "Recipient address:",
                      value: [
                        returnItem.data.gift_return.recipient.shipping_address
                          .street_1,
                        returnItem.data.gift_return.recipient.shipping_address
                          .street_2,
                        [
                          returnItem.data.gift_return.recipient.shipping_address
                            .city,
                          returnItem.data.gift_return.recipient.shipping_address
                            .state?.name,
                          returnItem.data.gift_return.recipient.shipping_address
                            .postal_code,
                        ]
                          .filter(Boolean)
                          .join(", "),
                        returnItem.data.gift_return.recipient.shipping_address
                          .country_region?.code,
                      ]
                        .filter(Boolean)
                        .join("\n"),
                    },
                  ]}
                />
              </AccordionContent>
            </AccordionItem>
          )}
          <AccordionItem
            value="items"
            className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
          >
            <AccordionTrigger className="py-2 text-xs font-medium">
              Items
            </AccordionTrigger>
            <AccordionContent className="pb-2 text-xs">
              <Accordion
                type="multiple"
                className="max-h-160 scrollbar-hide flex flex-col gap-1 overflow-hidden pb-0"
                defaultValue={["itemDetails"]}
              >
                {returnItem.data.return_items.map((item, index) => (
                  <div key={item.id}>
                    <AccordionItem
                      value={item.id}
                      className="mt-0 border-none pt-0 !shadow-none"
                    >
                      <AccordionTrigger className="py-2 text-xs font-medium">
                        {item.sku}
                      </AccordionTrigger>
                      <AccordionContent className="pb-2 text-xs">
                        <ItemDetailSection
                          details={[
                            {
                              label: "Product title:",
                              value: item.product_title,
                            },
                            {
                              label: "External Order Item ID:",
                              value: item.external_order_item_id,
                            },
                            {
                              label: "Variant title:",
                              value: item.variant_title,
                            },
                            {
                              label: "Return reason:",
                              value: item.return_reason,
                            },
                            {
                              label: "Return quantity:",
                              value: item.return_quantity,
                            },
                            {
                              label: "Received quantity:",
                              value: item.received_quantity,
                            },
                            {
                              label: "Restocked quantity:",
                              value: item.restocked_quantity,
                            },
                            {
                              label: "Ordered quantity:",
                              value: item.ordered_quantity,
                            },
                            {
                              label: "Refund quantity:",
                              value: item.refund_quantity,
                            },
                            {
                              label: "Unit display price:",
                              value: item.unit_display_price
                                ? `${item.unit_display_price.amount} ${item.unit_display_price.currency}`
                                : "N/A",
                            },
                            {
                              label: "Unit discounted price including tax:",
                              value: item.unit_discounted_price_including_tax
                                ? `${item.unit_discounted_price_including_tax.amount} ${item.unit_discounted_price_including_tax.currency}`
                                : "N/A",
                            },
                            {
                              label: "Unit tax:",
                              value: item.unit_tax
                                ? `${item.unit_tax.amount} ${item.unit_tax.currency}`
                                : "N/A",
                            },
                            {
                              label: "Unit discount:",
                              value: item.unit_discount
                                ? `${item.unit_discount.amount} ${item.unit_discount.currency}`
                                : "N/A",
                            },
                          ]}
                        />
                      </AccordionContent>
                    </AccordionItem>
                    {index !== returnItem.data.return_items.length - 1 && (
                      <Separator className="bg-offWhite mx-3 w-auto" />
                    )}
                  </div>
                ))}
              </Accordion>
            </AccordionContent>
          </AccordionItem>
          {returnItem.data.refunds.length > 0 && (
            <AccordionItem
              value="refunds"
              className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Refunds
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                <Accordion
                  type="multiple"
                  className="max-h-160 scrollbar-hide flex flex-col gap-1 overflow-hidden pb-0"
                >
                  {returnItem.data.refunds.map((refund, index) => {
                    return (
                      <div key={refund.external_id}>
                        <AccordionItem
                          value={refund.external_id}
                          className="mt-0 border-none pt-0 !shadow-none"
                        >
                          <AccordionTrigger className="py-2 text-xs font-medium">
                            {refund.destination
                              ? refund.destination
                                  .replace(/_/g, " ")
                                  .replace(/^\w/, (c) => c.toUpperCase())
                              : "N/A"}
                          </AccordionTrigger>
                          <AccordionContent className="pb-2 text-xs">
                            <ItemDetailSection
                              details={[
                                {
                                  label: "External ID:",
                                  value: refund.external_id,
                                },
                                ...refund.items.map((item) => ({
                                  label: "External Order Item ID:",
                                  value: item.external_order_item_id,
                                })),
                              ]}
                            />
                          </AccordionContent>
                        </AccordionItem>
                        {index !== returnItem.data.refunds.length - 1 && (
                          <Separator className="bg-offWhite mx-3 w-auto" />
                        )}
                      </div>
                    );
                  })}
                </Accordion>
              </AccordionContent>
            </AccordionItem>
          )}
          {returnItem.data.shipments.length > 0 && (
            <AccordionItem
              value="shipments"
              className="my-1 border-none !shadow-none"
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Shipments
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                <Accordion
                  type="multiple"
                  className="max-h-160 scrollbar-hide flex flex-col gap-1 overflow-hidden pb-0"
                >
                  {returnItem.data.shipments.map((shipment) => (
                    <AccordionItem
                      key={shipment.id}
                      value={shipment.id}
                      className="mt-0 border-none pt-0 !shadow-none"
                    >
                      <AccordionTrigger className="py-2 text-xs font-medium">
                        {shipment.tracking_number}
                      </AccordionTrigger>
                      <AccordionContent className="pb-2 text-xs">
                        <ItemDetailSection
                          details={[
                            {
                              label: "Service provider carrier code:",
                              value: shipment.slug,
                            },
                            ...shipment.items.map((item) => ({
                              label: "External Order Item ID:",
                              value: item.external_order_item_id,
                            })),
                            {
                              label: "Tracking status:",
                              value: shipment.tracking_status ?? "N/A",
                            },
                            ...(shipment.label?.url
                              ? [
                                  {
                                    label: "Shipping label URL:",
                                    value: (
                                      <a
                                        href={shipment.label.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500"
                                      >
                                        URL
                                      </a>
                                    ),
                                  },
                                ]
                              : []),
                            ...(shipment.packing_slip_url
                              ? [
                                  {
                                    label: "Packing slip URL:",
                                    value: (
                                      <a
                                        href={shipment.packing_slip_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500"
                                      >
                                        URL
                                      </a>
                                    ),
                                  },
                                ]
                              : []),
                            ...(shipment.shipping_documents_url
                              ? [
                                  {
                                    label: "Shipping documents URL:",
                                    value: (
                                      <a
                                        href={shipment.shipping_documents_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500"
                                      >
                                        URL
                                      </a>
                                    ),
                                  },
                                ]
                              : []),
                            ...(shipment.shipping_documents_components?.map(
                              (component) => ({
                                label: "Shipping documents component:",
                                value: component,
                              }),
                            ) ?? []),
                            ...(shipment.label?.total_charge
                              ? [
                                  {
                                    label: "Total charge:",
                                    value: `${shipment.label.total_charge.amount} ${shipment.label.total_charge.currency}`,
                                  },
                                ]
                              : []),
                          ]}
                        />
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              </AccordionContent>
            </AccordionItem>
          )}
        </Accordion>
      </AccordionContent>
    </AccordionItem>
  );
}
