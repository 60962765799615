import { useDraftMessages } from "../../../../-context/DraftMessagesContext";
import { ClipboardEvent, DragEvent, KeyboardEvent, useState } from "react";
import { DashboardConversation } from "./utils/input.types";
import { useFileUpload } from "../../../-hooks/useFileUpload";
import { MessageInputContainer } from "./components/MessageInputContainer";
import { MessageIcon } from "./components/MessageIcon";
import { MessageHeader } from "./components/MessageHeader";
import { EmailMetadata } from "./components/EmailMetadata";
import { MessageTextArea } from "./components/MessageTextArea";
import { useCreateExternalConversation } from "../../-hooks/useCreateExternalConversation";
import { useRefineExternalMessage } from "../../-hooks/useRefineExternalMessage";
import { ActionList } from "./components/ActionList";
import { MessageToolbar } from "./components/MessageToolbar";
import { AttachmentList } from "./components/AttachmentList";
import { createNewExternalConversation } from "./utils/input.createNewExternalConversation";
import { MacroAction } from "@be/modules/macros/macros.types";
import { useTranslateText } from "../../../-hooks/useTranslateText";

interface NewThreadMessageInputProps {
  conversation: DashboardConversation;
}

export function NewThreadMessageInput({
  conversation,
}: NewThreadMessageInputProps) {
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [isRefining, setIsRefining] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const { getDraft, updateNewThreadDraft, clearNewThreadDraft } =
    useDraftMessages();

  let publicId = conversation.publicId;
  if (conversation.type === "external") {
    publicId = conversation.customerConversationPublicId;
  }

  const draft = getDraft(publicId);

  const { uploadFiles } = useFileUpload();

  const sendMessage = useCreateExternalConversation();

  const onClickSendMessage = ({ action }: { action?: MacroAction }) => {
    createNewExternalConversation({
      action,
      draft,
      publicId,
      isRefining,
      sendMessage,
      clearNewThreadDraft,
    });
  };

  // Translation utils
  const { translate } = useTranslateText({
    onData: (data) => {
      updateNewThreadDraft(publicId, {
        text: data,
        originalText: draft.newThread.text,
      });
    },
  });

  const handleTranslate = (targetLang: string) => {
    translate({ text: draft.newThread.text, targetLang });
  };
  // End of Translation utils

  // Dragging utils
  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragging(true);
    } else if (e.type === "dragleave" || e.type === "drop") {
      setIsDragging(false);
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length === 0) return;

    const uploadedFiles = await uploadFiles({
      files: droppedFiles,
      businessId: conversation.businessId,
    });

    updateNewThreadDraft(publicId, {
      attachments: [
        ...draft.newThread.attachments,
        ...uploadedFiles.map((file) => ({
          fileId: file.fileId,
          filename: file.filename,
        })),
      ],
    });
  };
  // End of Dragging utils

  // Key / Paste utils
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const isModifierKeyPressed = e.metaKey || e.ctrlKey;
    if (e.key === "Enter" && isModifierKeyPressed) {
      onClickSendMessage({});

      e.preventDefault();
    }
  };

  const handlePaste = async (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const files = Array.from(e.clipboardData.items)
      .filter((item) => item.kind === "file")
      .map((item) => item.getAsFile())
      .filter((file): file is File => file !== null);

    if (files.length === 0) return;

    e.preventDefault();

    const uploadedFiles = await uploadFiles({
      files,
      businessId: conversation.businessId,
    });

    updateNewThreadDraft(publicId, {
      attachments: [
        ...draft.newThread.attachments,
        ...uploadedFiles.map((file) => ({
          fileId: file.fileId,
          filename: file.filename,
        })),
      ],
    });
  };
  // End of Key / Paste utils

  // Refining utils
  const refineMessage = useRefineExternalMessage();

  const onClickRefineMessage = () => {
    if (isRefining) return;

    if (!draft.current.text) return;

    setIsRefining(true);

    refineMessage({
      currentMessage: draft.newThread.text,
      onSuccess: (refinedMessage) => {
        updateNewThreadDraft(publicId, { text: refinedMessage });
        setIsRefining(false);
      },
      onError: () => {
        setIsRefining(false);
      },
    });
  };
  // End of Refining utils

  return (
    <MessageInputContainer
      isFocused={inputIsFocused}
      isDragging={isDragging}
      handleDrag={handleDrag}
      handleDrop={(e) => void handleDrop(e)}
    >
      <MessageHeader icon={<MessageIcon />} title="New Email Thread" />

      <EmailMetadata
        from={conversation.emailIntegration?.address ?? ""}
        to={{
          value: draft.newThread.email,
          onChange: (email) => updateNewThreadDraft(publicId, { email }),
        }}
        subject={{
          value: draft.newThread.subject,
          onChange: (subject) => updateNewThreadDraft(publicId, { subject }),
        }}
        isEditable={true}
      />

      <MessageTextArea
        value={draft.newThread.text}
        onChange={(text) => updateNewThreadDraft(publicId, { text })}
        onPaste={(e) => void handlePaste(e)}
        onKeyDown={handleKeyDown}
        onFocus={() => setInputIsFocused(true)}
        onBlur={() => setInputIsFocused(false)}
        disabled={isRefining}
        minRows={4}
      />

      <ActionList
        actions={draft.newThread.actions}
        onRemove={(index) =>
          updateNewThreadDraft(publicId, {
            actions: draft.newThread.actions.filter((_, i) => i !== index),
          })
        }
      />

      <AttachmentList
        attachments={draft.newThread.attachments}
        onRemove={(file) =>
          updateNewThreadDraft(publicId, {
            attachments: draft.newThread.attachments.filter(
              (f) => f.fileId !== file.fileId,
            ),
          })
        }
      />

      <MessageToolbar
        setInput={(input) => updateNewThreadDraft(publicId, { text: input })}
        setActions={(actions) => updateNewThreadDraft(publicId, { actions })}
        setFiles={(files) =>
          updateNewThreadDraft(publicId, { attachments: files })
        }
        onAttachmentUpload={(file) => {
          updateNewThreadDraft(publicId, {
            attachments: [
              ...draft.newThread.attachments,
              {
                fileId: file.fileId,
                filename: file.filename,
              },
            ],
          });
        }}
        isTranslated={!!draft.newThread.originalText}
        showTranslate={false}
        newThread={true}
        onTranslateNewThread={handleTranslate}
        onUndo={() => {
          updateNewThreadDraft(publicId, {
            text: draft.newThread.originalText,
            originalText: "",
          });
        }}
        onRefine={onClickRefineMessage}
        isRefining={isRefining}
        businessId={conversation.businessId}
        onClickSendMessage={onClickSendMessage}
        sendMessageDisabled={isRefining || !draft.newThread.text}
      />
    </MessageInputContainer>
  );
}
