import { Route } from "..";
import { toast } from "sonner";
import { FlowForm } from "../../-utils/flowFormSchema";

export function useSubmitForm() {
  const navigate = Route.useNavigate();
  const { organizationSlug, businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  async function navigateToFlows() {
    await navigate({
      to: "/organization/$organizationSlug/settings/$businessSlug/advanced-flows",
      params: { organizationSlug, businessSlug },
    });
  }

  const upsertMutation = trpc.dashboard.flows.upsert.useMutation({
    onSuccess: () => {
      void navigateToFlows();
      toast.success("Successfully saved flow.");
    },
    onError: () => {
      toast.error("Failed to save flow. Please try again.");
    },
  });

  return {
    onSubmit: (data: FlowForm) => {
      upsertMutation.mutate({
        businessSlug,
        ...data,
      });
    },

    onError: (errors: unknown) => {
      console.error(errors);
      toast.error("Failed to save flow.");
    },
  };
}
