import { toast } from "sonner";
import { Route } from "..";
import { IntegrationServiceName } from "@be/modules/integrations/integrations.types";
export const useTestIntegration = () => {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const testSmtpImapQuery =
    trpc.dashboard.integrations.testIntegration.useMutation({
      onSuccess: (data: {
        testSuccess: boolean;
        integrationServiceName: string;
        failureReason?: string;
      }) => {
        console.log(data);
        if (data.testSuccess) {
          toast.success(
            `${data.integrationServiceName} integration is working correctly.`,
          );
        } else {
          toast.error(
            `${data.integrationServiceName} integration is not working correctly, ${data.failureReason}`,
          );
        }
      },
      onError: () => {
        toast.error(
          "Something went wrong while attempting to test the integration",
        );
      },
    });

  return {
    testIntegration: (integrationServiceName: IntegrationServiceName) => {
      testSmtpImapQuery.mutate({
        businessSlug,
        integrationServiceName,
      });
    },
  };
};
