import { Button } from "@dashboard/common/ui/button";
import { cn } from "@dashboard/utils/ui";
import { Info, LucideIcon } from "lucide-react";

interface TextItem {
  icon?: LucideIcon;
  text: string;
}

interface FlowOptionProps {
  available: boolean;
  value: string;
  selected: boolean;
  onSelect: (value: string) => void;
  label: string;
  description: TextItem[];
  condition?: string;
  disabled?: {
    isDisabled: boolean;
    message: TextItem;
  };
}

export function FlowOption({
  available,
  value,
  selected,
  onSelect,
  label,
  description,
  condition,
  disabled,
}: FlowOptionProps) {
  const isDisabled = disabled?.isDisabled;
  const disabledMessage = disabled?.message;

  if (!available) {
    return null;
  }

  return (
    <Button
      type="button"
      onClick={() => onSelect(value)}
      variant="ghost"
      disabled={isDisabled}
      className={cn(
        "relative h-auto w-full rounded-lg border px-3 py-2 text-left transition-all hover:bg-gray-50",
        selected
          ? "ring-offset-none ring-blue border-blue bg-blue-50/50 ring-1"
          : "border-gray-200",
      )}
    >
      {isDisabled && (
        <div className="absolute right-3 top-2 rounded-lg bg-red-100 px-3 py-1 text-sm text-red-600">
          <div className="flex items-center gap-1.5">
            {disabledMessage?.icon && (
              <disabledMessage.icon className="h-4 w-4" />
            )}
            <span>{disabledMessage?.text}</span>
          </div>
        </div>
      )}
      <div className="w-full">
        <div className="text-base font-medium">{label}</div>

        {condition && (
          <div className="my-2 flex items-center gap-1.5 text-sm text-gray-500">
            <Info className="h-4 w-4 shrink-0 text-gray-500" />
            <span className="whitespace-normal">{condition}</span>
          </div>
        )}

        <ul className="mt-4 space-y-2 text-wrap text-sm">
          {description.map((item, i) => {
            return (
              <li key={i} className="flex items-center gap-1.5">
                {item.icon && <item.icon className="h-4 w-4 shrink-0" />}
                <span>{item.text}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </Button>
  );
}
