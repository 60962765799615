import { Route } from "..";

export function useMacros({ businessId }: { businessId?: string }) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [macros] = trpc.dashboard.macros.getAvailable.useSuspenseQuery({
    organizationSlug,
  });

  if (businessId) {
    const filteredMacros = macros.filter(
      (macro) => macro.businessId === businessId || !macro.businessId,
    );

    return filteredMacros;
  }

  return macros;
}
