import { z } from "zod";

export const flowFormSchema = z.object({
  id: z.string().optional(),
  title: z
    .string({
      required_error: "Title is required",
      invalid_type_error: "Title is required",
    })
    .min(1, "Title is required"),
  whenToFollow: z
    .string({
      required_error: "When to follow is required",
      invalid_type_error: "When to follow is required",
    })
    .min(1, "When to follow is required"),
  requiredConditions: z.array(z.string()),
  variants: z.array(
    z.object({
      id: z.string().optional(),
      title: z
        .string({
          required_error: "Title is required",
          invalid_type_error: "Title is required",
        })
        .min(1, "Title is required"),
      actionNames: z.array(z.string()),
      associatedThreatIds: z.array(z.string()),
      ignoredThreatIds: z.array(z.string()),
      requiredConditions: z.array(z.string()),
      botInstructions: z
        .string({
          required_error: "Bot instructions are required",
          invalid_type_error: "Bot instructions are required",
        })
        .min(1, "Bot instructions are required"),
      delayResponseInMinutes: z
        .number()
        .or(z.string().transform((s) => (s ? parseInt(s) : undefined)))
        .optional()
        .nullable()
        .transform((n) => n ?? undefined),
      instantHandoff: z.boolean(),
    }),
  ),
  isLive: z.boolean(),
  orderIdRequired: z.boolean(),
  subscriptionIdRequired: z.boolean(),
  priority: z
    .string()
    .regex(/^\d+$/, "Priority must be a number")
    .transform((value) => parseInt(value))
    .or(z.number()),
});

export type FlowForm = z.infer<typeof flowFormSchema>;
