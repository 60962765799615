import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { UserForm, userFormSchema } from "../-utils/userFormSchema";
import { toast } from "sonner";
import { UserFormBody } from "./UserFormBody";
import { useUpdateUser } from "../-hooks/useUpdateUser";

export function EditUserForm({
  id,
  fullName,
  email,
  isManager,
  isAgent,
  setOpen,
}: {
  id: string;
  fullName: string;
  email: string;
  isManager: boolean;
  isAgent: boolean;
  setOpen: (open: boolean) => void;
}) {
  const form = useForm<UserForm>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      fullName,
      email,
      isManager,
      isAgent,
    },
  });

  const { setError } = form;

  const { updateUser } = useUpdateUser({
    setError,
    setOpen,
  });

  const onSubmit = (data: UserForm) => {
    updateUser({ id, ...data });
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to update user");
  };

  return <UserFormBody form={form} onSubmit={onSubmit} onError={onError} />;
}
