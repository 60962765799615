import { z } from "zod";

export const workflowExportSchema = z.object({
  threats: z.array(
    z.object({
      id: z.string(),
      title: z.string().min(1),
      description: z.string().min(1),
      sampleStatements: z.array(z.string().min(1)),
    }),
  ),
  workflows: z.array(
    z.object({
      id: z.string().optional(),
      title: z.string(),
      whenToFollow: z.string(),
      requiredConditions: z.array(z.string()),
      variants: z.array(
        z.object({
          id: z.string().optional(),
          title: z.string(),
          associatedThreatIds: z.array(z.string()),
          ignoredThreatIds: z.array(z.string()),
          actionNames: z.array(z.string()),
          requiredConditions: z.array(z.string()),
          botInstructions: z.string(),
          instantHandoff: z.boolean(),
        }),
      ),
      isLive: z.boolean(),
      orderIdRequired: z.boolean(),
      subscriptionIdRequired: z.boolean(),
      priority: z.number(),
    }),
  ),
});
