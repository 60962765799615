import { Route } from "..";
import { toast } from "sonner";

import { TemplateForm } from "../-utils/templateFormSchema";

export function useEditTemplate({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.flowTemplates.update.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.flowTemplates.getAll.invalidate({
        businessSlug,
      });

      setOpen(false);
      toast.success("Template updated");
    },
    onError: () => {
      toast.error("Failed to update template");
    },
  });

  return {
    onSubmit: ({ id, name, content }: TemplateForm & { id: string }) => {
      updateMutation.mutate({ businessSlug, id, name, content });
    },
  };
}
