import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { MessageAvatar } from "./Message/MessageAvatar";
import { format, formatDistanceToNow } from "date-fns";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { useMemo } from "react";

export function Note({
  text,
  createdAt,
  agentName,
  files,
}: {
  text: string;
  createdAt: string;
  agentName: string;
  files: {
    url: string;
    name: string;
    contentType: string;
  }[];
}) {
  const filesMd = useMemo(() => {
    return files
      .map((file) => {
        if (file.contentType.startsWith("image")) {
          return `![${file.name}](${file.url})`;
        } else {
          return `[${file.name}](${file.url})`;
        }
      })
      .join("\n\n");
  }, [files]);

  const content = `${text}\n\n${filesMd}`;

  return (
    <div className="flex flex-row-reverse gap-x-2 p-2">
      <div className="flex flex-col-reverse pb-2">
        <MessageAvatar sender="agent" agentName={agentName} />
      </div>

      <div className="max-w-md rounded-lg bg-[#FFF0BF] px-4 pb-2 pt-3 hover:bg-[#FFE0A8]">
        <MarkdownContent content={content} className="break-words text-sm" />
        <div className="mt-1 flex flex-row items-center gap-1">
          <span className="text-gray text-[13px]">
            <Tooltip
              trigger={<div>{formatDistanceToNowShort(createdAt)}</div>}
              content={format(new Date(createdAt), "PPp")}
              side="bottom"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

function formatDistanceToNowShort(date: string) {
  return formatDistanceToNow(new Date(date))
    .replace(/ years?/, "y")
    .replace(/ months?/, "mon")
    .replace(/ weeks?/, "w")
    .replace(/ days?/, "d")
    .replace(/ hours?/, "h")
    .replace(/ minutes?/, "m")
    .replace("about ", "")
    .replace("less than am", "now");
}
