import { Button } from "@dashboard/common/ui/button";
import { Link } from "@tanstack/react-router";
import { Check } from "lucide-react";
import { useCompleteOnboardingStep } from "../../-hooks/useCompleteOnboardingStep";

interface StyleBotProps {
  organizationSlug: string;
  businessSlug: string;
  completed: boolean;
}

export function StyleBot({
  businessSlug,
  organizationSlug,
  completed,
}: StyleBotProps) {
  const completeStep = useCompleteOnboardingStep();

  return (
    <div className="space-y-6">
      <p>
        Choose from modern color themes or match your brand colors, customize
        chat bubble style and position, and add your logo and welcome message.
      </p>

      <div className="flex flex-col gap-4">
        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/web-chat"
          params={{
            organizationSlug,
            businessSlug,
          }}
          className="w-full"
        >
          <Button className="w-full">Style Your Chatbot</Button>
        </Link>

        {!completed ? (
          <Button
            onClick={() => completeStep({ step: "style" })}
            variant="secondary"
            className="w-full"
          >
            Complete
          </Button>
        ) : (
          <Button className="w-full">
            <Check className="mr-2 h-4 w-4" />
            Completed
          </Button>
        )}
      </div>
    </div>
  );
}
