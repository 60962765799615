import { createFileRoute } from "@tanstack/react-router";
import { Card, CardTitle, CardContent } from "@dashboard/common/ui/card";

export const Route = createFileRoute(
  "/public-routes/rate-conversation/success",
)({
  component: () => {
    return (
      <div className="flex h-full min-h-screen flex-col overscroll-none">
        <div className="mx-auto flex w-full flex-grow flex-col items-center justify-center px-4 pt-10 sm:px-20">
          <Card className="w-full max-w-md text-center">
            <CardContent className="pt-6">
              <div className="mb-4 inline-block rounded-full bg-green-100 p-3">
                <svg
                  className="h-8 w-8 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <CardTitle className="mb-2 text-2xl font-semibold">
                Thank you for your feedback!
              </CardTitle>
              <p className="text-gray-600">
                Your response helps us improve our service.
              </p>
            </CardContent>
          </Card>
        </div>
        <footer className="p-4 text-center text-xs text-gray-500 sm:p-6">
          Powered by{" "}
          <a
            href="https://www.octocom.ai"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium hover:underline"
          >
            Octocom
          </a>
        </footer>
      </div>
    );
  },
});
