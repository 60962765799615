import { Route } from "..";
import { brazeFormSchema, BrazeForm } from "../-utils/brazeFormSchema";
import { dixaFormSchema, DixaForm } from "../-utils/dixaFormSchema";
import { emailFormSchema, EmailForm } from "../-utils/emailFormSchema";
import { hubspotFormSchema, HubspotForm } from "../-utils/hubspotFormSchema";
import { klaviyoFormSchema, KlaviyoForm } from "../-utils/klaviyoFormSchema";
import {
  richpanelFormSchema,
  RichpanelForm,
} from "../-utils/richpanelFormSchema";
import {
  ShopifyIntegration,
  shopifyIntegrationSchema,
} from "../-utils/shopifyIntegrationSchema";
import { telegramFormSchema, TelegramForm } from "../-utils/telegramFormSchema";

const schemas = {
  braze: brazeFormSchema,
  dixa: dixaFormSchema,
  smtpImap: emailFormSchema,
  hubspot: hubspotFormSchema,
  klaviyo: klaviyoFormSchema,
  richpanel: richpanelFormSchema,
  telegram: telegramFormSchema,
  shopify: shopifyIntegrationSchema,
};

interface SchemaTypes {
  braze: BrazeForm;
  dixa: DixaForm;
  smtpImap: EmailForm;
  hubspot: HubspotForm;
  klaviyo: KlaviyoForm;
  richpanel: RichpanelForm;
  telegram: TelegramForm;
  shopify: ShopifyIntegration;
}

export function useIntegration<T extends keyof SchemaTypes>({
  integrationServiceName,
}: {
  integrationServiceName: T;
}): SchemaTypes[T] | null {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [integration] =
    trpc.dashboard.integrations.getIntegration.useSuspenseQuery({
      integrationServiceName,
      businessSlug,
    });

  if (!integration) {
    return null;
  }

  const schema = schemas[integrationServiceName];

  const parsedIntegration = schema.parse(integration);

  return parsedIntegration as SchemaTypes[T];
}
