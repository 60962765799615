import { Select } from "@dashboard/common/ui/select";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { cn } from "@dashboard/utils/ui";
import { X } from "lucide-react";
import { ElementType, useEffect, useState } from "react";
import { Route } from "../../_conversationSidebar";
import { FilterKeys } from "@be/modules/dashboard/conversations/conversations.types";

export function SelectFilter({
  options,
  valueIcon,
  name,
  filterKey,
}: {
  options: { label: string; value: string }[];
  valueIcon?: ElementType;
  name: string;
  filterKey: Exclude<FilterKeys, "timeRange">;
}) {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();
  const { filters } = search;

  const [value, setValue] = useState(filters ? filters[filterKey] ?? "" : "");

  const selectedClasses = "text-blue bg-blue-light border border-blue";

  const setSearch = (value: string | undefined) => {
    setValue(value ?? "");

    const updatedFilters = { ...search.filters, [filterKey]: value };

    if (
      Object.values<unknown>(updatedFilters).every(
        (value) => value === undefined || value === null,
      )
    ) {
      void navigate({
        search: { ...search, filters: undefined },
      });
    } else {
      void navigate({
        search: { ...search, filters: updatedFilters },
      });
    }
  };

  useEffect(() => {
    setValue(filters ? filters[filterKey] ?? "" : "");
  }, [filters, filterKey]);

  return (
    <div className="group relative flex w-full items-center justify-between gap-2">
      {value !== "" && (
        <>
          <div className="group-hover:bg-accent absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm" />
          <Tooltip
            triggerAsChild
            content="Remove filter"
            side="top"
            trigger={
              <X
                className="group-hover:bg-accent group-hover:text-blue absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors"
                onClick={() => setSearch(undefined)}
              />
            }
          />
        </>
      )}
      <Select
        value={value}
        valueIcon={valueIcon}
        variant="no-icon"
        className={cn(
          "group-hover:text-blue group-hover:bg-blue-light bg-gray-light h-auto w-full justify-start gap-2 rounded-lg border border-transparent text-sm font-normal",
          value !== "" && selectedClasses,
        )}
        defaultValue={""}
        placeholder={name}
        onChange={(value) => setSearch(value)}
        options={options}
      />
    </div>
  );
}
