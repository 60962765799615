import { ChannelType } from "@db";

type DetermineReplyChannelResult =
  | {
      showInput: false;
      customInput: null;
      replyChannel: null;
    }
  | {
      showInput: true;
      customInput: null | "setup-email-integration";
      replyChannel: ChannelType;
    };

interface DetermineReplyChannelParams {
  activeTab: "external" | "customer";
  hasExternalTicketConfig: boolean;
  isClosed: boolean;
  isHandedOff: boolean;
  replyChannel: ChannelType | null;
  latestChannel: ChannelType;
  enableLiveChat: boolean;
  customerEmailAvailable: boolean;
  emailIntegrationAvailable: boolean;
}

export function determineReplyChannel({
  activeTab,
  hasExternalTicketConfig,
  isClosed,
  isHandedOff,
  replyChannel,
  latestChannel,
  enableLiveChat,
  customerEmailAvailable,
  emailIntegrationAvailable,
}: DetermineReplyChannelParams): DetermineReplyChannelResult {
  console.log("Determining reply channel", {
    activeTab,
    hasExternalTicketConfig,
    isClosed,
    isHandedOff,
    replyChannel,
    latestChannel,
    enableLiveChat,
    customerEmailAvailable,
    emailIntegrationAvailable,
  });

  if (isClosed || !isHandedOff || hasExternalTicketConfig) {
    return { showInput: false, customInput: null, replyChannel: null };
  }

  function validateChannel(
    channel: ChannelType | null,
  ): DetermineReplyChannelResult {
    let result: DetermineReplyChannelResult = {
      showInput: false,
      customInput: null,
      replyChannel: null,
    };

    switch (channel) {
      case "web":
        if (enableLiveChat) {
          result = { showInput: true, customInput: null, replyChannel: "web" };
        } else {
          result = validateChannel("email");
        }
        break;
      case "email":
        if (customerEmailAvailable) {
          if (emailIntegrationAvailable) {
            result = {
              showInput: true,
              customInput: null,
              replyChannel: "email",
            };
          } else {
            result = {
              showInput: true,
              customInput: "setup-email-integration",
              replyChannel: "email",
            };
          }
        }
        break;
      case "messenger": {
        result = {
          showInput: true,
          customInput: null,
          replyChannel: "messenger",
        };
        break;
      }
      case "instagram": {
        result = {
          showInput: true,
          customInput: null,
          replyChannel: "instagram",
        };
        break;
      }
      case "whatsapp": {
        result = {
          showInput: true,
          customInput: null,
          replyChannel: "whatsapp",
        };
        break;
      }
      case "zoom-transcript": {
        result = {
          showInput: false,
          customInput: null,
          replyChannel: null,
        };
        break;
      }
      case "facebookComment": {
        result = {
          showInput: true,
          customInput: null,
          replyChannel: "facebookComment",
        };
        break;
      }
      case "instagramComment": {
        result = {
          showInput: true,
          customInput: null,
          replyChannel: "instagramComment",
        };
        break;
      }
      case null: {
        result = validateChannel(latestChannel);
        break;
      }
      default:
        break;
    }

    return result;
  }

  const result = validateChannel(replyChannel);

  console.log("Determine reply channel result", result);

  return result;
}
