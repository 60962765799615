import { Dialog, DialogContent } from "@dashboard/common/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@dashboard/common/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";

import { DeleteRecordDialogContent } from "./DeleteRecordDialogContent";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { RecordWithId } from "../Table";

interface RecordActionsProps<Record extends RecordWithId> {
  id: string;
  record: Record;
  recordName: { singular: string; plural: string };
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  deleteRecords?: (ids: string[]) => void;
  editRecordDialogContent?: (
    record: Record,
    setOpen: Dispatch<SetStateAction<boolean>>,
  ) => ReactNode;
  contextActions?: { label: string; action: (ids: string[]) => void }[];
  actionLabels?: {
    edit?: string;
    delete?: string;
  };
}

export function RecordActions<Record extends RecordWithId>({
  id,
  record,
  recordName,
  setSelectedIds,
  deleteRecords,
  editRecordDialogContent,
  contextActions,
  actionLabels = { edit: "Edit", delete: "Delete" },
}: RecordActionsProps<Record>) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const enableDelete =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    record.enableDelete === undefined ? true : record.enableDelete;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const enableEdit = record.enableEdit === undefined ? true : record.enableEdit;

  const enabledContextActions = contextActions?.filter(
    (action) => record.enableContextActions?.[action.label] !== false, // enabled by default unless explicitly disabled
  );

  const hasAnyActions =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (enableEdit && editRecordDialogContent) ||
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (enableDelete && deleteRecords) ||
    (enabledContextActions && enabledContextActions.length > 0);

  if (!hasAnyActions) return null;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <DotsVerticalIcon className="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent collisionPadding={10}>
          {enabledContextActions?.map(({ label, action }) => (
            <DropdownMenuItem key={label} onSelect={() => action([id])}>
              {label}
            </DropdownMenuItem>
          ))}
          {enableEdit && editRecordDialogContent && (
            <DropdownMenuItem onSelect={() => setIsEditDialogOpen(true)}>
              {actionLabels.edit}
            </DropdownMenuItem>
          )}
          {enableDelete && deleteRecords && (
            <DropdownMenuItem
              className="text-red-500 hover:!text-red-500"
              onSelect={() => setIsDeleteDialogOpen(true)}
            >
              {actionLabels.delete}
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      {enableDelete && deleteRecords && (
        <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <DeleteRecordDialogContent
            ids={[id]}
            recordName={recordName}
            setSelectedIds={setSelectedIds}
            deleteRecords={() => {
              deleteRecords([id]);
            }}
          />
        </Dialog>
      )}

      {enableEdit && editRecordDialogContent && (
        <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
          <DialogContent className="max-h-[80vh] w-[50vw] max-w-[50vw] overflow-scroll">
            {editRecordDialogContent(record, setIsEditDialogOpen)}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
