import { createFileRoute } from "@tanstack/react-router";
import ConnectContainer from "./-components/ConnectContainer";
import { useEffect } from "react";
import { z } from "zod";

export const Route = createFileRoute("/connect/success")({
  validateSearch: z.object({
    successRedirect: z.string().url().optional(),
  }),
  component: ConnectSuccess,
});

function ConnectSuccess() {
  const { successRedirect } = Route.useSearch();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = successRedirect ?? "/"; //The page must be reloaded to prevent errors here.
    }, 5000);
  }, []);

  return (
    <ConnectContainer>
      <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
        <h1 className="pb-3 text-center text-2xl font-medium leading-8">
          Integration Complete!
        </h1>
        <h2 className="text-center leading-6 text-gray-800">
          You will be redirected back to your dashboard in a few seconds.
          <br />
          If this doesn&apos;t occur automatically{" "}
          <a
            href={successRedirect ?? "/"}
            className="text-blue-600 hover:underline"
          >
            click here
          </a>
          .
        </h2>
      </div>
    </ConnectContainer>
  );
}
