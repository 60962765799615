import { wsTrpcClient } from "@dashboard/utils/wsTrpcClient";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useHandleUpdateEvent } from "./useHandleUpdateEvent";
import { Route } from "../_conversationList";
import { toast } from "sonner";
import { useHandleDeleteEvent } from "./useHandleDeleteEvent";

export function useSubscriptions({
  pageParams,
  setMovedConversation,
}: {
  pageParams: (string | null)[];
  setMovedConversation: Dispatch<SetStateAction<string | null>>;
}) {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const { view, filters, status } = Route.useSearch();

  const [subscriptionToken] =
    trpc.dashboard.conversations.getSubscriptionToken.useSuspenseQuery({
      organizationSlug,
      view,
      filters,
      status,
    });

  const handleDeleteEvent = useHandleDeleteEvent({
    pageParams,
  });

  const handleUpdateEvent = useHandleUpdateEvent({
    setMovedConversation,
    pageParams,
  });

  // Conversation updates
  useEffect(() => {
    const subscription =
      wsTrpcClient.dashboard.conversations.onConversationUpdate.subscribe(
        { token: subscriptionToken },
        {
          onStarted: () => {
            console.log("Conversation subscription started");
          },
          onStopped: () => {
            console.log("Conversation subscription stopped");
          },
          onData: (data) => handleUpdateEvent(data),
          onError: (error) => {
            console.error("Error in subscription", error);
            // TODO: Improve UX
            toast.error("Network error. Please refresh your browser.");
          },
        },
      );

    return () => {
      subscription.unsubscribe();
    };
    // ! Investigate if this is correct
  }, [subscriptionToken]);

  // Conversation deletions
  useEffect(() => {
    const subscription =
      wsTrpcClient.dashboard.conversations.onConversationDeletion.subscribe(
        { token: subscriptionToken },
        {
          onStarted: () => {
            console.log("Conversation deletion subscription started");
          },
          onStopped: () => {
            console.log("Conversation deletion subscription stopped");
          },
          onData: (data) => handleDeleteEvent(data),
          onError: (error) => {
            console.error("Error in subscription", error);
            // TODO: Improve UX
            toast.error("Network error. Please refresh your browser.");
          },
        },
      );

    return () => {
      subscription.unsubscribe();
    };
    // ! Investigate if this is correct
  }, [subscriptionToken]);
}
