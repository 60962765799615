import { ReactNode } from "react";
import logo from "@dashboard/assets/octocom-black.svg";

interface ConnectContainerProps {
  children: ReactNode;
}

function ConnectContainer({ children }: ConnectContainerProps) {
  return (
    <div className="flex h-full min-h-screen flex-col overscroll-none">
      <div className="w-full px-16 pt-10">
        <img alt="Octocom logo" className="w-40" src={logo} />
      </div>
      <div className="mx-auto flex w-full flex-grow flex-col items-center justify-center px-20 pt-10">
        {children}
      </div>
    </div>
  );
}

export default ConnectContainer;
