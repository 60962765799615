import { Button } from "@dashboard/common/ui/button";
import { Link } from "@tanstack/react-router";
import { BookText, Check, FileText, Globe, ScrollText } from "lucide-react";
import { useCompleteOnboardingStep } from "../../-hooks/useCompleteOnboardingStep";

interface AddKnowledgeProps {
  organizationSlug: string;
  businessSlug: string;
  completed: boolean;
}

export function AddKnowledge({
  businessSlug,
  organizationSlug,
  completed,
}: AddKnowledgeProps) {
  const completeStep = useCompleteOnboardingStep();

  const knowledgeSections = [
    {
      icon: <ScrollText className="text-primary h-5 w-5" />,
      title: "Bot Rules",
      description:
        "Define how your bot should behave, handle specific situations, and maintain your brand voice.",
      buttonText: "Configure Rules",
      path: "/bot-rules",
    },
    {
      icon: <BookText className="text-primary h-5 w-5" />,
      title: "Articles",
      description:
        "Add FAQs, general product information, policies, and other written content your customers might need.",
      buttonText: "Manage Articles",
      path: "/articles",
    },
    {
      icon: <Globe className="text-primary h-5 w-5" />,
      title: "Website Content",
      description:
        "Connect your public website URLs to automatically import content and keep your bot updated.",
      buttonText: "Add Websites",
      path: "/websites",
    },
    {
      icon: <FileText className="text-primary h-5 w-5" />,
      title: "Documents",
      description:
        "Upload PDFs, Word documents, and other files to expand your bot's knowledge base.",
      buttonText: "Upload Documents",
      path: "/documents",
    },
  ];

  return (
    <div className="space-y-6">
      <div className="prose prose-sm max-w-none">
        <p className="text-black">
          Enhance your bot&apos;s knowledge by adding information that will be
          valuable to your customers. The more comprehensive your knowledge
          base, the better your bot can assist users with accurate responses.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        {knowledgeSections.map((section, index) => (
          <Link
            key={index}
            to={`/organization/$organizationSlug/settings/$businessSlug/knowledge${section.path}`}
            params={{
              organizationSlug,
              businessSlug,
            }}
            className="border-blue-light hover:bg-blue-light hover:border-blue flex cursor-pointer flex-col items-start justify-start gap-2 rounded-lg border bg-white p-4 transition-all duration-200 ease-in-out hover:shadow-lg"
          >
            <div className="flex w-full items-center gap-2">
              <div className="bg-primary/10 rounded-md p-2">{section.icon}</div>
              <h3 className="text-lg font-medium text-gray-800">
                {section.title}
              </h3>
            </div>
            <p className="text-left text-gray-600">{section.description}</p>
          </Link>
        ))}
      </div>
      <div className="flex flex-col gap-4">
        <Link
          to={`/organization/$organizationSlug/settings/$businessSlug/knowledge`}
          params={{
            organizationSlug,
            businessSlug,
          }}
          className="w-full"
        >
          <Button className="w-full">Go to Knowledge Base Home</Button>
        </Link>
        {!completed ? (
          <Button
            onClick={() => completeStep({ step: "knowledge" })}
            variant="secondary"
            className="w-full"
          >
            Complete
          </Button>
        ) : (
          <Button className="w-full">
            <Check className="mr-2 h-4 w-4" />
            Completed
          </Button>
        )}
      </div>
    </div>
  );
}
