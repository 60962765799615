import { createFileRoute } from "@tanstack/react-router";
import gorgiasLogo from "@dashboard/assets/gorgias.svg";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { env } from "@dashboard/env";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useOrganization } from "../-hooks/useOrganization";
import { CircleHelp } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";

const formSchema = z.object({
  subdomain: z
    .string({
      required_error: "Subdomain is required",
      invalid_type_error: "Subdomain is required",
    })
    .min(1, "Subdomain is required"),
  helpCenterSubdomain: z.string().nullish(),
});

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/help-desk/gorgias",
)({
  component: GorgiasPage,
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.integrations.getIntegration.ensureData({
      integrationServiceName: "gorgias",
      businessSlug: organizationSlug,
    });
  },
});

function GorgiasPage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [integration] =
    trpc.dashboard.integrations.getIntegration.useSuspenseQuery({
      integrationServiceName: "gorgias",
      businessSlug: organizationSlug,
    });

  const parsedIntegration = z
    .object({
      tenant: z.string(),
      helpCenterSubdomain: z.string().nullable(),
    })
    .nullable()
    .parse(integration);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      subdomain: parsedIntegration?.tenant ?? "",
      helpCenterSubdomain: parsedIntegration?.helpCenterSubdomain ?? "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    const url = new URL(
      `${env.DASHBOARD_BASE_API_URL}/api/gorgias/generate-oauth-link`,
    );

    url.searchParams.set("account", values.subdomain);
    if (values.helpCenterSubdomain) {
      url.searchParams.set("helpCenterSubdomain", values.helpCenterSubdomain);
    }

    window.location.href = url.toString();
  };

  const organization = useOrganization();
  const stepCompleted = organization.onboardingStatus !== "HELP-DESK";

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Connect Gorgias
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        When the AI cannot resolve a ticket, it will automatically forward it to
        your Gorgias help desk. We also recommend connecting your Gorgias help
        center to train the AI on your existing knowledge base.
      </h2>
      <div className="w-full max-w-3xl py-8">
        <Form {...form}>
          <form
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="subdomain"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="h-10 border-[#041627]"
                        placeholder="Enter your Gorgias subdomain"
                        {...field}
                      />
                      <HoverCard>
                        <HoverCardTrigger asChild>
                          <CircleHelp
                            className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
                            strokeWidth={1.5}
                          />
                        </HoverCardTrigger>
                        <HoverCardContent className="w-[450px]" side="top">
                          <div className="space-y-4 text-sm leading-relaxed text-gray-800">
                            <h4 className="font-medium">
                              Finding Your Subdomain
                            </h4>
                            <p>
                              Your Gorgias subdomain is a part of your dashboard
                              URL:
                            </p>

                            <div className="relative rounded-lg border border-gray-200 bg-gray-50 p-3">
                              <div className="flex items-center gap-0 font-mono text-gray-600">
                                <span>https://</span>
                                <span className="rounded bg-blue-100 px-2 py-1 font-semibold text-blue-700">
                                  my-company
                                </span>
                                <span>.gorgias.com</span>
                              </div>
                              <div className="mt-3 flex items-center gap-2 text-sm text-gray-600">
                                <span className="font-medium">Subdomain:</span>
                                <code className="rounded bg-blue-100 px-2 py-1 font-mono font-semibold text-blue-700">
                                  my-company
                                </code>
                              </div>
                            </div>

                            <p className="text-xs text-gray-500">
                              Copy the highlighted part from your Gorgias URL
                              and paste it in the input field
                            </p>
                          </div>
                        </HoverCardContent>
                      </HoverCard>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="helpCenterSubdomain"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      {/* @ts-expect-error this works, but Input element needs to be fixed */}
                      <Input
                        className="h-10 border-[#041627]"
                        placeholder="Enter your Gorgias help center subdomain (optional)"
                        {...field}
                      />
                      <HoverCard>
                        <HoverCardTrigger asChild>
                          <CircleHelp
                            className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
                            strokeWidth={1.5}
                          />
                        </HoverCardTrigger>
                        <HoverCardContent className="w-[450px]" side="top">
                          <div className="space-y-4 text-sm leading-relaxed text-gray-800">
                            <h4 className="font-semibold">
                              Why connect your Gorgias help center?
                            </h4>
                            <p>
                              Connect your Gorgias help center to instantly
                              train your AI on all your help articles. It's
                              quick to set up and significantly improves your
                              AI's knowledge.
                            </p>
                            <h4 className="font-semibold">
                              Finding Your Gorgias Help Center Subdomain
                            </h4>
                            <p>
                              To connect your help center, enter your subdomain
                              - this is the unique part of your help center URL:
                            </p>
                            <div className="relative rounded-lg border border-gray-200 bg-gray-50 p-3">
                              <div className="flex items-center gap-0 font-mono text-gray-600">
                                <span>https://</span>
                                <span className="rounded bg-blue-100 px-2 py-1 font-semibold text-blue-700">
                                  my-gorgias-help-center
                                </span>
                                <span>.gorgias.help</span>
                              </div>
                              <div className="mt-3 flex items-center gap-2 text-sm text-gray-600">
                                <span className="font-medium">
                                  Help Center Subdomain:
                                </span>
                                <code className="rounded bg-blue-100 px-2 py-1 font-mono font-semibold text-blue-700">
                                  my-gorgias-help-center
                                </code>
                              </div>
                            </div>
                            <p className="text-xs text-gray-500">
                              Copy the highlighted part from your Gorgias help
                              center URL and paste it in the input field
                            </p>
                          </div>
                        </HoverCardContent>
                      </HoverCard>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant="outline"
              className="h-10 w-full justify-start border-[#041627]"
            >
              <img
                src={gorgiasLogo}
                alt="Gorgias Logo"
                className="mr-4 h-6 w-6"
              />
              Connect Gorgias
            </Button>
          </form>
        </Form>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/help-desk"
          nextPath="/organization/$organizationSlug/onboarding/knowledge"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}
