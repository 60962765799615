import { format, differenceInMinutes, isToday, isYesterday } from "date-fns";

export function LastSeenAt({ lastSeenAt }: { lastSeenAt: string | null }) {
  if (!lastSeenAt) {
    return (
      <div className="flex items-center gap-1">
        <span className="text-sm text-gray-500">Never</span>
      </div>
    );
  }

  const lastSeenDate = new Date(lastSeenAt);
  const minutesSinceLastSeen = differenceInMinutes(new Date(), lastSeenDate);
  const isOnline = minutesSinceLastSeen <= 5;

  if (isOnline) {
    return (
      <div className="flex items-center gap-1">
        <div className="mr-1 h-2 w-2 rounded-full bg-green-500" />
        <span className="text-sm text-gray-500">Online</span>
      </div>
    );
  }

  let displayText = format(lastSeenDate, "MMM dd, yyyy");
  if (isToday(lastSeenDate)) {
    displayText = `Today at ${format(lastSeenDate, "h:mm a")}`;
  } else if (isYesterday(lastSeenDate)) {
    displayText = `Yesterday at ${format(lastSeenDate, "h:mm a")}`;
  }

  return (
    <div className="flex items-center gap-1">
      <span className="text-sm text-gray-500">{displayText}</span>
    </div>
  );
}
