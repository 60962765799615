import { ArrowRight, CalendarRange, Check, X } from "lucide-react";
import {
  subDays,
  startOfMonth,
  startOfWeek,
  startOfDay,
  format,
  subMonths,
} from "date-fns";

import { Button } from "@dashboard/common/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { useState } from "react";
import { Calendar } from "@dashboard/common/ui/calendar";
import { cn } from "@dashboard/utils/ui";
import { DateRange } from "react-day-picker";
import { Route } from "../../_conversationSidebar";
import { Input } from "@dashboard/common/ui/input";
import { Tooltip } from "@dashboard/common/ui/tooltip";

const inputDateFormat = "dd MMM yyyy";

export function TimeRangeFilter() {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();
  const { filters } = search;

  const timeRange = filters?.timeRange;

  const [value, setValue] = useState<string | undefined>(timeRange?.value);
  const [range, setRange] = useState<DateRange | undefined>(
    timeRange?.value === "custom"
      ? {
          from: timeRange.from ? new Date(timeRange.from) : undefined,
          to: timeRange.to ? new Date(timeRange.to) : undefined,
        }
      : undefined,
  );
  const [open, setOpen] = useState(false);
  let content = "Time Range";
  if (value === "custom" && range?.from && range.to) {
    content = `${format(range.from, "dd/MM/yy")} - ${format(
      range.to,
      "dd/MM/yy",
    )}`;
  } else if (value) {
    content =
      timeRanges.find((range) => range.value === value)?.label ??
      "Custom Date Range";
  }

  const selectedClasses = "text-blue bg-blue-light border border-blue";
  const openClasses = "text-blue bg-blue-light";

  const clearFilter = (e: React.MouseEvent) => {
    e.stopPropagation();

    setValue(undefined);
    setRange(undefined);

    const updatedFilters = { ...search.filters, timeRange: undefined };

    void navigate({
      search: { ...search, filters: updatedFilters },
    });
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="group relative flex w-full items-center justify-between gap-2">
          {value && (
            <>
              <div className="group-hover:bg-accent absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm" />
              <Tooltip
                triggerAsChild
                content="Remove filter"
                side="top"
                trigger={
                  <X
                    className="group-hover:bg-accent group-hover:text-blue absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors"
                    onClick={clearFilter}
                  />
                }
              />
            </>
          )}
          <Button
            className={cn(
              "group-hover:text-blue group-hover:bg-blue-light hover:text-blue hover:bg-blue-light bg-gray-light h-auto w-full justify-start gap-2 rounded-lg border border-transparent text-sm font-normal text-black shadow-none",
              range && selectedClasses,
              open && openClasses,
            )}
          >
            <CalendarRange className="h-4 w-4" />
            {content}
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <TimeRangeComponent
          value={value}
          range={range}
          setRange={setRange}
          setValue={setValue}
          setOpen={setOpen}
        />
      </PopoverContent>
    </Popover>
  );
}

function TimeRangeComponent({
  range,
  value,
  setRange,
  setValue,
  setOpen,
}: {
  range: DateRange | undefined;
  value: string | undefined;
  setRange: (range: DateRange | undefined) => void;
  setValue: (value: string | undefined) => void;
  setOpen: (open: boolean) => void;
}) {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  const [from, setFrom] = useState<string | undefined>(
    range?.from ? format(range.from, inputDateFormat) : undefined,
  );
  const [to, setTo] = useState<string | undefined>(
    range?.to ? format(range.to, inputDateFormat) : undefined,
  );

  const [fromError, setFromError] = useState<string | undefined>(undefined);
  const [toError, setToError] = useState<string | undefined>(undefined);

  const handleSelect = (rangeValue: DateRange | undefined) => {
    const isRangeSelected = !!(range?.from && range.to);

    setFromError(undefined);
    setToError(undefined);

    if (isRangeSelected) {
      let newFrom: Date | undefined;

      if (rangeValue?.from && range.from && rangeValue.from < range.from) {
        newFrom = rangeValue.from;
      } else {
        newFrom = rangeValue?.to;
      }

      setFrom(newFrom ? format(newFrom, inputDateFormat) : "");
      setTo("");

      setRange({ from: newFrom, to: undefined });
      setValue(undefined);

      void navigate({
        search: {
          ...search,
          filters: {
            ...search.filters,
            timeRange: undefined,
          },
        },
      });
    } else {
      setRange(rangeValue);
      setFrom(rangeValue?.from ? format(rangeValue.from, inputDateFormat) : "");
      setTo(rangeValue?.to ? format(rangeValue.to, inputDateFormat) : "");

      if (rangeValue?.from && rangeValue.to) {
        setValue("custom");

        void navigate({
          search: {
            ...search,
            filters: {
              ...search.filters,
              timeRange: {
                value: "custom",
                from: rangeValue.from.toISOString(),
                to: rangeValue.to.toISOString(),
              },
            },
          },
        });
      } else {
        setValue(undefined);
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setRange(undefined);
    setValue(undefined);
  };

  const handleApply = () => {
    if (!to) {
      setToError("Please select an end date");
    } else {
      setToError(undefined);
    }

    if (!from) {
      setFromError("Please select a start date");
    } else {
      setFromError(undefined);
    }

    if (range?.from && range.to) {
      setOpen(false);
    }
  };

  return (
    <div className="flex divide-x">
      <div className="flex flex-col items-center justify-center p-4">
        <div className="w-full flex-grow">
          <h3 className="mb-4 font-medium">Relative</h3>
        </div>
        <div className="w-full flex-grow">
          <RelativeTimeRanges
            value={value}
            setRange={setRange}
            setValue={setValue}
            setFrom={setFrom}
            setTo={setTo}
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center p-4">
        <div className="w-full flex-grow">
          <h3 className="mb-4 font-medium">Custom Date Range</h3>
        </div>
        <div className="w-full flex-grow">
          <div className="mb-2 flex items-center justify-between gap-2">
            <div className="flex w-full flex-col">
              <Input
                placeholder="From"
                value={from}
                disabled={true}
                className={cn(
                  "cursor-default disabled:cursor-default",
                  fromError && "border-red-500",
                )}
              />
              {fromError && (
                <p className="mt-1 text-sm text-red-500">{fromError}</p>
              )}
            </div>
            <ArrowRight className="text-gray h-4 w-4 shrink-0" />
            <div className="flex w-full flex-col">
              <Input
                placeholder="To"
                value={to}
                disabled={true}
                className={cn(
                  "cursor-default disabled:cursor-default",
                  toError && "border-red-500",
                )}
              />
              {toError && (
                <p className="mt-1 text-sm text-red-500">{toError}</p>
              )}
            </div>
          </div>
          <Calendar
            selected={range}
            onSelect={handleSelect}
            defaultMonth={subMonths(new Date(), 1)}
            mode="range"
            numberOfMonths={2}
            showOutsideDays={false}
            className="border-gray-light rounded-lg border"
            disabled={{ after: new Date() }}
          />
        </div>
        <div className="mt-4 flex w-full items-center justify-end gap-2">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="default" onClick={handleApply}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

const timeRanges = [
  { label: "Today", value: "today" },
  { label: "This Week", value: "this-week" },
  { label: "This Month", value: "this-month" },
  { label: "Last 2 Days", value: "last-2-days" },
  { label: "Last 7 Days", value: "last-7-days" },
  { label: "Last 14 Days", value: "last-14-days" },
  { label: "Last 30 Days", value: "last-30-days" },
  { label: "Last 90 Days", value: "last-90-days" },
] as const;

function RelativeTimeRanges({
  value,
  setRange,
  setValue,
  setFrom,
  setTo,
}: {
  value: string | undefined;
  setRange: (range: DateRange | undefined) => void;
  setValue: (value: string | undefined) => void;
  setFrom: (from: string | undefined) => void;
  setTo: (to: string | undefined) => void;
}) {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  const handleClick = (value: (typeof timeRanges)[number]["value"]) => {
    let from: Date;

    switch (value) {
      case "today":
        from = startOfDay(new Date());
        break;
      case "this-week":
        from = startOfWeek(new Date());
        break;
      case "this-month":
        from = startOfMonth(new Date());
        break;
      case "last-2-days":
        from = subDays(startOfDay(new Date()), 1);
        break;
      case "last-7-days":
        from = subDays(startOfDay(new Date()), 6);
        break;
      case "last-14-days":
        from = subDays(startOfDay(new Date()), 13);
        break;
      case "last-30-days":
        from = subDays(startOfDay(new Date()), 29);
        break;
      case "last-90-days":
        from = subDays(startOfDay(new Date()), 89);
        break;
    }

    setValue(value);

    setFrom(format(from, inputDateFormat));
    setTo(format(new Date(), inputDateFormat));

    setRange({
      from,
      to: new Date(),
    });

    void navigate({
      search: {
        ...search,
        filters: {
          ...search.filters,
          timeRange: {
            value,
            from: from.toISOString(),
          },
        },
      },
    });
  };

  const currentRange = timeRanges.find((range) => range.value === value);

  return (
    <div className="flex w-40 flex-col gap-2">
      {timeRanges.map((range) => (
        <Button
          key={range.value}
          onClick={() => handleClick(range.value)}
          variant="outline"
          className="flex w-full items-center justify-between pr-2"
        >
          {range.label}
          {currentRange?.value === range.value && <Check className="h-4 w-4" />}
        </Button>
      ))}
    </div>
  );
}
