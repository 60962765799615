import { useUiState } from "@chatbot/hooks/uiState.hooks";
import clsxm from "@chatbot/utils/clsxm";
import { DisclaimerModal } from "./Disclaimer/DisclaimerModal";
import { useConfig } from "@chatbot/hooks/config.hooks";
import { KeyboardEvent } from "react";
import { trpc } from "@chatbot/utils/trpc";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { wsLink, createWSClient } from "@trpc/client/links/wsLink";
import { env } from "@chatbot/env";
import { ConversationProvider } from "./ConversationProvider";

export function ChatWindow({ browserSessionId }: { browserSessionId: string }) {
  const trpcClient = trpc.createClient({
    links: [
      wsLink({
        client: createWSClient({
          url:
            env.CHATBOT_TRPC_WS_URL +
            `?browserSessionId=${browserSessionId}&commitHash=${env.CHATBOT_COMMIT_HASH}`,
          onOpen: () => {
            console.log("WebSocket connection opened");
          },
          onClose: () => {
            console.log("WebSocket connection closed");
          },
        }),
      }),
    ],
  });

  const queryClient = new QueryClient();

  const {
    loadedIntoContainer,
    isMobile,
    windowHeight,
    closeChatWindow,
    displaySettings,
  } = useUiState();

  const config = useConfig();

  const isLhs =
    (isMobile && !!config.mobilePositionLeft) ||
    (!isMobile && !!config.positionLeft);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape" && !displaySettings) {
      closeChatWindow();
    }
  };

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <div
          onKeyDown={handleKeyDown}
          tabIndex={0}
          aria-label="Customer support chat window"
          style={{
            height: loadedIntoContainer
              ? "100%"
              : isMobile
              ? windowHeight
              : "704px",
            maxHeight: isMobile || loadedIntoContainer ? undefined : "80vh",
            width: isMobile || loadedIntoContainer ? "100%" : "400px",
          }}
          className={clsxm(
            "relative flex flex-col overflow-hidden rounded-none bg-white shadow-xl sm:rounded-xl",
            // Mobile is full screen, above the launcher and attention grabbers, or above all the content if loaded into container
            isMobile ? "fixed bottom-0 right-0 z-10" : "",
            loadedIntoContainer
              ? ""
              : `animate-open-chat${isLhs ? "-lhs" : ""}`,
          )}
        >
          <ConversationProvider
            configId={config.id}
            browserSessionId={browserSessionId}
          />

          {config.disclaimerText && <DisclaimerModal />}
        </div>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
