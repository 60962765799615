import { useEffect } from "react";
import { proxy, useSnapshot, snapshot } from "valtio";
import { env } from "@chatbot/env";

export interface Config {
  id: string;
  businessId: string;
  botId: string | null;
  topBarTitle?: string | null;
  topBarImageFileId?: string | null;
  topBarImageFileUrl: string | null;
  topBarBackgroundColor?: string | null;
  topBarTextColor?: string | null;
  topBarIconColor?: string | null;
  enableTopBarBorder: boolean;
  botIconFileId?: string | null;
  botIconFileUrl: string | null;
  botMessageBackgroundColor?: string | null;
  botMessageTextColor?: string | null;
  customerMessageBackgroundColor?: string | null;
  customerMessageTextColor?: string | null;
  disclaimerText?: string | null;
  disclaimerButtonBackgroundColor?: string | null;
  disclaimerButtonTextColor?: string | null;
  enableDisclaimer: boolean;
  positionBottom?: string | null;
  positionRight?: string | null;
  positionLeft?: string | null;
  zIndex: number;
  mobilePositionBottom?: string | null;
  mobilePositionRight?: string | null;
  mobilePositionLeft?: string | null;
  launcherType: "icon" | "icon_text" | "image";
  launcherText?: string | null;
  launcherMobileText?: string | null;
  launcherBackgroundColor?: string | null;
  launcherTextColor?: string | null;
  launcherIconColor?: string | null;
  launcherSize?: string | null;
  launcherIconSize?: string | null;
  launcherIconFileId?: string | null;
  launcherIconFileUrl: string | null;
  launcherImageFileId?: string | null;
  launcherImageFileUrl: string | null;
  enableAttentionGrabbers: boolean;
  enableAttentionGrabberMobile: boolean;
  attentionGrabberMessage?: string | null;
  attentionGrabberSuggestionBubbleBackgroundColor?: string | null;
  attentionGrabberSuggestionBubbleTextColor?: string | null;
  attentionGrabberSuggestionBubbleHoverBackgroundColor?: string | null;
  attentionGrabberSuggestionBubbleHoverTextColor?: string | null;
  attentionGrabberSuggestions: {
    displayText: string;
    message: string; // TODO: Rename to messageText
  }[];
  attentionGrabberDelay?: number | null;
  requestEmailBeforeChat: boolean;
  requireTermsAndConditionsAgreement: boolean;
  enableOctocomBranding: boolean;
  firstMessageSuggestions: {
    id: string;
    displayText: string;
    messageText: string;
  }[];
  greetings: {
    pathRegex: string | null;
    content: string;
  }[];
  languages: {
    pathRegex: string | null;
    language: string;
  }[];
  deployments: {
    hostname: string | null;
    pathRegex: string | null;
    enableDeployment: boolean;
  }[];
  enableLiveChat: boolean;
  liveChatResponseTime: number | null;
  liveChatTimeout: number | null;
  openChatAfter: number | null;
  autoOpenPaths: string[] | null;
  supportHours: unknown;
}

const configStore = proxy({
  config: undefined as Config | undefined,
});

type ConfigStore = ReturnType<typeof snapshot<typeof configStore>>;

export type ReadonlyConfig = NonNullable<ConfigStore["config"]>;
export type SupportHours = ReadonlyConfig["supportHours"];

export function useConfigOptional() {
  const snapshot = useSnapshot(configStore);

  return snapshot.config;
}

export function useConfig() {
  const snapshot = useSnapshot(configStore);

  if (!snapshot.config) {
    throw new Error("Config is undefined");
  }

  return snapshot.config;
}

export function useInitializeConfig({
  presetConfigId,
}: {
  presetConfigId: string | undefined;
}) {
  const { config } = useSnapshot(configStore);

  console.log("presetConfigId", presetConfigId);
  console.log("getting config for host", window.location.href);

  useEffect(() => {
    const url = new URL(`${env.CHATBOT_BASE_API_URL}/web-chat/config`);

    if (presetConfigId) {
      url.searchParams.append("id", presetConfigId);
    } else {
      url.searchParams.append("url", window.location.href);
    }
    void fetch(url.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Commit-Hash": env.CHATBOT_COMMIT_HASH ?? "unknown",
      },
    })
      .then((res) => res.json())
      .then((data: Config) => {
        configStore.config = data;
      });
  }, [presetConfigId]);

  return config;
}
