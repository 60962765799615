import { useRemoveConversation } from "./useRemoveConversation";

export function useHandleDeleteEvent({
  pageParams,
}: {
  pageParams: (string | null)[];
}) {
  const removeConversation = useRemoveConversation({
    pageParams,
  });

  return (data: { conversationId: string }) => {
    removeConversation({ conversationId: data.conversationId });
  };
}
