import { z } from "zod";
import { MacroAction } from "@be/modules/macros/macros.types";
import { DraftState } from "../../../../../-context/DraftMessagesContext";
import { toast } from "sonner";

export const createNewExternalConversation = ({
  action,
  draft,
  publicId,
  isRefining,
  sendMessage,
  clearNewThreadDraft,
}: {
  action?: MacroAction;
  draft: DraftState;
  publicId: string;
  isRefining: boolean;
  sendMessage: (data: {
    text: string;
    emailTo: string[];
    subject: string;
    actions: MacroAction[];
    fileIds: string[];
  }) => void;
  clearNewThreadDraft: (publicId: string) => void;
}) => {
  if (isRefining || !draft.newThread.text) return;

  const email = draft.newThread.email;
  const subject = draft.newThread.subject;

  if (!email || !subject) return;

  const emailTo = email.split(",").map((e) => e.trim());

  if (emailTo.length === 0) return;

  const parsedEmailTo = z.array(z.string().email()).safeParse(emailTo);

  if (!parsedEmailTo.success) {
    toast.error("Invalid email to field");
    console.log(parsedEmailTo.error.errors);
    return;
  }

  // This ensures that we don't send duplicate actions if the action is already in the list
  let allActions = [...draft.newThread.actions];
  if (action) {
    allActions.push(action);
    allActions = allActions.filter(
      (v, i, a) => a.findIndex((t) => t.type === v.type) === i,
    );
  }

  sendMessage({
    text: draft.newThread.text,
    emailTo: parsedEmailTo.data,
    subject,
    actions: allActions,
    fileIds: draft.newThread.attachments.map((f) => f.fileId),
  });

  clearNewThreadDraft(publicId);
};
