import { Switch } from "@dashboard/common/ui/switch";
import { useState, useEffect } from "react";
import { useUpdateAgentStatus } from "../-hooks/useUpdateAgentStatus";

interface AgentStatusProps {
  userId: string;
  isAgent: boolean;
  isDisabled?: boolean;
}

export function AgentStatus({
  userId,
  isAgent,
  isDisabled = false,
}: AgentStatusProps) {
  const [isAgentStatus, setIsAgentStatus] = useState(isAgent);

  const { updateAgentStatus } = useUpdateAgentStatus();

  const handleToggle = (checked: boolean) => {
    updateAgentStatus({ id: userId, isAgent: checked });
    setIsAgentStatus(checked);
  };

  useEffect(() => {
    setIsAgentStatus(isAgent);
  }, [isAgent]);

  return (
    <div className="w-30 flex flex-row items-center gap-2">
      <Switch
        checked={isAgentStatus}
        onCheckedChange={(value) => handleToggle(value)}
        disabled={isDisabled}
      />
      <span>{isAgentStatus ? "Yes" : "No"}</span>
    </div>
  );
}
