import { Tooltip } from "@dashboard/common/ui/tooltip";
import { TooltipContentProps } from "@radix-ui/react-tooltip";
import { format, formatDistanceToNow } from "date-fns";
import { ReactNode, useEffect, useState } from "react";

export function StatusAndTimeTooltip({
  date,
  side,
  statusIcon,
  statusText,
  extraText = "",
}: {
  date: string;
  side?: TooltipContentProps["side"];
  statusIcon?: ReactNode;
  statusText: string | null;
  extraText?: string;
}) {
  const [, setTick] = useState(0);

  // Effect to update the component every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const content = `${statusText ? `${statusText} | ` : ""}${format(
    new Date(date),
    "PPp",
  )} ${extraText}`;

  const trigger = (
    <div className="flex flex-row items-center gap-1">
      {statusIcon}
      {formatDistanceToNowShort(date)}
    </div>
  );

  return <Tooltip trigger={trigger} content={content.trim()} side={side} />;
}

function formatDistanceToNowShort(date: string) {
  return formatDistanceToNow(new Date(date))
    .replace(/ years?/, "y")
    .replace(/ months?/, "mon")
    .replace(/ weeks?/, "w")
    .replace(/ days?/, "d")
    .replace(/ hours?/, "h")
    .replace(/ minutes?/, "m")
    .replace("about ", "")
    .replace("less than am", "now");
}
