import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import { Check, FileText, Globe, Loader2, X, CircleHelp } from "lucide-react";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useSyncArticles } from "../-hooks/useSyncArticles";
import { useState } from "react";
import gorgiasLogo from "@dashboard/assets/gorgias.svg";
import zendeskLogo from "@dashboard/assets/zendesk.svg";
import { Link } from "@tanstack/react-router";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/knowledge/",
)({
  component: KnowledgePage,
  beforeLoad: async ({
    params: { organizationSlug },
    context: { trpcClientUtils },
  }) => {
    await trpcClientUtils.dashboard.externalTickets.getConfiguration.ensureData(
      {
        organizationSlug,
      },
    );
  },
});

function KnowledgePage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const [externalTicketConfig] =
    trpc.dashboard.externalTickets.getConfiguration.useSuspenseQuery({
      organizationSlug,
    });

  const [syncStatus, setSyncStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const { syncArticles } = useSyncArticles({
    onError: () => {
      setSyncStatus("error");
    },
    onSuccess: () => {
      setSyncStatus("loading");
      setTimeout(() => {
        setSyncStatus("success");
      }, 5000);
    },
  });

  const SyncStatus = () => {
    switch (syncStatus) {
      case "loading":
        return <Loader2 className="h-5 w-5 animate-spin text-[#041627]" />;
      case "success":
        return <Check className="h-5 w-5 text-[#041627]" />;
      case "error":
        return <X className="h-5 w-5 text-red-500" />;
      default:
        return null;
    }
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Add knowledge to your AI
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Add links to your FAQ page, return policy, or other helpful content for
        your AI agent to learn from.
      </h2>
      <div className="flex w-full max-w-3xl flex-col gap-4 py-8">
        {(externalTicketConfig?.system === "gorgias" ||
          externalTicketConfig?.system === "zendesk") && (
          <div className="flex flex-col gap-2">
            {externalTicketConfig.system === "gorgias" && (
              <Button
                variant="outline"
                className="h-10 w-full justify-between border-[#041627] font-semibold"
                onClick={() => syncArticles("gorgias")}
              >
                <div className="flex items-center justify-start">
                  <img
                    src={gorgiasLogo}
                    alt="Gorgias Logo"
                    className="mr-4 h-6 w-6"
                  />
                  Sync your Gorgias articles
                </div>
                <SyncStatus />
              </Button>
            )}
            {externalTicketConfig.system === "zendesk" && (
              <Button
                variant="outline"
                className="h-10 w-full justify-between border-[#041627] font-semibold"
                onClick={() => syncArticles("zendesk")}
              >
                <div className="flex items-center justify-start">
                  <img
                    src={zendeskLogo}
                    alt="Zendesk Logo"
                    className="mr-4 h-6 w-6"
                  />
                  Sync your Zendesk articles
                </div>
                <SyncStatus />
              </Button>
            )}
            {syncStatus === "error" && (
              <p className="text-sm text-red-600">
                An error occurred while syncing articles. Please try again.
              </p>
            )}
          </div>
        )}

        <Link
          to="/organization/$organizationSlug/onboarding/knowledge/websites"
          params={{ organizationSlug }}
          className="relative"
        >
          <Button
            variant="outline"
            className="h-10 w-full justify-start border-[#041627] font-semibold"
          >
            <Globe className="mr-4 h-6 w-6" strokeWidth={1.5} />
            Add public websites
          </Button>
          <HoverCard>
            <HoverCardTrigger asChild>
              <CircleHelp
                className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
                strokeWidth={1.5}
              />
            </HoverCardTrigger>
            <HoverCardContent className="w-[400px]" side="top">
              <div className="space-y-4 text-sm leading-relaxed text-gray-800">
                <h4 className="font-semibold">About Website Scanning</h4>

                <p>
                  Website scanning offers a quick way to add supplementary
                  information to your AI. While not as reliable as other
                  methods, it's perfect for testing or adding general content.
                  Simply provide the URLs and the scanning{" "}
                  <span className="font-medium">
                    completes automatically within 1 minute
                  </span>
                  .
                </p>

                <p>
                  Use this feature for content like "About Us" pages and other
                  general information.{" "}
                  <span className="font-medium">
                    {" "}
                    However, for critical content such as policies and terms, we
                    strongly recommend using articles instead to ensure 100%
                    accuracy{" "}
                  </span>
                  .
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </Link>

        <Link
          className="relative"
          to="/organization/$organizationSlug/onboarding/knowledge/article"
          params={{ organizationSlug }}
        >
          <Button
            variant="outline"
            className="h-10 w-full justify-start border-[#041627] font-semibold"
          >
            <FileText className="mr-4 h-6 w-6" strokeWidth={1.5} />
            Add articles
          </Button>
          <HoverCard>
            <HoverCardTrigger asChild>
              <CircleHelp
                className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
                strokeWidth={1.5}
              />
            </HoverCardTrigger>
            <HoverCardContent className="w-[400px]" side="top">
              <div className="space-y-4 text-sm leading-relaxed text-gray-800">
                <h4 className="font-semibold">About Articles</h4>

                <p>
                  Articles are the most reliable way to give your AI accurate
                  knowledge. While website scanning is convenient, manually
                  created articles ensure your AI learns exactly what you want
                  it to know.
                </p>

                <ul className="ml-4 list-disc space-y-1">
                  <li>Create articles with descriptive titles</li>
                  <li>Add detailed content in a clear format</li>
                </ul>

                <p>
                  Articles work best for important information like shipping
                  policies, payment options, and company information that your
                  AI needs to understand completely.
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </Link>

        <p className="text-center text-sm text-gray-600">
          Start with the basics - you can always add more later.
        </p>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/help-desk"
          nextPath="/organization/$organizationSlug/onboarding/features"
          isNextEnabled={true}
        />
      </div>
    </div>
  );
}
