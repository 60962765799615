import { useBanner } from "../-hooks/useBanner";

export function Banner() {
  const { shouldShow, height } = useBanner();

  if (!shouldShow) return null;

  return (
    <div
      className="bg-blue-dark flex w-full items-center justify-center text-sm text-white"
      style={{ height }}
    >
      Need help? We&apos;re here to help at&nbsp;
      <a className="font-semibold" href="mailto:info@octocom.ai">
        info@octocom.ai
      </a>
    </div>
  );
}
