// Define a type for the schedule of a single day
interface DaySchedule {
  open: string;
  close: string;
}

// Define a type for SupportHours that includes all days of the week
export interface SupportHoursType {
  Sunday?: DaySchedule;
  Monday?: DaySchedule;
  Tuesday?: DaySchedule;
  Wednesday?: DaySchedule;
  Thursday?: DaySchedule;
  Friday?: DaySchedule;
  Saturday?: DaySchedule;
  timezone: string;
}

export function currentlyInSupportTimeWindow({
  supportHours,
}: {
  supportHours: SupportHoursType | undefined;
}) {
  if (supportHours) {
    const bypassSupportHours =
      new URL(window.location.href).searchParams.get("bypass-support-hours") !==
      null;

    if (bypassSupportHours) {
      return true;
    }

    const now = new Date();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ] as const;
    type DayOfWeek = (typeof daysOfWeek)[number];

    const dayOfWeek: DayOfWeek = daysOfWeek[now.getDay()];
    const daySchedule = supportHours[dayOfWeek];

    if (!daySchedule) {
      return true;
    }

    const openTime = new Date(now);
    const closeTime = new Date(now);
    openTime.setHours(
      parseInt(daySchedule.open.split(":")[0]),
      parseInt(daySchedule.open.split(":")[1]),
      daySchedule.open.split(":")[2]
        ? parseInt(daySchedule.open.split(":")[2])
        : 0,
      0,
    );
    closeTime.setHours(
      parseInt(daySchedule.close.split(":")[0]),
      parseInt(daySchedule.close.split(":")[1]),
      daySchedule.close.split(":")[2]
        ? parseInt(daySchedule.close.split(":")[2])
        : 0,
      0,
    );

    const configTimeZone = supportHours.timezone;

    const nowInConfigTZ = new Date(
      now.toLocaleString("en-US", { timeZone: configTimeZone }),
    );

    return nowInConfigTZ < openTime || nowInConfigTZ > closeTime;
  }

  return true;
}
