import { Route } from "../_navbar";

export function useBanner() {
  const { organization } = Route.useRouteContext();

  const shouldShow = organization.onboardingStatus === "SETUP";

  const height = "40px";

  return {
    shouldShow,
    height,
  };
}
