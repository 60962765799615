import { useConfig } from "@chatbot/hooks/config.hooks";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import clsxm from "@chatbot/utils/clsxm";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/solid";
import { KeyboardEvent } from "react";

export function IconLauncher() {
  const config = useConfig();
  const { displayChatWindow, openChatWindow, closeChatWindow } = useUiState();

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (displayChatWindow) {
        closeChatWindow();
      } else {
        openChatWindow();
      }
    }
  };

  return (
    <div
      // aria-label={displayChatWindow ? "Close chat widget" : "Open chat widget"}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={() => (displayChatWindow ? closeChatWindow() : openChatWindow())}
      className="flex cursor-pointer items-center justify-center rounded-full border bg-white shadow-lg hover:scale-105"
      style={{
        height: config.launcherSize ?? "48px",
        minWidth: config.launcherSize ?? "48px",
        backgroundColor: config.launcherBackgroundColor ?? undefined,
      }}
    >
      <div className="flex items-center justify-center">
        {config.launcherIconFileUrl ? (
          <img
            className={clsxm("mx-auto my-auto rounded")}
            style={{
              height: config.launcherIconSize ?? "24px",
              width: config.launcherIconSize ?? "24px",
            }}
            src={config.launcherIconFileUrl}
          />
        ) : (
          <>
            {/* // TODO: solve the isOpen null issue */}
            <ChatBubbleOvalLeftIcon
              className={clsxm(
                "absolute",
                displayChatWindow === null
                  ? ""
                  : displayChatWindow
                  ? "animate-open-chat-icon"
                  : "animate-close-chat-icon",
              )}
              style={{
                height: config.launcherIconSize ?? "24px",
                width: config.launcherIconSize ?? "24px",
                color: config.launcherIconColor ?? undefined,
              }}
            />

            {/* // TODO: solve the isOpen null issue */}
            <ChevronDownIcon
              className={clsxm(
                "absolute h-6 w-6",
                displayChatWindow === null
                  ? ""
                  : displayChatWindow
                  ? "animate-open-chevron-icon"
                  : "animate-close-chevron-icon",
              )}
              style={{
                color: config.launcherIconColor ?? undefined,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
