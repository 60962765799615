import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { Button } from "@dashboard/common/ui/button";
import { Plus } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { Pagination } from "@dashboard/common/ui/Pagination";
import { useDeleteProducts } from "./-hooks/useDeleteProducts";

const ITEMS_PER_PAGE = 20;

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/",
)({
  validateSearch: z.object({
    pageNumber: z.coerce.number().optional(),
  }),
  loaderDeps: ({ search: { pageNumber } }) => ({ pageNumber }),
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
    deps: { pageNumber },
  }) => {
    await trpcClientUtils.dashboard.business.getOrThrow.ensureData({
      businessSlug,
    });

    await trpcClientUtils.dashboard.products.getAll.ensureData({
      businessSlug,
      pageNumber,
    });

    await trpcClientUtils.dashboard.products.getCount.ensureData({
      businessSlug,
    });
  },
  component: ProductsPage,
});

function ProductsPage() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { pageNumber = 1 } = Route.useSearch();
  const { trpc } = Route.useRouteContext();

  const [products] = trpc.dashboard.products.getAll.useSuspenseQuery({
    businessSlug,
    pageNumber,
  });

  const [count] = trpc.dashboard.products.getCount.useSuspenseQuery({
    businessSlug,
  });

  const [business] = trpc.dashboard.business.getOrThrow.useSuspenseQuery({
    businessSlug,
  });

  const records = products.map((product) => ({
    id: product.id,
    name: product.name[business.defaultLanguage],
    slug: product.slug,
  }));

  records.sort((a, b) => a.name.localeCompare(b.name));

  const pageCount = Math.ceil(count / ITEMS_PER_PAGE);

  const { deleteProducts } = useDeleteProducts();

  return (
    <div className="relative py-0">
      <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
        <h1 className="text-xl font-medium text-black">
          Products
          <span className="text-gray"> {`of ${business.name}`}</span>
        </h1>
        <div className="flex items-center gap-2">
          <Link
            to="/organization/$organizationSlug/settings/$businessSlug/knowledge/products/new"
            params={{ organizationSlug, businessSlug }}
          >
            <Button
              className="flex items-center justify-between gap-2"
              type="button"
            >
              <Plus className="h-4 w-4" strokeWidth={3} />
              Create a new product
            </Button>
          </Link>
        </div>
      </div>
      <Table
        records={records}
        columns={[
          {
            header: "Name",
            accessor: "name",
          },
          {
            header: "Slug",
            accessor: "slug",
          },
          {
            header: "ID",
            accessor: "id",
          },
        ]}
        deleteRecords={deleteProducts}
        recordName={{ singular: "Product", plural: "Products" }}
        noRecordsText="No products available"
      />
      <div className="flex justify-center py-4">
        <Pagination
          currentPage={pageNumber}
          pageCount={pageCount}
          baseUrl={`/organization/${organizationSlug}/settings/${businessSlug}/products`}
        />
      </div>
    </div>
  );
}
