import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { useArchiveUsers } from "./-hooks/useArchiveUsers";
import { AddUserDialogContent } from "./-components/AddUserDialogContent";
import { Badge } from "@dashboard/common/ui/badge";
import { useResetPassword } from "./-hooks/useResetPassword";
import { AdminPermissions } from "./-components/AdminPermissions";
import { useUnarchiveUser } from "./-hooks/useUnarchiveUser";
import { EditUserDialogContent } from "./-components/EditUserDialogContent";
import { AgentStatus } from "./-components/AgentStatus";
import { LastSeenAt } from "./-components/LastSeenAt";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
      organizationSlug,
      includeArchived: true,
    });
  },
  component: TeamSettingsPage,
});

function TeamSettingsPage() {
  const { trpc } = Route.useRouteContext();

  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
    includeArchived: true,
  });

  const records = users.map((user) => ({
    id: user.id,
    fullName: user.fullName,
    email: user.email,
    isManager: user.isManager,
    isAgent: user.isAgent,
    lastSeenAt: <LastSeenAt lastSeenAt={user.lastSeenAt} />,
    adminPermissions: user.isArchived ? null : (
      <AdminPermissions
        userId={user.id}
        isManager={user.isManager}
        isDisabled={user.isArchived}
      />
    ),
    agentStatus: user.isArchived ? null : (
      <AgentStatus
        userId={user.id}
        isAgent={user.isAgent}
        isDisabled={user.isArchived}
      />
    ),
    isArchived: user.isArchived ? (
      <Badge variant="destructive">Archived</Badge>
    ) : (
      <Badge variant="default">Active</Badge>
    ),
    enableDelete: !user.isArchived,
    enableEdit: !user.isArchived,
    enableContextActions: {
      "Reset Password": !user.isArchived,
      Unarchive: user.isArchived,
    },
  }));

  const { archiveUsers } = useArchiveUsers();
  const { resetPassword } = useResetPassword();
  const { unarchiveUser } = useUnarchiveUser();

  return (
    <div className="flex h-full flex-col">
      <Table
        title="Team Management"
        records={records}
        columns={[
          {
            header: "Full Name",
            accessor: "fullName",
          },
          {
            header: "Email",
            accessor: "email",
          },
          {
            header: "Status",
            accessor: "isArchived",
          },
          {
            header: "Permissions",
            accessor: "adminPermissions",
          },
          {
            header: "Agent",
            accessor: "agentStatus",
          },
          {
            header: "Last Seen",
            accessor: "lastSeenAt",
          },
        ]}
        addRecordDialogContent={() => <AddUserDialogContent />}
        editRecordDialogContent={(record, setOpen) => (
          <EditUserDialogContent {...record} setOpen={setOpen} />
        )}
        deleteRecords={archiveUsers}
        recordName={{ singular: "Member", plural: "Members" }}
        noRecordsText="No members available"
        actionLabels={{ delete: "Archive", edit: "Edit" }}
        contextActions={[
          { label: "Reset Password", action: resetPassword },
          { label: "Unarchive", action: unarchiveUser },
        ]}
      />
    </div>
  );
}
