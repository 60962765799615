import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { Table } from "@dashboard/common/Table/Table";
import { CreateWebsiteDialogContent } from "./-components/CreateWebsiteDialogContent";
import { useDeleteWebsites } from "./-hooks/useDeleteWebsites";
import { useScrapeWebsites } from "./-hooks/useScrapeWebsites";
import { format } from "date-fns";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.scraper.getAll.ensureData({
      businessSlug,
    });
  },

  component: WebsitesPage,
});

function WebsitesPage() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug, organizationSlug } = Route.useParams();
  const navigate = useNavigate();

  const [websites] = trpc.dashboard.scraper.getAll.useSuspenseQuery({
    businessSlug,
  });

  const { deleteWebsites } = useDeleteWebsites();
  const { scrapeWebsites } = useScrapeWebsites();

  const records = websites.map((website) => ({
    id: website.id,
    website: (
      <a
        href={website.url}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline"
      >
        {website.url}
      </a>
    ),
    lastScrapedAt: website.lastScrapedAt
      ? format(website.lastScrapedAt, "EEE, d MMM yyyy, HH:mm")
      : "Never",
    numOfArticles: website.numOfContextElements
      ? website.numOfContextElements
      : 0,
  }));

  return (
    <div className="flex h-full flex-col">
      <Table
        title="Websites"
        records={records}
        columns={[
          {
            header: "Website",
            accessor: "website",
          },
          {
            header: "Last Scraped At",
            accessor: "lastScrapedAt",
          },
          {
            header: "Number of Articles",
            accessor: "numOfArticles",
          },
        ]}
        addRecordDialogContent={(setOpen) => (
          <CreateWebsiteDialogContent setOpen={setOpen} />
        )}
        deleteRecords={deleteWebsites}
        contextActions={[
          {
            label: "Rescrape",
            action: scrapeWebsites,
          },
          {
            label: "View extracted articles",
            action: (selectedRecords) => {
              if (selectedRecords.length === 1) {
                const webpageId = selectedRecords[0];
                void navigate({
                  to: `/organization/$organizationSlug/settings/$businessSlug/knowledge/websites/context-elements`,
                  search: { webpageId },
                  params: { organizationSlug, businessSlug },
                }); //this needs to be absolute, because otherwise type inference breaks for the search object
              }
            },
          },
        ]}
        headerActions={[
          {
            label: "Scrape",
            action: scrapeWebsites,
          },
        ]}
        recordName={{ singular: "Website", plural: "Websites" }}
        noRecordsText="No websites available"
      />
    </div>
  );
}
