interface StatusOption {
  label: string;
  value: string;
}

interface StatusFilterTabsProps {
  statusOptions: StatusOption[];
  statusFilter: string;
  setStatusFilter: (value: string) => void;
}

export function StatusFilterTabs({
  statusOptions,
  statusFilter,
  setStatusFilter,
}: StatusFilterTabsProps) {
  return (
    <div className="mb-2 border-b">
      <div className="flex gap-2">
        {statusOptions.map((option) => (
          <button
            key={option.value}
            onClick={() => setStatusFilter(option.value)}
            className={`border-b-2 px-3 pb-1 text-xs font-medium transition-colors ${
              statusFilter === option.value
                ? "border-primary text-primary"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }`}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}
