import { format } from "date-fns";
import { Route } from "..";
import { useOrders } from "./orders/useOrders";
import { useSubscriptions } from "./subscriptions/useSubscriptions";
import { useConversation } from "./useConversation.hook";
import { MacroData } from "../../-utils/macroTypes";

const dataFormat = "MM/d/yyyy";

export function useMacroData(): MacroData {
  const { user } = Route.useRouteContext();

  const conversation = useConversation();
  const orders = useOrders();
  const subscriptions = useSubscriptions();

  const lastOrder = orders.data?.[0];
  const lastSubscription = subscriptions.data?.[0];

  return {
    agentName: user.fullName,
    // for backwards compatibility
    customerName: conversation.customer?.name ?? "",
    customer: {
      name: conversation.customer?.name ?? "",
      email: conversation.customer?.email ?? "",
      phone: conversation.customer?.phone ?? "",
    },
    lastOrder: {
      orderPlacedAt: lastOrder?.orderPlacedAt
        ? format(new Date(lastOrder.orderPlacedAt), dataFormat)
        : "",
      trackingId: lastOrder?.fulfillments[0]?.trackingId ?? "",
      trackingUrl: lastOrder?.fulfillments[0]?.trackingUrl ?? "",
      orderId: lastOrder?.orderId ?? "",
      statusPageUrl: lastOrder?.statusPageUrl ?? "",
    },
    lastSubscription: {
      nextChargeDate: lastSubscription?.nextChargeDate
        ? format(new Date(lastSubscription.nextChargeDate), dataFormat)
        : "",
    },
  };
}
