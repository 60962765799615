import { env } from "@dashboard/env";

export function installMetaIntegration({
  channel,
  businessSlug,
}: {
  channel: "instagram" | "messenger" | "whatsapp";
  businessSlug: string;
}) {
  let configId;
  switch (channel) {
    case "messenger":
      configId = env.DASHBOARD_MESSENGER_CONFIGURATION_ID;
      break;
    case "whatsapp":
      configId = env.DASHBOARD_WHATSAPP_CONFIGURATION_ID;
      break;
    case "instagram":
      configId = env.DASHBOARD_INSTAGRAM_CONFIGURATION_ID;
      break;
  }

  const url = new URL("https://www.facebook.com/v18.0/dialog/oauth?");

  const params = new URLSearchParams({
    client_id: env.DASHBOARD_META_APP_ID,
    redirect_uri: env.DASHBOARD_META_REDIRECT_URI,
    response_type: "code",
    state: JSON.stringify({ configId, businessSlug }),
    config_id:
      businessSlug === "tolink" || businessSlug === "meta-test"
        ? channel === "instagram"
          ? "2055835391598728"
          : "529502050107091"
        : configId,
  });

  localStorage.setItem("authState", JSON.stringify({ configId, businessSlug }));

  url.search = params.toString();

  window.location.href = url.toString();
}
