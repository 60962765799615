import { Button } from "@dashboard/common/ui/button";
import { Separator } from "@dashboard/common/ui/separator";
import { Link, createFileRoute } from "@tanstack/react-router";
import {
  BookOpen,
  BookText,
  Globe,
  Globe2,
  File,
  ScanBarcode,
  Bot,
} from "lucide-react";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/",
)({
  component: KnowledgePage,
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.bots.getOrThrow.ensureData({
      businessSlug,
    });
  },
});

function KnowledgePage() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { user, trpc } = Route.useRouteContext();

  const [bot] = trpc.dashboard.bots.getOrThrow.useSuspenseQuery({
    businessSlug,
  });

  const showBotRules = !bot.v2Enabled;

  return (
    <div className="flex h-full flex-col">
      <div className="border-gray-light w-full border-b px-10 py-4">
        <h1 className="text-xl font-medium text-black">Bot Knowledge</h1>
      </div>

      <div className="flex flex-col gap-8 p-8">
        {showBotRules && (
          <div className="border-gray-light flex flex-col gap-4 rounded-lg border p-4">
            <div className="flex items-center justify-start gap-4">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black">
                <Bot className="h-7 w-7 text-white" />
              </div>
              <div className="flex flex-col">
                <h2 className="text-lg font-medium text-black">Bot Rules</h2>
                <p className="text-gray">
                  Manage the rules that your bot will follow.
                </p>
              </div>
            </div>
            <Separator />
            <div className="flex flex-col pl-16">
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <Bot className="h-5 w-5" />
                  <span className="w-24 text-black">Bot Rules</span>
                </div>
                <Link
                  to="/organization/$organizationSlug/settings/$businessSlug/knowledge/bot-rules"
                  params={{ organizationSlug, businessSlug }}
                  className="flex items-center gap-2"
                >
                  <Button className="w-40" variant="secondary">
                    Manage Bot Rules
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        )}
        {/* Articles */}
        <div className="border-gray-light flex flex-col gap-4 rounded-lg border p-4">
          <div className="flex items-center justify-start gap-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black">
              <BookOpen className="h-7 w-7 text-white" />
            </div>
            <div className="flex flex-col">
              <h2 className="text-lg font-medium text-black">Articles</h2>
              <p className="text-gray">
                Manage the articles available to your bot.
              </p>
            </div>
          </div>
          <Separator />
          <div className="flex flex-col pl-16">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <BookText className="h-5 w-5" />
                <span className="w-24 text-black">Articles</span>
              </div>
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/knowledge/articles"
                params={{ organizationSlug, businessSlug }}
                className="flex items-center gap-2"
              >
                <Button className="w-40" variant="secondary">
                  Manage Articles
                </Button>
              </Link>
            </div>
          </div>
        </div>
        {/* Websites */}
        <div className="border-gray-light flex flex-col gap-4 rounded-lg border p-4">
          <div className="flex items-center justify-start gap-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black">
              <Globe className="h-7 w-7 text-white" />
            </div>
            <div className="flex flex-col">
              <h2 className="text-lg font-medium text-black">Websites</h2>
              <p className="text-gray">
                Add public websites to be used by the bot.
              </p>
            </div>
          </div>
          <Separator />
          <div className="flex flex-col pl-16">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Globe2 className="h-5 w-5" />
                <span className="w-24 text-black">Websites</span>
              </div>
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/knowledge/websites"
                params={{ organizationSlug, businessSlug }}
                className="flex items-center gap-2"
              >
                <Button className="w-40" variant="secondary">
                  Manage Websites
                </Button>
              </Link>
            </div>
          </div>
        </div>
        {/* Documents */}
        <div className="border-gray-light flex flex-col gap-4 rounded-lg border p-4">
          <div className="flex items-center justify-start gap-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black">
              <File className="h-7 w-7 text-white" />
            </div>
            <div className="flex flex-col">
              <h2 className="text-lg font-medium text-black">Documents</h2>
              <p className="text-gray">
                Upload documents to be used by the bot.
              </p>
            </div>
          </div>
          <Separator />
          <div className="flex flex-col pl-16">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <File className="h-5 w-5" />
                <span className="w-24 text-black">Documents</span>
              </div>
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/knowledge/documents"
                params={{ organizationSlug, businessSlug }}
                className="flex items-center gap-2"
              >
                <Button className="w-40" variant="secondary">
                  Manage Documents
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="border-gray-light flex flex-col gap-4 rounded-lg border p-4">
          <div className="flex items-center justify-start gap-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black">
              <ScanBarcode className="h-7 w-7 text-white" />
            </div>
            <div className="flex flex-col">
              <h2 className="text-lg font-medium text-black">Products</h2>
              <p className="text-gray">
                View the products available to the bot.
              </p>
            </div>
          </div>
          <Separator />
          {user.isAdmin && (
            <div className="flex flex-col pl-16">
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <ScanBarcode className="h-5 w-5" />
                  <span className="w-24 text-black">Products</span>
                </div>
                <Link
                  to="/organization/$organizationSlug/settings/$businessSlug/knowledge/products"
                  params={{ organizationSlug, businessSlug }}
                  className="flex items-center gap-2"
                >
                  <Button className="w-40" variant="secondary">
                    View Products
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
