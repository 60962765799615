import { RouterOutput } from "@be/exports";
import { useParams } from "@tanstack/react-router";
import { useRef, useEffect } from "react";
import { useInsertConversation } from "./useInsertConversation";
import { useRemoveConversation } from "./useRemoveConversation";
import { toast } from "sonner";
import { ConversationEventType } from "@be/modules/dashboard/dashboard.types";

type DashboardConversation = RouterOutput["dashboard"]["conversations"]["get"];

export function useHandleUpdateEvent({
  setMovedConversation,
  pageParams,
}: {
  pageParams: (string | null)[];
  setMovedConversation: (publicId: string | null) => void;
}) {
  const { publicId } = useParams({
    strict: false,
  });

  const publicIdRef = useRef(publicId);

  useEffect(() => {
    publicIdRef.current = publicId;
  }, [publicId]);

  const insertConversation = useInsertConversation({
    pageParams,
  });

  const removeConversation = useRemoveConversation({
    pageParams,
  });

  return (data: {
    event: ConversationEventType;
    conversation: DashboardConversation;
    inView: boolean;
  }) => {
    if (!data.inView) {
      if (data.conversation.publicId === publicIdRef.current) {
        setMovedConversation(data.conversation.publicId);
      } else {
        removeConversation({ publicId: data.conversation.publicId });
      }
    } else {
      if (data.conversation.publicId === publicIdRef.current) {
        setMovedConversation(null);
        insertConversation(data.conversation);
        if (data.event === "replyChannelChangeClosed") {
          toast(
            "The customer was notified that you will be replaying via email because the customer was waiting for too long.",
            {
              position: "top-center",
            },
          );
        }
      } else {
        insertConversation(data.conversation);
      }
    }
  };
}
