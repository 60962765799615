import { createFileRoute } from "@tanstack/react-router";
import { IntegrationServiceName } from "@be/modules/integrations/integrations.types";

import { TelegramIntegrationDialog } from "./-components/TelegramIntegrationDialog";
import { FacebookIntegrationDialog } from "./-components/FacebookIntegrationDialog";
import { WhatsappIntegrationDialog } from "./-components/WhatsappIntegrationDialog";
import { InstagramIntegrationDialog } from "./-components/InstagramIntegrationDialog";
import { ShopifyIntegrationDialog } from "./-components/ShopifyIntegrationDialog";
import { WixIntegrationDialog } from "./-components/WixIntegrationDialog";
import { GorgiasIntegrationDialog } from "./-components/GorgiasIntegrationDialog";
import { ZendeskIntegrationDialog } from "./-components/ZendeskIntegrationDialog";
import { EmailIntegrationDialog } from "./-components/EmailIntegrationDialog";
import { GoogleIntegrationDialog } from "./-components/GoogleIntegrationDialog";
import { OutlookIntegrationDialog } from "./-components/OutlookIntegrationDialog";
import { DixaIntegrationDialog } from "./-components/DixaIntegrationDialog";
import { HubspotIntegrationDialog } from "./-components/HubspotIntegrationDialog";
import { RichpanelIntegrationDialog } from "./-components/RichpanelIntegrationDialog";
import { KustomerIntegrationDialog } from "./-components/KustomerIntegrationDialog";
import { ReamazeIntegrationDialog } from "./-components/ReamazeIntegrationDialog";
import { LiveAgentIntegrationDialog } from "./-components/LiveAgentIntegrationDialog";
import { FreshdeskIntegrationDialog } from "./-components/FreshdeskIntegrationDialog";
import { KlaviyoIntegrationDialog } from "./-components/KlaviyoIntegrationDialog";
import { BrazeIntegrationDialog } from "./-components/BrazeIntegrationDialog";
import { NetoHqIntegrationDialog } from "./-components/NetoHqIntegrationDialog";
import { AdobeCommerceIntegrationDialog } from "./-components/AdobeCommerceIntegrationDialog";
import { BitrixIntegrationDialog } from "./-components/BitrixIntegrationDialog";
import { PrestaIntegrationDialog } from "./-components/PrestaIntegrationDialog";
import { BigCommerceIntegrationDialog } from "./-components/BigCommerceIntegrationDialog";
import { WooCommerceIntegrationDialog } from "./-components/WooCommerceIntegrationDialog";
import { z } from "zod";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/",
)({
  validateSearch: z.object({
    dialog: z.string().optional(),
  }),
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    const integrations: IntegrationServiceName[] = [
      "telegram",
      "smtpImap",
      "dixa",
      "richpanel",
      "hubspot",
      "klaviyo",
      "braze",
      "shopify",
    ];

    const emailIntegrationNames = ["gmail", "microsoft", "smtp_imap"] as const;

    await Promise.all([
      trpcClientUtils.dashboard.integrations.getInstalledIntegrations.ensureData(
        {
          businessSlug,
        },
      ),
      ...integrations.map((integrationServiceName) =>
        trpcClientUtils.dashboard.integrations.getIntegration.ensureData({
          businessSlug,
          integrationServiceName,
        }),
      ),
      ...emailIntegrationNames.map((integrationName) =>
        trpcClientUtils.dashboard.emails.getEmailAddress.ensureData({
          businessSlug,
          integrationName,
        }),
      ),
    ]);
  },

  component: IntegrationsPage,
});

function IntegrationsPage() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [installedIntegrations] =
    trpc.dashboard.integrations.getInstalledIntegrations.useSuspenseQuery({
      businessSlug,
    });

  const integrationsList = [
    // Main Help Desks
    {
      component: GorgiasIntegrationDialog,
      isInstalled: installedIntegrations.includes("gorgias"),
    },
    {
      component: ZendeskIntegrationDialog,
      isInstalled: installedIntegrations.includes("zendesk"),
    },
    { component: FreshdeskIntegrationDialog, isInstalled: false },

    // Email integrations
    {
      component: EmailIntegrationDialog,
      isInstalled: installedIntegrations.includes("smtpImap"),
    },
    {
      component: OutlookIntegrationDialog,
      isInstalled: installedIntegrations.includes("outlook"),
    },
    {
      component: GoogleIntegrationDialog,
      isInstalled: installedIntegrations.includes("google"),
    },

    // Messaging integrations
    {
      component: TelegramIntegrationDialog,
      isInstalled: installedIntegrations.includes("telegram"),
    },
    {
      component: FacebookIntegrationDialog,
      isInstalled: installedIntegrations.includes("messenger"),
    },
    {
      component: WhatsappIntegrationDialog,
      isInstalled: installedIntegrations.includes("whatsapp"),
    },
    {
      component: InstagramIntegrationDialog,
      isInstalled: installedIntegrations.includes("instagram"),
    },

    // Marketing integrations
    {
      component: KlaviyoIntegrationDialog,
      isInstalled: installedIntegrations.includes("klaviyo"),
    },
    {
      component: BrazeIntegrationDialog,
      isInstalled: installedIntegrations.includes("braze"),
    },

    // E-commerce integrations
    {
      component: ShopifyIntegrationDialog,
      isInstalled: installedIntegrations.includes("shopify"),
    },
    {
      component: WixIntegrationDialog,
      isInstalled: installedIntegrations.includes("wix"),
    },
    { component: NetoHqIntegrationDialog, isInstalled: false },
    { component: BigCommerceIntegrationDialog, isInstalled: false },
    { component: AdobeCommerceIntegrationDialog, isInstalled: false },
    { component: PrestaIntegrationDialog, isInstalled: false },
    { component: WooCommerceIntegrationDialog, isInstalled: false },
    { component: BitrixIntegrationDialog, isInstalled: false },

    // Additional Help Desks
    {
      component: DixaIntegrationDialog,
      isInstalled: installedIntegrations.includes("dixa"),
    },
    {
      component: HubspotIntegrationDialog,
      isInstalled: installedIntegrations.includes("hubspot"),
    },
    {
      component: RichpanelIntegrationDialog,
      isInstalled: installedIntegrations.includes("richpanel"),
    },
    { component: KustomerIntegrationDialog, isInstalled: false },
    { component: ReamazeIntegrationDialog, isInstalled: false },
    { component: LiveAgentIntegrationDialog, isInstalled: false },
  ];

  const sortedIntegrations = [...integrationsList].sort(
    (a, b) => Number(b.isInstalled) - Number(a.isInstalled),
  );

  return (
    <div className="flex h-full flex-col">
      <div className="border-gray-light w-full border-b bg-white px-10 py-4">
        <h1 className="text-xl font-medium text-black">Integrations</h1>
      </div>
      <div className="grid w-full grid-cols-4 gap-4 p-10">
        {sortedIntegrations.map(
          ({ component: IntegrationDialog, isInstalled }) => (
            <IntegrationDialog
              key={IntegrationDialog.name}
              installed={isInstalled}
            />
          ),
        )}
      </div>
    </div>
  );
}
