import { BrowserSessionProvider } from "./components/BrowserSessionProvider";
import CustomErrorBoundary from "./components/CustomErrorBoundary";

export function App({
  presetConfigId,
  containerMode,
}: {
  presetConfigId?: string;
  containerMode?: boolean;
}) {
  return (
    <CustomErrorBoundary>
      <BrowserSessionProvider
        presetConfigId={presetConfigId}
        containerMode={containerMode}
      />
    </CustomErrorBoundary>
  );
}
