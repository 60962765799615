import { Link } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import { useCompleteOnboardingStep } from "../../-hooks/useCompleteOnboardingStep";
import { Check } from "lucide-react";

export function ImplementFlows({
  businessSlug,
  organizationSlug,
  completed,
}: {
  businessSlug: string;
  organizationSlug: string;
  completed: boolean;
}) {
  const completeStep = useCompleteOnboardingStep();

  return (
    <div className="space-y-6">
      <p className="text-justify">
        Configure automated workflows to handle common post-sale customer
        requests like order cancellations, shipping address updates, contact
        detail changes, and order item modifications. These flows help provide
        faster and more consistent customer service for routine order management
        tasks.
      </p>
      <div className="flex flex-col gap-4">
        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/simple-flows"
          params={{
            organizationSlug,
            businessSlug,
          }}
          className="w-full"
        >
          <Button className="w-full">Configure Flows</Button>
        </Link>

        {!completed ? (
          <Button
            onClick={() => completeStep({ step: "flows" })}
            variant="secondary"
            className="w-full"
          >
            Complete
          </Button>
        ) : (
          <Button className="w-full">
            <Check className="mr-2 h-4 w-4" />
            Completed
          </Button>
        )}
      </div>
    </div>
  );
}
