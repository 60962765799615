import { toast } from "sonner";
import { Route } from "..";
import { requestAlertDialogDisplay } from "@dashboard/common/GlobalAlertDialog/GlobalAlertDialog";
import { articleExportSchema } from "../-utils/articleExportSchema";

export function useImportArticles() {
  const { businessSlug } = Route.useParams();
  const search = Route.useSearch();
  const { searchQuery } = search;
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const [currentArticles] = trpc.dashboard.articles.getAll.useSuspenseQuery({
    searchQuery,
    businessSlug,
  });

  const importArticlesMutation =
    trpc.dashboard.articles.createMany.useMutation();

  return {
    importArticles: () => {
      void getJsonString().then((jsonString) => {
        if (!jsonString) return;
        const data = validateAndParseJsonString(jsonString);
        if (!data) {
          toast.error("Import error: Invalid JSON provided");
          return;
        }

        if (data.length === 0) {
          toast.error("Import error: No articles to import");
          return;
        }

        const articlesToCreate: typeof data = [];
        for (const article of data) {
          const identicalArticle = currentArticles.find(
            (a) => a.title === article.title && a.content === article.content,
          );
          if (identicalArticle) {
            continue;
          }

          articlesToCreate.push({
            title: article.title,
            content: article.content,
            categoryId: article.categoryId,
            isPublic: article.isPublic,
          });
        }

        if (articlesToCreate.length === 0) {
          toast.success("No new articles to import");
          return;
        }

        requestAlertDialogDisplay({
          title: "Import articles",
          description: `Do you confirm you want to import ${
            articlesToCreate.length
          } article${
            articlesToCreate.length === 1 ? "" : "s"
          } from the provided file?`,
          onAction: () => {
            void importArticlesMutation
              .mutateAsync({
                businessSlug,
                articles: articlesToCreate,
              })
              .then(async () => {
                await trpcUtils.dashboard.articles.getAll.invalidate({
                  searchQuery,
                  businessSlug,
                });
                toast.success("Articles imported successfully");
              });
          },
          actionText: "Import",
        });
      });
    },
  };
}

function getJsonString() {
  return new Promise<string | null>((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";

    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) {
        resolve(null);
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result !== "string") {
          reject(new Error("File is not a string when using readAsText"));
          return;
        }
        resolve(result);
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };

      reader.readAsText(file);
    };

    input.oncancel = () => {
      resolve(null);
    };

    input.click();
  });
}

function validateAndParseJsonString(jsonString: string) {
  let parsedJson: unknown;
  try {
    parsedJson = JSON.parse(jsonString) as unknown;
  } catch (error) {
    return;
  }

  const jsonValidationResult = articleExportSchema.safeParse(parsedJson);

  if (!jsonValidationResult.success) {
    return;
  }

  return jsonValidationResult.data;
}
