import { toast } from "sonner";
import { Route } from "..";

export function useUpdateAgentStatus() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const updateAgentStatusMutation = trpc.dashboard.users.update.useMutation({
    onError: (error) => {
      const message = "Failed to update agent status.";

      toast(message);
      console.error(error);
    },
  });

  return {
    updateAgentStatus: ({ id, isAgent }: { id: string; isAgent: boolean }) => {
      updateAgentStatusMutation.mutate({
        organizationSlug,
        data: { id, isAgent },
      });
    },
  };
}
