import { useInitializeUiState } from "@chatbot/hooks/uiState.hooks";
import { ChatWindow } from "./ChatWindow/ChatWindow";
import { Launcher } from "./Launcher";
import clsxm from "@chatbot/utils/clsxm";
import { ReadonlyConfig } from "@chatbot/hooks/config.hooks";
import { useEffect } from "react";

import {
  currentlyInSupportTimeWindow,
  SupportHoursType,
} from "@chatbot/utils/supportHours";
import { useFurniture1Hack } from "@chatbot/hooks/hacks.hooks";
import AttentionGrabbers from "./AttentionGrabbers";

export function Coordinator({
  browserSessionId,
  config,
  containerMode,
}: {
  browserSessionId: string;
  config: ReadonlyConfig;
  containerMode?: boolean;
}) {
  const ui = useInitializeUiState({ configId: config.id, containerMode });

  useEffect(() => {
    if (!ui?.loadedIntoContainer) {
      const chatWidget = document.querySelector("chat-widget");
      if (chatWidget) {
        (chatWidget as HTMLElement).style.zIndex = config.zIndex.toString();
      }
    }
  }, [config.zIndex, ui?.loadedIntoContainer]);

  const isInSupportTimeWindow = currentlyInSupportTimeWindow({
    supportHours: config.supportHours as SupportHoursType | undefined,
  });

  useFurniture1Hack({
    enabled:
      new URL(window.location.href).hostname === "www.arredamento1.it" &&
      isInSupportTimeWindow,
  });

  if (!ui) {
    return null;
  }

  if (!isInSupportTimeWindow) {
    return null;
  }

  console.log("display chat window", ui.displayChatWindow);

  const { isMobile, loadedIntoContainer } = ui;

  return (
    <div
      className={clsxm(
        "font-sans",
        !loadedIntoContainer ? "absolute h-full" : "h-full w-full",
      )}
    >
      <div
        className={!loadedIntoContainer ? `fixed` : "h-full w-full"}
        style={{
          zIndex: !loadedIntoContainer ? config.zIndex : undefined,
          bottom: !isMobile
            ? config.positionBottom
              ? config.positionBottom
              : "20px"
            : config.mobilePositionBottom
            ? config.mobilePositionBottom
            : "20px",
          right: !isMobile
            ? config.positionRight
              ? config.positionRight
              : config.positionLeft
              ? undefined
              : "20px"
            : config.mobilePositionRight
            ? config.mobilePositionRight
            : config.mobilePositionLeft
            ? undefined
            : "20px",
          left: !isMobile
            ? config.positionLeft
              ? config.positionLeft
              : undefined
            : config.mobilePositionLeft
            ? config.mobilePositionLeft
            : undefined,
        }}
      >
        {(containerMode ?? ui.displayChatWindow) && (
          <ChatWindow browserSessionId={browserSessionId} />
        )}
        {!loadedIntoContainer && (
          <>
            <AttentionGrabbers />
            <div
              className={clsxm(
                "mt-4 flex w-full flex-row justify-end",
                !isMobile
                  ? config.positionLeft
                    ? "justify-start"
                    : "justify-end"
                  : config.mobilePositionLeft
                  ? "justify-start"
                  : "justify-end",
              )}
            >
              <Launcher />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
