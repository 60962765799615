import { minutesToMilliseconds } from "date-fns";
import { Route } from "../..";
export function useReturns() {
  const { publicId, organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const {
    data: returns,
    isLoading,
    refetch,
  } = trpc.dashboard.conversations.returns.getReturns.useQuery(
    {
      publicId,
      organizationSlug,
    },
    { refetchInterval: minutesToMilliseconds(5) },
  );

  return { returns, isLoading, refetch };
}
