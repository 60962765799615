import { Accordion } from "@dashboard/common/ui/accordion";
import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { Route } from "../_config-sidebar";
import { Channels } from "./Channels";
import { AiAndAutomation } from "./AiAndAutomation";
import { HelpDesk } from "./HelpDesk";
import { ConfigurationAccordionItem } from "./ConfigurationAccordionItem";
import { Workspace } from "./Workspace";

export function ConfigurationAccordion() {
  const { businessSlug } = Route.useParams();

  return (
    <Accordion
      type="multiple"
      className="w-full"
      defaultValue={["workspace", "help-desk", "ai", "channels"]}
    >
      <Workspace />
      <HelpDesk />
      <AiAndAutomation />
      <Channels />

      {businessSlug === "woo-test" && (
        <ConfigurationAccordionItem value="integrations" title="Integrations">
          <SidebarButton
            text="WooCommerce"
            to="/organization/$organizationSlug/settings/$businessSlug/woocommerce"
          />
        </ConfigurationAccordionItem>
      )}
    </Accordion>
  );
}
