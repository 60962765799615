import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@dashboard/common/ui/button";
import SettingsTab from "./-components/SettingsTab";
import TopBar from "./-components/ChatWindow/TopBar";
import { Select } from "@dashboard/common/ui/select";
import Body from "./-components/ChatWindow/Body";
import MessageInput from "./-components/ChatWindow/MessageInput";
import Launcher from "./-components/Launcher/Launcher";
import ChatWindow from "./-components/ChatWindow/ChatWindow";
import { toast } from "sonner";
import DisclaimerModal from "./-components/ChatWindow/Disclaimer/DisclaimerModal";
import AttentionGrabbers from "./-components/AttentionGrabbers/AttentionGrabbers";
import {
  WebChatConfigForm,
  webChatConfigFormSchema,
} from "./-utils/formSchema";
import { nullsToUndefined } from "@dashboard/utils/objects";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import { useBanner } from "@dashboard/routes/organization/$organizationSlug/-hooks/useBanner";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/web-chat/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.webChat.getConfig.ensureData({
      businessSlug,
    });

    await trpcClientUtils.dashboard.bots.getOrThrow.ensureData({
      businessSlug,
    });
  },
  component: WebChatSettingsPage,
});

// Todo: make right gray area smaller (only px padding needed)

function WebChatSettingsPage() {
  const [selectedComponent, setSelectedComponent] = useState("chat-window");

  const { trpc, user } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [webChatConfig] = trpc.dashboard.webChat.getConfig.useSuspenseQuery({
    businessSlug,
  });

  const defaultValues = {
    ...nullsToUndefined(webChatConfig),
    topBarImageSrc: webChatConfig.topBarImageFileUrl ?? undefined,
    botIconSrc: webChatConfig.botIconFileUrl ?? undefined,
    launcherIconSrc: webChatConfig.launcherIconFileUrl ?? undefined,
    launcherImageSrc: webChatConfig.launcherImageFileUrl ?? undefined,
    // TODO: Add this value to the DB. Only have positionSide column instead of positionLeft and positionRight
    positionSide:
      webChatConfig.positionRight ?? webChatConfig.positionLeft ?? undefined,
    // TODO: Add this value to the DB. Only have selectedSide column instead of selectedLeft and selectedRight
    selectedSide: webChatConfig.positionLeft
      ? ("left" as const)
      : ("right" as const),
    greetingMessage: webChatConfig.greetings[0]?.content ?? undefined,
    deploymentHostname: webChatConfig.deployments[0]?.hostname ?? undefined,
    enableDeployment: webChatConfig.deployments[0]?.enableDeployment ?? false,
  };

  const formMethods = useForm<WebChatConfigForm>({
    resolver: zodResolver(webChatConfigFormSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    formMethods.reset(defaultValues);
  }, [businessSlug]);

  const enableAttentionGrabbers = formMethods.watch("enableAttentionGrabbers");

  const { onSubmit } = useSubmitForm();

  const onSubmissionError = (errors: unknown) => {
    console.error(errors);
    toast("Failed to update the web chat settings.");
  };

  const [isChatWindowOpen, setChatWindowOpen] = useState(true);

  const { height: bannerHeight, shouldShow: isBannerVisible } = useBanner();

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={(e) =>
          void formMethods.handleSubmit(onSubmit, onSubmissionError)(e)
        }
      >
        <div
          className="flex flex-col"
          style={{
            height: isBannerVisible ? `calc(100vh - ${bannerHeight})` : "100vh",
          }}
        >
          {/* top bar */}
          <div className="border-gray-light flex w-full flex-row justify-between border-b px-10 py-4">
            <h1 className="text-xl font-medium">Web Chat Settings</h1>

            <div className="flex flex-row space-x-2">
              {user.isAdmin && (
                <Button
                  type="button"
                  variant="secondary"
                  className="ml-auto"
                  onClick={() => {
                    console.log(formMethods.getValues());
                  }}
                >
                  Show Values
                </Button>
              )}
              <Button type="submit" variant="publish" className="ml-auto">
                {formMethods.formState.isSubmitting ? "Submitting..." : "Save"}
              </Button>
            </div>
          </div>
          <div className="flex flex-1 overflow-hidden">
            {/* left side */}
            <div className="flex w-1/2 flex-col">
              <div className="flex-1 overflow-y-auto px-10 py-5">
                <SettingsTab />
              </div>
            </div>
            {/* right side */}
            <div className="bg-gray-lightest flex w-1/2 flex-col">
              {/* Navbar */}
              <div className="border-gray-light top-0 z-10 w-full border-b px-4 py-2">
                <div className="flex flex-row space-x-5">
                  <Select
                    variant="dropdown"
                    value={selectedComponent}
                    onChange={(value) => setSelectedComponent(value)}
                    renderValue={(valueJsx) => <>{valueJsx}</>}
                    options={[
                      {
                        label: "Chat window",
                        value: "chat-window",
                      },
                      {
                        label: "Attention grabbers",
                        value: "attention-grabbers",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="relative flex grow items-center justify-center overflow-hidden pb-10">
                {selectedComponent === "chat-window" ? (
                  <>
                    {isChatWindowOpen && (
                      <ChatWindow>
                        <TopBar />
                        <Body />
                        <MessageInput />
                        <DisclaimerModal />
                      </ChatWindow>
                    )}
                  </>
                ) : (
                  <>{enableAttentionGrabbers && <AttentionGrabbers />}</>
                )}
                <Launcher
                  isChatWindowOpen={isChatWindowOpen}
                  onClick={() => setChatWindowOpen((v) => !v)}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
