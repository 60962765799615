import { CalendarRange } from "lucide-react";
import { FilterSelect } from "./FilterSelect";

export function TimeRange({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const options = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "This Week",
      value: "this-week",
    },
    {
      label: "This Month",
      value: "this-month",
    },
    {
      label: "Last 2 Days",
      value: "last-2-days",
    },
    {
      label: "Last 7 Days",
      value: "last-7-days",
    },
    {
      label: "Last 14 Days",
      value: "last-14-days",
    },
    {
      label: "Last 30 Days",
      value: "last-30-days",
    },
    {
      label: "Last 90 Days",
      value: "last-90-days",
    },
  ];

  return (
    <FilterSelect
      filterName="Time Range"
      valueIcon={CalendarRange}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
