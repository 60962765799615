import { useConversation } from "../../../-hooks/useConversation.hook";

export function CustomerTagsSection() {
  const conversation = useConversation();

  const { customerTags } = conversation;

  if (!customerTags) {
    return null;
  }

  return (
    <div>
      <div className="pl-7 pt-4 text-xs font-medium uppercase">
        Customer Tags
      </div>
      {customerTags.length > 0 && (
        <div className="mt-5 flex max-h-[150px] flex-wrap gap-2 overflow-y-auto px-7">
          {customerTags.map((tag) => (
            <div
              key={tag.key}
              className="flex rounded-full border px-3 py-1 text-sm font-medium"
            >
              {`${tag.key}: ${tag.value}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
