import { Ban, Truck, UserRound, PackageSearch, Package } from "lucide-react";

import { Route } from "../..";
import { Flow } from "./Flow";

const getFlowStatusLabel = (status: string) => {
  switch (status) {
    case "notAllowed":
      return "Not allowed";
    case "handOff":
      return "Hand off to resolve manually";
    case "holdAndHandOff":
      return "Put on hold and hand off to resolve manually";
    case "automatic":
      return "Process automatically";
    case "automaticNoEmail":
      return "Process automatically";
    default:
      return "";
  }
};

export function FlowGrid({ businessSlug }: { businessSlug: string }) {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [flowConfig] =
    trpc.dashboard.flows.getSimpleFlowConfiguration.useSuspenseQuery({
      businessSlug,
    });

  return (
    <div className="rounded-lg border bg-white p-4">
      <h3 className="text-lg font-medium text-gray-900">
        Customer Support Flows
      </h3>
      <div className="mt-2">
        <div className="grid grid-cols-2 gap-4">
          <Flow
            organizationSlug={organizationSlug}
            businessSlug={businessSlug}
            flowName="Order Tracking"
            flowSlug="orderTracking"
            flowOption={getFlowStatusLabel("automatic")}
            icon={PackageSearch}
            description={
              <div className="bg-card divide-y">
                <div className="p-4">
                  <h3 className="font-medium">Order Tracking Process</h3>
                  <p className="text-muted-foreground mt-1 text-sm">
                    How the Chatbot handles order tracking requests.
                  </p>
                </div>
                <div className="text-muted-foreground flex items-center gap-2 p-4 text-sm">
                  <span className="text-primary">→</span>
                  Click the card to see the available options in detail and
                  configure the flow.
                </div>
              </div>
            }
          />

          <Flow
            organizationSlug={organizationSlug}
            businessSlug={businessSlug}
            flowName="Order Cancellations"
            flowSlug="orderCancelation"
            flowOption={getFlowStatusLabel(flowConfig.orderCancelation)}
            icon={Ban}
            description={
              <div className="bg-card divide-y">
                <div className="p-4">
                  <h3 className="font-medium">Order Cancellation Process</h3>
                  <p className="text-muted-foreground mt-1 text-sm">
                    How the Chatbot handles order cancellation requests.
                  </p>
                </div>
                <div className="text-muted-foreground flex items-center gap-2 p-4 text-sm">
                  <span className="text-primary">→</span>
                  Click the card to see the available options in detail and
                  configure the flow.
                </div>
              </div>
            }
          />

          <Flow
            organizationSlug={organizationSlug}
            businessSlug={businessSlug}
            flowName="Shipping Address Updates"
            flowSlug="shippingAddressUpdate"
            flowOption={getFlowStatusLabel(flowConfig.shippingAddressUpdate)}
            icon={Truck}
            description={
              <div className="bg-card divide-y">
                <div className="p-4">
                  <h3 className="font-medium">
                    Shipping Address Updates Process
                  </h3>
                  <p className="text-muted-foreground mt-1 text-sm">
                    How the Chatbot handles shipping address updates.
                  </p>
                </div>
                <div className="text-muted-foreground flex items-center gap-2 p-4 text-sm">
                  <span className="text-primary">→</span>
                  Click the card to see the available options in detail and
                  configure the flow.
                </div>
              </div>
            }
          />

          <Flow
            organizationSlug={organizationSlug}
            businessSlug={businessSlug}
            flowName="Contact Details Updates"
            flowSlug="contactDetailsUpdate"
            flowOption={getFlowStatusLabel(flowConfig.contactDetailsUpdate)}
            icon={UserRound}
            description={
              <div className="bg-card divide-y">
                <div className="p-4">
                  <h3 className="font-medium">
                    Contact Details Updates Process
                  </h3>
                  <p className="text-muted-foreground mt-1 text-sm">
                    How the Chatbot handles contact details updates.
                  </p>
                </div>
                <div className="text-muted-foreground flex items-center gap-2 p-4 text-sm">
                  <span className="text-primary">→</span>
                  Click the card to see the available options in detail and
                  configure the flow.
                </div>
              </div>
            }
          />

          <Flow
            organizationSlug={organizationSlug}
            businessSlug={businessSlug}
            flowName="Order Item Updates"
            flowSlug="orderItemsUpdate"
            flowOption={getFlowStatusLabel(flowConfig.orderItemsUpdate)}
            icon={Package}
            description={
              <div className="bg-card divide-y">
                <div className="p-4">
                  <h3 className="font-medium">Order Item Updates Process</h3>
                  <p className="text-muted-foreground mt-1 text-sm">
                    How the Chatbot handles order item updates.
                  </p>
                </div>
                <div className="text-muted-foreground flex items-center gap-2 p-4 text-sm">
                  <span className="text-primary">→</span>
                  Click the card to see the available options in detail and
                  configure the flow.
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
