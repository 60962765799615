import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  Check,
  Heart,
  ClipboardList,
  Briefcase,
  Smile,
  Scale,
} from "lucide-react";
import { Button } from "@dashboard/common/ui/button";
import { useUpdateOnboardingStatus } from "../-hooks/useUpdateOnboardingStatus";
import { useOrganization } from "../-hooks/useOrganization";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useSelectBotTone } from "./-hooks/useSelectBotTone";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/tone/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.bots.getOrThrow.ensureData({
      businessSlug: organizationSlug,
    });
  },
  component: TonePage,
});

function TonePage() {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const organization = useOrganization();
  const navigate = useNavigate();

  const stepCompleted = organization.onboardingStatus !== "TONE";

  const [bot] = trpc.dashboard.bots.getOrThrow.useSuspenseQuery({
    businessSlug: organizationSlug,
  });

  const { update } = useUpdateOnboardingStatus({
    onSuccess: () => {
      void navigate({
        to: "/organization/$organizationSlug/onboarding/help-desk",
        params: { organizationSlug },
      });
    },
  });

  const { updateTone } = useSelectBotTone({
    onSuccess: () => {
      if (!stepCompleted) {
        update("HELP-DESK");
      }
    },
  });

  const options = [
    {
      name: "friendly",
      label: "Friendly",
      description: "Warm and welcoming, making customers feel appreciated",
      icon: Heart,
      onClick: () => {
        updateTone({ tone: "friendly" });
      },
    },
    {
      name: "matter-of-fact",
      label: "Matter of Fact",
      description: "Direct and factual, focusing on clear information",
      icon: ClipboardList,
      onClick: () => {
        updateTone({ tone: "matter-of-fact" });
      },
    },
    {
      name: "professional",
      label: "Professional",
      description: "Courteous and maintaining business etiquette",
      icon: Briefcase,
      onClick: () => {
        updateTone({ tone: "professional" });
      },
    },
    {
      name: "humorous",
      label: "Humorous",
      description: "Light and cheerful while remaining professional",
      icon: Smile,
      onClick: () => {
        updateTone({ tone: "humorous" });
      },
    },
    {
      name: "neutral",
      label: "Neutral",
      description: "Balanced, neither too casual nor too formal",
      icon: Scale,
      onClick: () => {
        updateTone({ tone: "neutral" });
      },
    },
  ];

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Select your AI assistant&apos;s tone
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Choose how you want your AI assistant to communicate with customers.
      </h2>
      <div className="flex w-full max-w-3xl flex-col gap-4 py-8">
        {options.map((option) => (
          <Button
            key={option.name}
            onClick={option.onClick}
            variant="outline"
            className="h-auto w-full justify-between border-[#041627] px-3 py-2 font-semibold"
          >
            <div className="flex items-center gap-3">
              <option.icon className="h-5 w-5" strokeWidth={1.5} />
              <div className="flex flex-col items-start text-left">
                <span>{option.label}</span>
                <span className="font-normal text-gray-600">
                  {option.description}
                </span>
              </div>
            </div>
            {option.name === bot.tone && (
              <Check className="h-6 w-6" strokeWidth={1.5} />
            )}
          </Button>
        ))}
        <p className="text-center text-sm text-gray-600">
          You can change this later in the settings.
        </p>

        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/platform"
          nextPath="/organization/$organizationSlug/onboarding/help-desk"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}
