import { Mail, Check } from "lucide-react";
import { SiGmail } from "react-icons/si";
import { PiMicrosoftOutlookLogo } from "react-icons/pi";
import { Link } from "@tanstack/react-router";
import { cn } from "@dashboard/utils/ui";
import { Button } from "@dashboard/common/ui/button";
import { Route } from "../..";
import { useCompleteOnboardingStep } from "../../-hooks/useCompleteOnboardingStep";

interface SetupChannelsProps {
  organizationSlug: string;
  businessSlug: string;
  completed: boolean;
}

export function SetupEmail({
  businessSlug,
  organizationSlug,
  completed,
}: SetupChannelsProps) {
  const { trpc } = Route.useRouteContext();

  const [installedIntegrations] =
    trpc.dashboard.integrations.getInstalledIntegrations.useSuspenseQuery({
      businessSlug,
    });

  const completeStep = useCompleteOnboardingStep();

  const channels = [
    {
      name: "SMTP/IMAP",
      link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=smtpImap",
      icon: Mail,
      description: "Connect an email account via SMTP/IMAP.",
      installed: installedIntegrations.includes("smtpImap"),
    },
    {
      name: "Outlook",
      link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=outlook",
      icon: PiMicrosoftOutlookLogo,
      description: "Connect an Outlook email account.",
      installed: installedIntegrations.includes("outlook"),
    },
    {
      name: "Gmail",
      link: "/organization/$organizationSlug/settings/$businessSlug/integrations?dialog=google",
      icon: SiGmail,
      description: "Connect a Gmail email account.",
      installed: installedIntegrations.includes("google"),
    },
  ];

  return (
    <div className="space-y-6">
      <div className="prose prose-sm max-w-none">
        <p className="text-black">
          Connect your email to enable automated responses from your bot and
          allow your team to respond to handed-off support conversations through
          email. Choose from Gmail, Outlook, or any email provider via
          SMTP/IMAP.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        {channels.map((channel) => (
          <Link
            key={channel.name}
            to={channel.link}
            params={{
              organizationSlug,
              businessSlug,
            }}
            className={cn(
              "border-blue-light hover:bg-blue-light hover:border-blue flex cursor-pointer flex-col items-start justify-start gap-2 rounded-lg border p-4 transition-all duration-200 ease-in-out hover:shadow-lg",
              {
                "bg-blue-light": channel.installed,
                "bg-white": !channel.installed,
              },
            )}
          >
            <div className="flex w-full items-center justify-between">
              <div
                className={cn("bg-primary/10 rounded-md p-2", {
                  "bg-blue text-white": channel.installed,
                })}
              >
                <channel.icon className="h-5 w-5" strokeWidth={1.5} />
              </div>
              {channel.installed && (
                <div className="bg-blue flex items-center gap-1 rounded-lg px-2 py-1 text-sm text-white">
                  <Check className="h-4 w-4" />
                  Installed
                </div>
              )}
            </div>
            <h3 className="text-lg font-medium text-gray-800">
              {channel.name}
            </h3>
            <p className="text-left text-gray-600">{channel.description}</p>
          </Link>
        ))}
      </div>

      {!completed ? (
        <Button
          onClick={() => completeStep({ step: "channels" })}
          variant="secondary"
          className="w-full"
        >
          Complete
        </Button>
      ) : (
        <Button className="w-full">
          <Check className="mr-2 h-4 w-4" />
          Completed
        </Button>
      )}
    </div>
  );
}
