import { toast } from "sonner";
import { CustomerTagForm } from "../-utils/tagFormSchema";
import { Route } from "../new";

export function useCreateTag() {
  const navigate = Route.useNavigate();
  const { organizationSlug, businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  async function navigateToTags() {
    await navigate({
      to: "/organization/$organizationSlug/settings/$businessSlug/customer-tags",
      params: { organizationSlug, businessSlug },
    });
  }

  const upsertMutation =
    trpc.dashboard.customers.upsertTagKeysAndValues.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.customers.getTagKeysAndValues.invalidate({
          businessSlug,
        });

        void navigateToTags();
        toast.success("Successfully saved tag.");
      },
      onError: () => {
        toast.error("Failed to save tag. Please try again.");
      },
    });

  return {
    onSubmit: (data: CustomerTagForm) => {
      const values = data.values.split(",").map((value) => value.trim());

      const uniqueValues = [...new Set(values)];

      upsertMutation.mutate({
        businessSlug,
        keys: [
          {
            key: data.key,
            values: uniqueValues,
            llmInstructions: data.llmInstructions,
          },
        ],
      });
    },

    onError: (errors: unknown) => {
      console.error(errors);
      toast.error("Failed to save tag.");
    },
  };
}
