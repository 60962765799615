import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@dashboard/common/ui/dialog";

interface ContextElementDialogProps {
  title: string;
  content: string;
}

export function ContextElementDialog({
  title,
  content,
}: ContextElementDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="link" className="p-0">
          {title}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <MarkdownContent content={content} />
      </DialogContent>
    </Dialog>
  );
}
