import { createFileRoute } from "@tanstack/react-router";
import zendeskLogo from "@dashboard/assets/zendesk.svg";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { env } from "@dashboard/env";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useOrganization } from "../-hooks/useOrganization";
import { CircleHelp } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";

const formSchema = z.object({
  subdomain: z
    .string({
      required_error: "Subdomain is required",
      invalid_type_error: "Subdomain is required",
    })
    .min(1, "Subdomain is required"),
});

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/help-desk/zendesk",
)({
  component: ZendeskPage,
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.integrations.getIntegration.ensureData({
      integrationServiceName: "zendesk",
      businessSlug: organizationSlug,
    });
  },
});

function ZendeskPage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [integration] =
    trpc.dashboard.integrations.getIntegration.useSuspenseQuery({
      integrationServiceName: "zendesk",
      businessSlug: organizationSlug,
    });

  const parsedIntegration = z
    .object({
      subdomain: z.string(),
    })
    .nullable()
    .parse(integration);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      subdomain: parsedIntegration?.subdomain ?? "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    window.location.href = `${env.DASHBOARD_BASE_API_URL}/api/zendesk/generate-oauth-link?customerSubdomain=${values.subdomain}`;
  };

  const organization = useOrganization();
  const stepCompleted = organization.onboardingStatus !== "HELP-DESK";

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Connect Zendesk
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        When the AI cannot resolve a ticket, it will automatically forward it to
        your Zendesk help desk.
      </h2>
      <div className="w-full max-w-3xl py-8">
        <Form {...form}>
          <form
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="subdomain"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="h-10 border-[#041627]"
                        placeholder="Enter your Zendesk subdomain"
                        {...field}
                      />
                      <HoverCard>
                        <HoverCardTrigger asChild>
                          <CircleHelp
                            className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
                            strokeWidth={1.5}
                          />
                        </HoverCardTrigger>
                        <HoverCardContent className="w-[450px]" side="top">
                          <div className="space-y-4 text-sm leading-relaxed text-gray-800">
                            <h4 className="font-medium">
                              Finding Your Subdomain
                            </h4>
                            <p>
                              Your Zendesk subdomain is a part of your dashboard
                              URL:
                            </p>

                            <div className="relative rounded-lg border border-gray-200 bg-gray-50 p-3">
                              <div className="flex items-center gap-0 font-mono text-gray-600">
                                <span>https://</span>
                                <span className="rounded bg-blue-100 px-2 py-1 font-semibold text-blue-700">
                                  my-company
                                </span>
                                <span>.zendesk.com</span>
                              </div>
                              <div className="mt-3 flex items-center gap-2 text-sm text-gray-600">
                                <span className="font-medium">Subdomain:</span>
                                <code className="rounded bg-blue-100 px-2 py-1 font-mono font-semibold text-blue-700">
                                  my-company
                                </code>
                              </div>
                            </div>

                            <p className="text-xs text-gray-500">
                              Copy the highlighted part from your Zendesk URL
                              and paste it in the input field
                            </p>
                          </div>
                        </HoverCardContent>
                      </HoverCard>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant="outline"
              className="h-10 w-full justify-start border-[#041627]"
            >
              <img
                src={zendeskLogo}
                alt="Zendesk Logo"
                className="mr-4 h-6 w-6"
              />
              Connect Zendesk
            </Button>
          </form>
        </Form>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/help-desk"
          nextPath="/organization/$organizationSlug/onboarding/knowledge"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}
