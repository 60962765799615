import { env } from "@dashboard/env";
import { createFileRoute } from "@tanstack/react-router";
import googleLoginButton from "@dashboard/assets/google-login-button.png";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/fq-google-my-business",
)({
  component: FQGoogleMyBusinessIntegration,
});

function FQGoogleMyBusinessIntegration() {
  const url = new URL("https://accounts.google.com/o/oauth2/v2/auth");

  const params = new URLSearchParams({
    client_id:
      "466125500455-2i9117vir42bqhruuj76ce2el3opl2d8.apps.googleusercontent.com",
    redirect_uri: `${env.DASHBOARD_BASE_API_URL}/floraqueen/reviews/callback`,
    response_type: "code",
    scope: "https://www.googleapis.com/auth/business.manage",
    access_type: "offline",
    prompt: "consent",
  });

  url.search = params.toString();

  return (
    <div className="flex w-full justify-center p-4">
      <img
        className="w-48 max-w-48 cursor-pointer"
        src={googleLoginButton}
        onClick={() => {
          window.location.href = url.toString();
        }}
      />
    </div>
  );
}
