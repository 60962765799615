import { createFileRoute } from "@tanstack/react-router";
import ConnectContainer from "./-components/ConnectContainer";

export const Route = createFileRoute("/connect/fail")({
  component: ConnectFailure,
});

function ConnectFailure() {
  return (
    <ConnectContainer>
      <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
        <h1 className="pb-3 text-center text-2xl font-medium leading-8">
          Something went wrong.
        </h1>
        <h2 className="text-center leading-6 text-gray-800">
          Please try again later.
          <br />
          If the problem persists,{" "}
          <a
            href="mailto:info@octocom.ai"
            className="text-blue-600 hover:underline"
          >
            please contact us
          </a>
          .
        </h2>
      </div>
    </ConnectContainer>
  );
}
