import { TableCell, TableRow } from "@dashboard/common/ui/table";
import { ChevronDown, ChevronUp } from "lucide-react";
import { cn } from "@dashboard/utils/ui";
import { getStatusColor } from "../-utils/utils";
import { TrackedOrder } from "@be/modules/orders/orders.types";
import {
  formatDistanceToNow,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";

interface OrderRowProps {
  order: TrackedOrder;
  expandedRows: Set<string>;
  toggleRow: (orderId: string) => void;
  updatedAt: string | undefined;
}

export function OrderRow({
  order,
  expandedRows,
  toggleRow,
  updatedAt,
}: OrderRowProps) {
  return (
    <>
      <TableRow
        key={order.orderId}
        className={cn(
          "cursor-pointer transition-colors",
          "hover:bg-gray-50",
          expandedRows.has(order.orderId) && "bg-gray-50",
        )}
        onClick={() => toggleRow(order.orderId)}
      >
        <TableCell className="py-2">
          <div className="flex items-center gap-2">
            <div
              className={cn(
                "rounded-full p-1 transition-colors",
                expandedRows.has(order.orderId)
                  ? "bg-primary/10"
                  : "bg-gray-100",
              )}
            >
              {expandedRows.has(order.orderId) ? (
                <ChevronUp size={14} className="text-primary" />
              ) : (
                <ChevronDown size={14} className="text-gray-500" />
              )}
            </div>
            <span className="font-medium text-gray-900">{order.orderId}</span>
            {updatedAt &&
              differenceInHours(new Date(), new Date(updatedAt)) < 8 && (
                <span
                  className={cn(
                    "text-sm",
                    differenceInMinutes(new Date(), new Date(updatedAt)) < 5
                      ? "text-green-600"
                      : "text-gray-500",
                  )}
                >
                  • updated {formatDistanceToNow(new Date(updatedAt))} ago
                </span>
              )}
          </div>
        </TableCell>
        <TableCell className="py-2 text-gray-600">
          {order.customerData?.name ?? "-"}
        </TableCell>
        <TableCell className="py-2">
          <div
            className={cn(
              "inline-flex rounded-full px-3 py-1 text-xs font-medium",
              getStatusColor(order.fulfillments[0]?.status),
            )}
          >
            {order.fulfillments[0]?.status
              ? order.fulfillments[0].status === "PENDING"
                ? "Unfulfilled"
                : order.fulfillments[0].status
                    .replace(/_/g, " ")
                    .toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase())
              : "-"}
          </div>
        </TableCell>
        <TableCell className="py-2 text-gray-600">
          {order.fulfillments[0]?.items?.length ?? "0"}
        </TableCell>
        <TableCell className="py-2 text-gray-600">
          {order.fulfillments[0]?.courier ?? "-"}
        </TableCell>
      </TableRow>
      {expandedRows.has(order.orderId) && <OrderDetails order={order} />}
    </>
  );
}

function OrderDetails({ order }: { order: TrackedOrder }) {
  return (
    <TableRow>
      <TableCell colSpan={5} className="bg-gray-50/50 px-6 py-4">
        <div className="overflow-hidden rounded-lg border border-gray-100 bg-white shadow-sm">
          {/* Header */}
          <div className="border-b border-gray-100 bg-gray-50/50 px-6 py-4">
            <h3 className="text-lg font-medium text-gray-900">Order Details</h3>
          </div>

          {/* Content */}
          <div className="grid grid-cols-2 gap-6 p-6">
            {/* Left Column - Fulfillments */}
            <div className="rounded-lg border border-gray-100 bg-gray-50/50 p-6">
              <h4 className="mb-6 text-base font-medium text-gray-900">
                Fulfillments
              </h4>

              <div className="space-y-4">
                {order.fulfillments.map((fulfillment, fulfillmentIndex) => (
                  <div
                    key={fulfillmentIndex}
                    className="rounded-lg border border-gray-100 bg-white p-4"
                  >
                    <div className="mb-4 flex items-center justify-between border-b border-gray-100 pb-4">
                      <div
                        className={cn(
                          "inline-flex rounded-full px-3 py-1 text-sm font-medium",
                          getStatusColor(fulfillment.status),
                        )}
                      >
                        {fulfillment.status
                          ? fulfillment.status
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .replace(/^\w/, (c) => c.toUpperCase())
                          : "-"}
                      </div>
                      <span className="text-sm font-medium text-gray-600">
                        {fulfillment.courier ?? "Courier -"}
                      </span>
                    </div>

                    {fulfillment.items && (
                      <div className="space-y-4">
                        {fulfillment.items.map((item, itemIndex) => (
                          <div
                            key={itemIndex}
                            className="flex items-start gap-4 rounded-md border border-gray-100 bg-gray-50/50 p-4"
                          >
                            {item.image ? (
                              <img
                                src={item.image}
                                alt={item.name}
                                className="h-24 w-24 rounded-md border border-gray-100 object-cover"
                              />
                            ) : (
                              <div className="h-24 w-24 rounded-md bg-gray-100" />
                            )}
                            <div className="flex flex-col gap-2">
                              <p className="text-base font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="text-sm text-gray-600">
                                Quantity: {item.quantity}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Right Column - Customer Details */}
            <div className="rounded-lg border border-gray-100 bg-gray-50/50 p-6">
              <h4 className="mb-6 text-base font-medium text-gray-900">
                Customer Information
              </h4>

              <div className="space-y-6">
                {/* Shipping Address */}
                <div className="rounded-lg border border-gray-100 bg-white p-4">
                  <h5 className="mb-3 text-sm font-medium text-gray-700">
                    Shipping Address
                  </h5>
                  <p className="whitespace-pre-line text-base leading-relaxed text-gray-600">
                    {Object.values(order.recipient?.address ?? {}).some(
                      (value) => value,
                    )
                      ? [
                          order.recipient?.address?.line1,
                          order.recipient?.address?.line2,
                          order.recipient?.address?.city,
                          order.recipient?.address?.province,
                          order.recipient?.address?.zip,
                          order.recipient?.address?.country,
                        ]
                          .filter(Boolean)
                          .join("\n")
                      : "Address -"}
                  </p>
                </div>

                {/* Contact Info */}
                <div className="rounded-lg border border-gray-100 bg-white p-4">
                  <h5 className="mb-3 text-sm font-medium text-gray-700">
                    Contact Details
                  </h5>
                  <p className="text-base text-gray-600">
                    {order.customerData?.email ?? "Email -"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
