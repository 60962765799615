import { Table } from "@dashboard/common/Table/Table";
import { Button } from "@dashboard/common/ui/button";
import { Link, createFileRoute } from "@tanstack/react-router";
import { Plus } from "lucide-react";
import { FlowLink } from "./-components/FlowLink";
import { Live } from "./-components/Live";
import { Draft } from "./-components/Draft";
import { useDeleteFlows } from "./-hooks/useDeleteFlows";
import { useExportWorkflows } from "./-hooks/useExportWorkflows";
import { useImportWorkflows } from "./-hooks/useImportWorkflows";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/advanced-flows/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await Promise.all([
      trpcClientUtils.dashboard.flows.getAll.ensureData({
        businessSlug,
      }),
      trpcClientUtils.dashboard.threats.getAll.ensureData({
        businessSlug,
      }),
    ]);
  },

  component: FlowsPage,
});

function FlowsPage() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { trpc, business } = Route.useRouteContext();

  const [flows] = trpc.dashboard.flows.getAll.useSuspenseQuery({
    businessSlug,
  });

  const records = flows.map((flow) => ({
    id: flow.id,
    titleLink: <FlowLink title={flow.title} flowId={flow.id} />,
    title: flow.title,
    isLive: flow.isLive ? <Live /> : <Draft />,
    priority: flow.priority,
  }));

  records.sort((a, b) => {
    const priorityDiff = b.priority - a.priority;
    if (priorityDiff !== 0) return priorityDiff;
    return a.title.localeCompare(b.title);
  });

  const { deleteFlows } = useDeleteFlows();
  const { exportWorkflows } = useExportWorkflows();
  const { importWorkflows } = useImportWorkflows();

  return (
    <div className="relative py-0">
      <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
        <h1 className="text-xl font-medium text-black">
          Advanced Flows
          <span className="text-gray"> {`for ${business.name}`}</span>
        </h1>
        <div className="flex items-center gap-2">
          <Button type="button" variant="secondary" onClick={importWorkflows}>
            Import workflows
          </Button>
          <Link
            to="/organization/$organizationSlug/settings/$businessSlug/advanced-flows/templates"
            params={{ organizationSlug, businessSlug }}
          >
            <Button type="button" variant="secondary">
              Manage templates
            </Button>
          </Link>
          <Link
            to="/organization/$organizationSlug/settings/$businessSlug/advanced-flows/new"
            params={{ organizationSlug, businessSlug }}
          >
            <Button
              className="flex items-center justify-between gap-2"
              type="button"
            >
              <Plus className="h-4 w-4" strokeWidth={3} />
              Create a new flow
            </Button>
          </Link>
        </div>
      </div>
      <Table
        records={records}
        columns={[
          {
            header: "Title",
            accessor: "titleLink",
          },
          {
            header: "Priority",
            accessor: "priority",
          },
          {
            header: "Status",
            accessor: "isLive",
          },
        ]}
        recordName={{ singular: "Flow", plural: "Flows" }}
        deleteRecords={deleteFlows}
        headerActions={[
          {
            label: "Export",
            action: exportWorkflows,
          },
        ]}
        contextActions={[
          {
            label: "Export",
            action: exportWorkflows,
          },
        ]}
        noRecordsText="No flows available"
      />
    </div>
  );
}
