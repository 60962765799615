import { useNavigate } from "@tanstack/react-router";
import { Route } from "../selection";

export function useConnectSelection({
  onDataSuccess,
}: {
  onDataSuccess: (data: unknown) => void;
}) {
  const { integrationServiceName, integrationData } = Route.useSearch();
  const { trpc } = Route.useRouteContext();
  const navigate = useNavigate();

  const connectMutation = trpc.dashboard.integrations.install.useMutation({
    onSuccess: (data) => {
      onDataSuccess(data);
    },
    onError: () => {
      void navigate({ to: "/connect/fail" });
    },
  });

  const connect = ({
    selectedBusinessSlug,
  }: {
    selectedBusinessSlug: string;
  }) => {
    connectMutation.mutate({
      integrationServiceName,
      integrationData,
      businessSlug: selectedBusinessSlug,
    });
  };

  return connect;
}
