import { Route } from "..";

export function useGetKnowledgeSummary() {
  const { trpc, business } = Route.useRouteContext();

  const [summary] = trpc.dashboard.bots.getKnowledgeSummary.useSuspenseQuery(
    {
      businessSlug: business.slug,
    },
    {
      refetchInterval: 30000,
    },
  );

  const totalQuestionCount = Object.values(summary.knowledgeSummary).reduce(
    (acc, summary) => acc + summary.questions.length,
    0,
  );

  const totalAnsweredQuestionCount = Object.values(
    summary.knowledgeSummary,
  ).reduce(
    (acc, summary) =>
      acc + summary.questions.filter((q) => q.isAnswered).length,
    0,
  );

  return {
    ...summary,
    totalQuestionCount,
    totalAnsweredQuestionCount,
  };
}
