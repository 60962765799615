import { toast } from "sonner";
import { Route } from "../../setup";

export function useCompleteSetup() {
  const { trpc } = Route.useRouteContext();

  const { organizationSlug } = Route.useParams();

  const undoMutation = trpc.dashboard.business.updateOrganization.useMutation();

  let redirectTimeoutId: NodeJS.Timeout;

  const updateMutation = trpc.dashboard.business.updateOrganization.useMutation(
    {
      onSuccess: () => {
        toast.success("Setup completed", {
          position: "bottom-right",
          action: {
            label: "Undo",
            onClick: () => {
              clearTimeout(redirectTimeoutId);
              undoMutation.mutate({
                onboardingStatus: "SETUP",
                organizationSlug,
              });
            },
          },
        });

        redirectTimeoutId = setTimeout(() => {
          window.location.href = `/organization/${organizationSlug}/conversation`;
        }, 5000);
      },
    },
  );

  return {
    complete: () => {
      updateMutation.mutate({
        onboardingStatus: "COMPLETED",
        organizationSlug,
      });
    },
  };
}
