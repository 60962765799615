import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import ConnectContainer from "./-components/ConnectContainer";
import { Button } from "@dashboard/common/ui/button";
import { useConnectSelection } from "./-hooks/useConnectSelection";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { integrationServiceNameSchema } from "@be/modules/integrations/integrations.schemas";
import { Select } from "@dashboard/common/ui/select";

export const Route = createFileRoute("/connect/selection")({
  validateSearch: z.object({
    integrationData: z.unknown(),
    integrationServiceName: integrationServiceNameSchema,
  }),
  component: ConnectSelection,
});

function ConnectSelection() {
  const { trpc } = Route.useRouteContext();
  const { integrationServiceName } = Route.useSearch();
  const navigate = useNavigate();

  const [user] = trpc.dashboard.users.getCurrent.useSuspenseQuery();

  const [selectedOrganizationSlug, setSelectedOrganizationSlug] = useState(
    user.organizations[0].slug,
  );

  const selectedOrganization = user.organizations.find(
    (o) => o.slug === selectedOrganizationSlug,
  );

  const onboardingCompleted =
    selectedOrganization?.onboardingStatus === "COMPLETED";

  const [businesses] = trpc.dashboard.business.getAll.useSuspenseQuery({
    organizationSlug: selectedOrganizationSlug,
  });

  const [selectedBusinessSlug, setSelectedBusinessSlug] = useState(
    businesses[0].slug,
  );

  const handleOnSuccess = (data: unknown) => {
    if (integrationServiceName === "shopify" && onboardingCompleted) {
      window.location.href = data as string;
    } else {
      void navigate({ to: "/connect/success" });
    }
  };

  const connect = useConnectSelection({
    onDataSuccess: handleOnSuccess,
  });

  useEffect(() => {
    setSelectedBusinessSlug(businesses[0].slug);
  }, [businesses]);

  const handleConnect = () => {
    connect({ selectedBusinessSlug });
  };

  const organizationOptions = user.organizations.map((o) => ({
    label: o.name,
    value: o.slug,
  }));

  const businessOptions = businesses.map((b) => ({
    label: b.name,
    value: b.slug,
  }));

  return (
    <ConnectContainer>
      <div className="text-blue-dark flex h-full w-full flex-col items-center justify-center">
        <h1 className="pb-3 text-center text-2xl font-medium leading-8">
          Connect Integration
        </h1>
        <h2 className="text-center leading-6 text-gray-800">
          Choose which business you want to connect with{" "}
          {integrationServiceName.charAt(0).toUpperCase() +
            integrationServiceName.slice(1)}
        </h2>

        <div className="flex w-full max-w-xl flex-col gap-4 py-8">
          {user.isAdmin && (
            <div className="flex flex-col gap-2 rounded-lg border border-gray-200 px-3 py-2">
              <div className="px-3 pt-2 text-sm font-medium">Organization</div>
              <Select
                options={organizationOptions}
                value={selectedOrganizationSlug}
                onChange={(value) => {
                  setSelectedOrganizationSlug(value);
                }}
              />
            </div>
          )}
          <div className="flex flex-col gap-2 rounded-lg border border-gray-200 px-3 py-2">
            <div className="px-3 pt-2 text-sm font-medium">Business</div>
            <Select
              options={businessOptions}
              value={selectedBusinessSlug}
              onChange={(value) => {
                setSelectedBusinessSlug(value);
              }}
            />
          </div>
          <Button onClick={handleConnect} className="bg-blue-dark h-9 w-full">
            Connect
          </Button>
        </div>
      </div>
    </ConnectContainer>
  );
}
