import { useState, useMemo } from "react";
import { Table, TableBody, TableHeader } from "@dashboard/common/ui/table";
import { StatusFilterTabs } from "./StatusFilterTabs";
import { TableHeaderContent } from "./TableHeaderContent";
import { OrderRow } from "./OrderRow";
import { TrackedOrder } from "@be/modules/orders/orders.types";

interface OrderStatusTableProps {
  orders: TrackedOrder[];
}

export function OrderStatusTable({ orders }: OrderStatusTableProps) {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [statusFilter, setStatusFilter] = useState<string>("all");

  const orderUpdatedDates = useMemo(
    () =>
      orders.reduce<Record<string, string | undefined>>((acc, order) => {
        acc[order.orderId] = order.updatedAt?.toString();
        return acc;
      }, {}),
    [orders],
  );

  const uniqueStatuses = Array.from(
    new Set(
      orders
        .map((order) => order.fulfillments[0]?.status)
        .filter((status): status is string => typeof status === "string")
        .map((status) =>
          status
            .replace(/_/g, " ")
            .toLowerCase()
            .replace(/^\w/, (c) => c.toUpperCase()),
        ),
    ),
  );

  const statusOptions = [
    { label: "All", value: "all" },
    ...uniqueStatuses.map((status) => ({
      label: status,
      value: status.toLowerCase(),
    })),
  ];

  const filteredOrders = orders.filter((order) => {
    if (statusFilter === "all") return true;
    const orderStatus = order.fulfillments[0]?.status
      ?.replace(/_/g, " ")
      .toLowerCase();
    return orderStatus === statusFilter;
  });

  const toggleRow = (orderId: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(orderId)) {
        newSet.delete(orderId);
      } else {
        newSet.add(orderId);
      }
      return newSet;
    });
  };

  return (
    <>
      <StatusFilterTabs
        statusOptions={statusOptions}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      <Table className="mt-0 overflow-hidden border-gray-200 shadow-sm">
        <TableHeader>
          <TableHeaderContent />
        </TableHeader>
        <TableBody>
          {filteredOrders.map((order) => (
            <OrderRow
              key={order.orderId}
              order={order}
              expandedRows={expandedRows}
              toggleRow={toggleRow}
              updatedAt={orderUpdatedDates[order.orderId]}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}
