// Increment at which the overage fee is applied
// e.g. OVERAGE_CONVERSATION_INCREMENT = 50
// OVERAGE_FEE = 10
// Fee of 10 USD is applied for every 50 conversations over the limit
// 50 conversations = 0 USD
// 51 conversations = 10 USD
// 101 conversations = 20 USD
export const OVERAGE_CONVERSATION_INCREMENT = 50;

// Basic Plan
export const BASIC_PLAN_PRICE = "200";
export const BASIC_PLAN_OVERAGE_FEE = "15";
export const MAX_BASIC_CONVOS = 350;

// Advanced Plan
export const ADVANCED_PLAN_PRICE = "300";
export const ADVANCED_PLAN_OVERAGE_FEE = "36";
export const MAX_ADVANCED_CONVOS = 1200;

// Enterprise Plan
export const ENTERPRISE_PLAN_PRICE = "800";
export const ENTERPRISE_PLAN_OVERAGE_FEE = "30";
export const MAX_ENTERPRISE_CONVOS = 3200;

export const TRIAL_DAYS = 14;

export const BASIC_PLAN = {
  id: "Basic",
  name: "Basic",
  price: BASIC_PLAN_PRICE,
  maxConversations: MAX_BASIC_CONVOS,
  overageFee: BASIC_PLAN_OVERAGE_FEE,
};
