import { Route } from "..";
import { toast } from "sonner";
import { TemplateForm } from "../-utils/templateFormSchema";

export const useCreateTemplate = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const creationMutation = trpc.dashboard.flowTemplates.create.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.flowTemplates.getAll.invalidate({
        businessSlug,
      });

      setOpen(false);
      toast.success("Template created");
    },
    onError: () => {
      toast.error("Failed to create template");
    },
  });

  return {
    onSubmit: (data: TemplateForm) => {
      creationMutation.mutate({ businessSlug, ...data });
    },
  };
};
