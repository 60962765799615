import { toast } from "sonner";
import { Route } from "../../_conversationList";

export function useCloseConversations() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { view, filters, status, sortBy, searchQuery } = Route.useSearch();
  const trpcUtils = trpc.useUtils();

  const closeConversationsMutation =
    trpc.dashboard.conversations.closeMany.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.conversations.getAll.invalidate({
          organizationSlug,
          view,
          filters,
          status,
          sortBy,
          searchQuery,
        });

        await trpcUtils.dashboard.conversations.getCount.invalidate({
          organizationSlug,
          view,
          status,
          filters,
          searchQuery,
        });

        await trpcUtils.dashboard.conversations.get.invalidate({
          organizationSlug,
        });

        toast.success("Conversations closed");
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to close conversations");
      },
    });

  const closeConversations = ({ publicIds }: { publicIds: string[] }) => {
    closeConversationsMutation.mutate({
      organizationSlug,
      publicIds,
    });
  };

  return closeConversations;
}
