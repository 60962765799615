import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { ConfigurationAccordionItem } from "./ConfigurationAccordionItem";
import { Route } from "../_config-sidebar";

import { TicketIcon, BookmarkIcon, SlidersIcon } from "lucide-react";

export function HelpDesk() {
  const { hasExternalTicketConfig } = Route.useRouteContext();

  return (
    <ConfigurationAccordionItem value="help-desk" title="Help Desk">
      <SidebarButton
        icon={TicketIcon}
        text="Ticket Routing"
        to="/organization/$organizationSlug/settings/$businessSlug/tickets"
      />

      {!hasExternalTicketConfig && (
        <>
          <SidebarButton
            icon={SlidersIcon}
            text="General"
            to="/organization/$organizationSlug/settings/$businessSlug/help-desk"
          />

          <SidebarButton
            icon={BookmarkIcon}
            text="Macros"
            to="/organization/$organizationSlug/settings/$businessSlug/macros"
          />
        </>
      )}
    </ConfigurationAccordionItem>
  );
}
