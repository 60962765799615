import { Route } from "..";
import { toast } from "sonner";
import { FlowConfigForm } from "../-utils/formSchema";

export function useSubmitForm() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const updateMutation =
    trpc.dashboard.flows.upsertSimpleFlowConfiguration.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.flows.getSimpleFlowConfiguration.invalidate({
          businessSlug,
        });

        toast.success("Successfully updated flow settings.");
      },
      onError: () => {
        toast.error("Failed to update flow settings. Please try again.");
      },
    });

  return {
    onSubmit: (data: FlowConfigForm) => {
      updateMutation.mutate({
        businessSlug,
        ...data,
      });
    },
    isLoading: updateMutation.isPending,
  };
}
