export const orderPrefixToBusinessSlug = {
  ES: "divain-es",
  FRA: "divain-fr",
  IT: "divain-it",
  NL: "divain-nl",
  EU: "divain-eu",
  UK: "divain-uk",
  US: "divain-us",
  MX: "divain-mx",
  PT: "divain-pt",
  DE: "divain-de",
} as const;

export const divainBusinessSlugs = [
  "divain-mx",
  "divain-us",
  "divain-eu",
  "divain-pt",
  "divain-es",
  "divain-fr",
  "divain-nl",
  "divain-uk",
  "divain-de",
  "divain-it",
];

export const conditions = {
  isPendingFulfillment: "Fulfillment is pending",
} as const;

export type ConditionId = keyof typeof conditions;

export function isConditionId(id: string): id is ConditionId {
  return id in conditions;
}
