import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { useConversation } from "../../-hooks/useConversation.hook";
import { Separator } from "@dashboard/common/ui/separator";
import { RecentConversationLink } from "./RecentConversationLink";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { ConversationPreview } from "./ConversationPreview";
import { MergeButton } from "./MergeButton";
import { MergeDialog } from "./MergeDialog";
import { useState } from "react";

export function RecentConversations() {
  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
  const currentConversation = useConversation();

  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);

  const handleMergeClick = (conversationId: string) => {
    setSelectedConversationId(conversationId);
    setMergeDialogOpen(true);
  };

  if (currentConversation.relatedConversations.length < 1) {
    return null;
  }

  const shownConversations = currentConversation.relatedConversations.slice(
    0,
    20,
  );

  const selectedConversation = shownConversations.find(
    (conversation) => conversation.publicId === selectedConversationId,
  );

  return (
    <>
      {currentConversation.type === "customer" && selectedConversation && (
        <MergeDialog
          open={mergeDialogOpen}
          onOpenChange={setMergeDialogOpen}
          sourceConversation={selectedConversation}
        />
      )}

      <Accordion type="multiple" className="w-full">
        <AccordionItem value="item-0" className="border-none !shadow-none">
          <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
            Recent Conversations ({shownConversations.length})
          </AccordionTrigger>
          <AccordionContent className="mt-5 flex max-h-96 flex-col gap-1 overflow-y-scroll pb-0 pl-4 pr-4">
            {shownConversations.map((conversation, index) => (
              <div key={conversation.publicId}>
                <Tooltip
                  triggerAsChild
                  side="left"
                  className="my-10 px-0 py-0"
                  trigger={
                    <div>
                      <RecentConversationLink
                        publicId={conversation.publicId}
                        isClosed={conversation.isClosed}
                        createdAt={new Date(conversation.createdAt)}
                        channel={conversation.latestChannel}
                        orderId={conversation.orderId}
                      />
                    </div>
                  }
                  content={
                    <div className="relative">
                      <ConversationPreview
                        conversation={conversation}
                        mergingEnabled={currentConversation.type === "customer"}
                        mergeButton={
                          <MergeButton
                            onClick={() =>
                              handleMergeClick(conversation.publicId)
                            }
                          />
                        }
                      />
                    </div>
                  }
                />
                {index !== shownConversations.length - 1 && (
                  <Separator className="bg-offWhite mx-3 w-auto" />
                )}
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
}
