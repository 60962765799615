import { RouterOutput } from "@be/exports";
import { CSSProperties, useMemo } from "react";
import { Route } from "../_conversationList";
import { getColorForUuid } from "../-utils/getColorForUuid";
import { Link } from "@tanstack/react-router";
import { cn } from "@dashboard/utils/ui";
import { Forward, Mail, Globe, Instagram, Facebook } from "lucide-react";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { DisplayPassedTimeWithTooltip } from "@dashboard/common/DisplayPassedTimeWithTooltip";
import TelegramLogo from "@dashboard/assets/telegram.svg";
import WhatsAppLogo from "@dashboard/assets/whatsapp-black.svg";
import MessengerLogo from "@dashboard/assets/messenger.svg";
import GoogleLogo from "@dashboard/assets/google.svg";
import { Checkbox } from "@dashboard/common/ui/checkbox";

const colors = [
  "#ec4662",
  "#14C0A6",
  "#BBAB02",
  "#E6739F",
  "#00BFB3",
  "#CDDC39",
  "#FF6B6B",
  "#4ECDC4",
  "#FFD166",
  "#FF8C94",
  "#68B0AB",
];

// used for virtual list sizing
export const CONVERSATION_CARD_HEIGHT = 67;

type ConversationCard =
  RouterOutput["dashboard"]["conversations"]["getAll"]["conversations"][0];

export function ConversationCard({
  conversation,
  className,
  style,
  selectionMode,
  isSelected,
  onSelect,
}: {
  conversation: ConversationCard;
  className?: string;
  style?: CSSProperties;
  selectionMode: boolean;
  isSelected: boolean;
  onSelect: (selected: boolean) => void;
}) {
  const { organizationSlug } = Route.useParams();
  const search = Route.useSearch();

  const avatarBgColor = useMemo(
    () => getColorForUuid(conversation.id, colors),
    [conversation.id],
  );

  function ChannelIcon() {
    switch (conversation.replyChannel ?? conversation.lastMessageChannel) {
      case "email": {
        return (
          <Mail strokeWidth={1.4} height={12} width={12} className="p-0" />
        );
      }
      case "telegram": {
        return (
          <img
            width={12}
            height={12}
            className="grayscale"
            src={TelegramLogo}
          />
        );
      }
      case "web": {
        return (
          <Globe strokeWidth={1.4} height={12} width={12} className="p-0" />
        );
      }
      case "whatsapp": {
        return <img width={12} height={12} src={WhatsAppLogo} />;
      }
      case "instagram": {
        return (
          <Instagram strokeWidth={1.4} height={12} width={12} className="p-0" />
        );
      }
      case "instagramComment": {
        return (
          <Instagram strokeWidth={1.4} height={12} width={12} className="p-0" />
        );
      }
      case "messenger": {
        return (
          <img
            width={12}
            height={12}
            className="grayscale"
            src={MessengerLogo}
          />
        );
      }
      case "facebookComment": {
        return (
          <Facebook strokeWidth={1.4} height={12} width={12} className="p-0" />
        );
      }
      case "brymec": {
        return <img width={12} height={12} src={WhatsAppLogo} />;
      }
      case "google-business-messages": {
        return (
          <img width={12} height={12} className="grayscale" src={GoogleLogo} />
        );
      }
      default: {
        return null;
      }
    }
  }

  function CardIcon() {
    if (conversation.handedOffAt) {
      return (
        <div className="bg-gray-light relative mt-0.5 flex h-[23px] w-[23px] items-center justify-center rounded-full">
          <Forward
            color="currentColor"
            width={16}
            height={16}
            className="text-gray pb-0.5"
            strokeWidth={2.5}
          />
        </div>
      );
    } else {
      return (
        <div
          className="relative mt-0.5 flex h-[23px] w-[23px] items-center justify-center rounded-full"
          style={{ backgroundColor: avatarBgColor }}
        >
          <div className="h-0.5 w-[40%] rounded-full bg-white" />
        </div>
      );
    }
  }

  function TimeComponent() {
    if (conversation.replyChannel === "web" && !conversation.assigneeId) {
      return (
        <div className="flex items-center rounded-xl bg-orange-200 px-3 text-[0.6rem] leading-4 text-orange-500">
          <span>Waiting&nbsp;</span>
          <DisplayPassedTimeWithTooltip date={conversation.timestamp} />
        </div>
      );
    } else {
      return <DisplayPassedTimeWithTooltip date={conversation.timestamp} />;
    }
  }

  const activeClassName = "is-active z-10 outline outline-white";

  return (
    <Link
      to="/organization/$organizationSlug/conversation/$publicId"
      params={{
        organizationSlug,
        publicId: conversation.publicId,
      }}
      search={search}
      className={cn(
        "group relative hover:z-10 hover:outline hover:outline-white",
        className,
        isSelected && activeClassName,
      )}
      activeProps={{
        className: selectionMode ? "" : activeClassName,
      }}
      style={style}
      onClick={(e) => {
        if (selectionMode) {
          e.preventDefault();
          onSelect(!isSelected);
          return;
        }

        const target = e.target as HTMLElement;
        if (
          target.closest(".checkbox-wrapper") ??
          target instanceof HTMLInputElement
        ) {
          e.preventDefault();
        }
      }}
    >
      <div
        style={{ height: `${CONVERSATION_CARD_HEIGHT - 1}px` }}
        className="group-hover:bg-offWhite group-[.is-active]:bg-accent relative flex w-full cursor-pointer gap-x-2 rounded-xl py-3"
      >
        <div className="shrink-0 pl-3">
          {isSelected ? (
            <div className="checkbox-wrapper relative flex h-[23px] w-[23px] items-center justify-center">
              <Checkbox
                checked={isSelected}
                onCheckedChange={(checked) => onSelect(checked as boolean)}
                className={cn(
                  "hover:bg-gray-light data-[state=checked]:bg-blue-dark h-[23px] w-[23px] rounded-full border-gray-300 transition-all duration-200 ease-in-out",
                  selectionMode && "group-hover:opacity-60",
                )}
              />
            </div>
          ) : (
            <>
              <div className="group-hover:hidden">
                <CardIcon />
              </div>
              <div className="checkbox-wrapper relative flex h-[23px] w-[23px] items-center justify-center">
                <Checkbox
                  checked={isSelected}
                  onCheckedChange={(checked) => onSelect(checked as boolean)}
                  className={cn(
                    "data-[state=checked]:bg-blue-dark bg-gray-light z-10 hidden h-[23px] w-[23px] rounded-full border-gray-300 opacity-0 transition-all duration-200 ease-in-out hover:opacity-50 group-hover:block",
                    selectionMode && "group-hover:opacity-50",
                  )}
                />
                <div className="border-blue-dark z-9 absolute inset-0 m-auto hidden h-3 w-3 rounded border group-hover:block" />
              </div>
            </>
          )}
        </div>
        {/* content */}
        <div className="text-gray relative flex flex-grow flex-col gap-1 overflow-hidden pr-3">
          <div className="flex items-center justify-between gap-2">
            {/* name */}
            <TruncatedTooltip
              delayDuration={500}
              text={conversation.customerEmail ?? "Unknown email"}
              className="w-full truncate text-sm font-semibold"
            />
            <ChannelIcon />
          </div>

          <div className="flex items-center justify-between gap-2">
            <span className="truncate text-sm">
              {conversation.lastMessageText}
            </span>
            {/* time */}
            <div className="flex shrink-0 flex-col items-end justify-start">
              <span className="text-sm text-[#757575]">
                <TimeComponent />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-lightest absolute bottom-0 left-3 right-3 h-px group-last:invisible group-hover:invisible" />
    </Link>
  );
}
