import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { format } from "date-fns";

import { Table } from "@dashboard/common/Table/Table";

import { ArticleDialog } from "./-components/ArticleDialog";
import { EditArticleDialogContent } from "./-components/EditArticleDialogContent";
import { CreateArticleDialogContent } from "./-components/CreateArticleDialogContent";

import { useDeleteArticles } from "./-hooks/useDeleteArticles";
import { useCategories } from "./-hooks/useCategories";
import { ArticleVisibility } from "./-components/ArticleVisibility";
import { useExportArticles } from "./-hooks/useExportArticles";
import { useImportArticles } from "./-hooks/useImportArticles";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/articles/",
)({
  validateSearch: z.object({
    searchQuery: z.string().optional(),
    q: z
      .object({
        question: z.string(),
        category: z.string().optional(),
      })
      .optional(),
  }),
  loaderDeps: ({ search: { searchQuery, q } }) => ({
    searchQuery,
    q,
  }),
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
    deps: { searchQuery },
  }) => {
    await trpcClientUtils.dashboard.articles.getAll.ensureData({
      businessSlug,
      searchQuery,
    });

    await trpcClientUtils.dashboard.articles.getCategories.ensureData({
      businessSlug,
    });
  },
  component: ArticlesPage,
});

function ArticlesPage() {
  const { businessSlug } = Route.useParams();
  const search = Route.useSearch();
  const { searchQuery } = search;
  const navigate = Route.useNavigate();
  const { trpc, business, user } = Route.useRouteContext();

  const [articles] = trpc.dashboard.articles.getAll.useSuspenseQuery({
    searchQuery,
    businessSlug,
  });

  const setSearch = (value: string | undefined) => {
    void navigate({ search: { ...search, searchQuery: value } });
  };

  const { deleteArticles } = useDeleteArticles();

  const categories = useCategories();

  const records = articles.map((article) => ({
    id: article.id,
    titleDialog: ArticleDialog({
      title: article.title,
      content: article.content,
      category: categories.find(
        (category) => category.id === article.categoryId,
      )?.name,
      isPublic: article.isPublic,
      createdAt: article.createdAt,
      updatedAt: article.updatedAt,
      showVisibility: user.isAdmin,
    }),
    title: article.title,
    content: article.content,
    category: categories.find((category) => category.id === article.categoryId)
      ?.name ?? <span className="text-gray-500">No Category</span>,
    categoryId: article.categoryId ?? undefined,
    isPublic: article.isPublic,
    updatedAt: format(new Date(article.updatedAt), "Pp"),
    visibility: (
      <ArticleVisibility articleId={article.id} isPublic={article.isPublic} />
    ),
    source: article.managedBy ?? "Manual",
  }));

  const title = (
    <div className="flex items-center gap-2">
      <span className="text-xl font-medium">Article Center</span>
      <span className="text-gray-500">{`for ${business.name}`}</span>
    </div>
  );

  const { exportArticles } = useExportArticles();
  const { importArticles } = useImportArticles();

  return (
    <div className="flex h-full flex-col">
      <Table
        title={title}
        records={records}
        topBarActions={[
          {
            label: "Import",
            action: importArticles,
          },
        ]}
        columns={[
          {
            header: "Title",
            accessor: "titleDialog",
          },
          {
            header: "Category",
            accessor: "category",
          },
          {
            header: "Last edited",
            accessor: "updatedAt",
          },
          ...(user.isAdmin
            ? [
                {
                  header: "Visibility",
                  accessor: "visibility" as const,
                  headerClassName: "text-center",
                  cellClassName: "text-center text-black",
                },
              ]
            : []),
          {
            header: "Source",
            accessor: "source",
            headerClassName: "text-center",
            cellClassName: "text-center text-black",
          },
        ]}
        initialAddRecordDialogOpen={!!search.q}
        addRecordDialogContent={(setOpen) => (
          <CreateArticleDialogContent setOpen={setOpen} />
        )}
        editRecordDialogContent={(record, setOpen) => (
          <EditArticleDialogContent {...record} setOpen={setOpen} />
        )}
        deleteRecords={deleteArticles}
        recordName={{ singular: "Article", plural: "Articles" }}
        noRecordsText="No articles available"
        search={{
          setSearch,
          searchQuery,
        }}
        headerActions={[
          {
            label: "Export",
            action: exportArticles,
          },
        ]}
        contextActions={[
          {
            label: "Export",
            action: exportArticles,
          },
        ]}
      />
    </div>
  );
}
