import { z } from "zod";

export const templateFormSchema = z.object({
  name: z
    .string({
      invalid_type_error: "Name is required",
    })
    .min(1, "Name is required"),
  content: z
    .string({
      invalid_type_error: "Content is required",
    })
    .min(1, "Content is required"),
});

export type TemplateForm = z.infer<typeof templateFormSchema>;
