import { RouterOutput } from "@be/exports";
import { createFileRoute, redirect } from "@tanstack/react-router";

type User = RouterOutput["dashboard"]["users"]["getCurrent"];

export const Route = createFileRoute("/organization")({
  beforeLoad: async ({ context: { trpcClientUtils }, location }) => {
    let user: User;

    try {
      user = await trpcClientUtils.dashboard.users.getCurrent.ensureData(
        undefined,
        {
          retry: false,
        },
      );
    } catch (e) {
      sessionStorage.setItem("redirectAfterLogin", location.pathname);
      throw redirect({ to: "/login" });
    }

    return {
      user,
    };
  },
});
