import { useInView } from "react-intersection-observer";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useEffect, useMemo, useRef, useState } from "react";
import { Route } from "../_conversationList";
import { Oval } from "react-loader-spinner";
import { useSelection } from "../-context/SelectionContext";

import { CONVERSATION_CARD_HEIGHT } from "./ConversationCard";
import { ConversationCard } from "./ConversationCard";
import { ConversationMovedCard } from "./ConversationMovedCard";
import { useSubscriptions } from "../-hooks/useSubscriptions";
import { useMovedConversationEffect } from "../-hooks/useMovedConversationEffect";

export function ConversationsList() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { view, filters, status, sortBy, searchQuery } = Route.useSearch();
  const { selectedCards, setSelectedCards } = useSelection();

  const [firstPage] = trpc.dashboard.conversations.getAll.useSuspenseQuery({
    organizationSlug,
    view,
    filters,
    status,
    sortBy,
    searchQuery,
  });

  const [movedConversation, setMovedConversation] = useState<string | null>(
    null,
  );

  const { data, fetchNextPage, hasNextPage } =
    trpc.dashboard.conversations.getAll.useInfiniteQuery(
      {
        organizationSlug,
        view,
        filters,
        status,
        sortBy,
        searchQuery,
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        initialData: {
          pages: [firstPage],
          pageParams: [undefined],
        },
      },
    );

  useMovedConversationEffect({
    pageParams: data.pageParams.map((pageParam) => pageParam ?? null),
    movedConversation,
    setMovedConversation,
  });

  useSubscriptions({
    pageParams: data.pageParams.map((pageParam) => pageParam ?? null),
    setMovedConversation,
  });

  const conversations = useMemo(
    () => data.pages.map((page) => page.conversations).flat(),
    [data],
  );

  const scrollWrapRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: conversations.length + (hasNextPage ? 1 : 0),
    getScrollElement: () => scrollWrapRef.current,
    estimateSize: () => CONVERSATION_CARD_HEIGHT,
    overscan: 5,
  });

  const afterInView = useInView();

  useEffect(() => {
    if (afterInView.inView) {
      void fetchNextPage();
    }
  }, [fetchNextPage, afterInView.inView]);

  return (
    <div ref={scrollWrapRef} className={"h-full overflow-auto px-3 pb-2"}>
      <div
        className={"relative w-full"}
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer.getVirtualItems().map(({ key, index, start }) =>
          index <= conversations.length - 1 ? (
            movedConversation === conversations[index].publicId ? (
              <ConversationMovedCard start={start} key={key} />
            ) : (
              <ConversationCard
                conversation={conversations[index]}
                key={key}
                className="absolute left-0 top-0 w-full"
                style={{
                  transform: `translateY(${start}px)`,
                }}
                selectionMode={selectedCards.size > 0}
                isSelected={selectedCards.has(conversations[index].publicId)}
                onSelect={(selected) => {
                  setSelectedCards((prev) => {
                    const next = new Set(prev);
                    if (selected) {
                      next.add(conversations[index].publicId);
                    } else {
                      next.delete(conversations[index].publicId);
                    }
                    return next;
                  });
                }}
              />
            )
          ) : (
            <div
              key={index}
              className="absolute left-0 top-0 flex w-full justify-center pt-2"
              style={{
                transform: `translateY(${start}px)`,
              }}
              ref={afterInView.ref}
            >
              <Oval
                height={24}
                width={24}
                strokeWidth={6}
                color="gray"
                secondaryColor="gray"
              />
            </div>
          ),
        )}
      </div>
    </div>
  );
}
