import { cn } from "@dashboard/utils/ui";
import { Upload, Mail } from "lucide-react";
import { DragEvent, ReactNode } from "react";
import { SiGmail } from "react-icons/si";
import { PiMicrosoftOutlookLogo } from "react-icons/pi";
import { Link } from "@tanstack/react-router";
import { useConversation } from "../../../-hooks/useConversation.hook";
import { Route } from "../../..";
import { Button } from "@dashboard/common/ui/button";

interface MessageInputContainerProps {
  children: ReactNode;
  isFocused: boolean;
  isDragging: boolean;
  customInput?: "setup-email-integration" | null;
  handleDrag?: (e: DragEvent<HTMLDivElement>) => void;
  handleDrop?: (e: DragEvent<HTMLDivElement>) => void;
  backgroundColor?: string;
}

export function MessageInputContainer({
  children,
  isFocused,
  isDragging,
  customInput,
  handleDrag,
  handleDrop,
  backgroundColor,
}: MessageInputContainerProps) {
  const showUpload = !customInput && isDragging;

  return (
    <div className="mb-3 w-full px-7">
      <div
        className={cn(
          "relative flex flex-col overflow-x-hidden break-words rounded-2xl border border-gray-100 px-4 pb-2 pt-3",
          isFocused ? "border-[#C2C4BB] shadow-xl" : "shadow-md",
          backgroundColor,
        )}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {showUpload && (
          <div className="absolute inset-0 z-50 flex flex-col items-center justify-center rounded-2xl border border-gray-400 bg-white/80 backdrop-blur-sm">
            <Upload className="h-10 w-10 text-gray-400" />
            <p className="mt-2 text-sm text-gray-500">
              Drop files here to attach
            </p>
          </div>
        )}
        {customInput === "setup-email-integration" && <SetupEmailIntegration />}
        {children}
      </div>
    </div>
  );
}

interface EmailButtonProps {
  label: string;
  dialog: "smtpImap" | "outlook" | "google";
  icon: ReactNode;
}

const EmailButton = ({ dialog, icon, label }: EmailButtonProps) => {
  const conversation = useConversation();

  const { businessSlug } = conversation;
  const { organizationSlug } = Route.useParams();

  return (
    <Link
      to="/organization/$organizationSlug/settings/$businessSlug/integrations"
      params={{
        organizationSlug,
        businessSlug,
      }}
      search={{
        dialog,
      }}
      className="w-full"
    >
      <Button
        variant="outline"
        className="flex w-full flex-row items-center gap-2 bg-white"
      >
        {icon}
        <span>{label}</span>
      </Button>
    </Link>
  );
};

const SetupEmailIntegration = () => {
  return (
    <div className="absolute inset-0 z-50 flex flex-col items-center justify-center rounded-2xl border-gray-400 bg-white/80 backdrop-blur-sm">
      <p>Setup email integration to reply to your customers</p>
      <div className="flex w-full flex-row items-center justify-center gap-2 p-4">
        <EmailButton
          label="SMTP/IMAP"
          dialog="smtpImap"
          icon={<Mail className="h-5 w-5" />}
        />
        <EmailButton
          label="Outlook"
          dialog="outlook"
          icon={<PiMicrosoftOutlookLogo className="h-5 w-5" />}
        />
        <EmailButton
          label="Gmail"
          dialog="google"
          icon={<SiGmail className="h-5 w-5" />}
        />
      </div>
    </div>
  );
};
