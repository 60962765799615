import { z } from "zod";
import {
  addDays,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
  subDays,
} from "date-fns";
import { exhaustive } from "@dashboard/utils/other";

export const periodSchema = z
  .union([
    z.literal("today"),
    z.literal("this-week"),
    z.literal("this-month"),
    z.literal("last-2-days"),
    z.literal("last-7-days"),
    z.literal("last-14-days"),
    z.literal("last-30-days"),
    z.literal("last-90-days"),
    // Format: "custom:YYYY-MM-DD.YYYY-MM-DD"
    z.string().regex(/^custom:\d{4}-\d{2}-\d{2}\.\d{4}-\d{2}-\d{2}$/),
  ])
  .catch("last-30-days");

export function periodToRange(period: z.infer<typeof periodSchema>) {
  if (period.startsWith("custom:")) {
    const [fromDate, toDate] = period.slice(7).split(".");
    return {
      from: startOfDay(new Date(fromDate)),
      to: startOfDay(addDays(new Date(toDate), 1)),
    };
  }

  switch (period) {
    case "today": {
      return {
        from: startOfDay(new Date()),
        to: startOfTomorrow(),
      };
    }
    case "this-week": {
      return {
        from: startOfWeek(new Date(), { weekStartsOn: 1 }),
        to: startOfTomorrow(),
      };
    }
    case "this-month": {
      return {
        from: startOfMonth(new Date()),
        to: startOfTomorrow(),
      };
    }
    case "last-2-days": {
      return {
        from: subDays(startOfToday(), 1),
        to: startOfTomorrow(),
      };
    }
    case "last-7-days": {
      return {
        from: subDays(startOfToday(), 6),
        to: startOfTomorrow(),
      };
    }
    case "last-14-days": {
      return {
        from: subDays(startOfToday(), 13),
        to: startOfTomorrow(),
      };
    }
    case "last-30-days": {
      return {
        from: subDays(startOfToday(), 29),
        to: startOfTomorrow(),
      };
    }
    case "last-90-days": {
      return {
        from: subDays(startOfToday(), 89),
        to: startOfTomorrow(),
      };
    }

    default:
      throw exhaustive(period as never);
  }
}
