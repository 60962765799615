import { Button } from "@dashboard/common/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import { Tooltip } from "@dashboard/common/ui/tooltip";

import { Languages, Undo } from "lucide-react";
import { useState } from "react";
import { SUPPORTED_LANGUAGES } from "@be/lib/translations/translations.const";
import { useTranslateText } from "../../-hooks/useTranslateText";

const AVAILABLE_LANGUAGES = Object.entries(SUPPORTED_LANGUAGES).map(
  ([id, lang]) => ({
    id,
    title: `${lang.name} (${lang.nativeName})`,
  }),
);

export function LanguageCommand({
  inputText,
  setInputText,
  originalText,
  setOriginalText,
}: {
  inputText: string;
  setInputText: (text: string) => void;
  originalText: string;
  setOriginalText: (text: string) => void;
}) {
  const [open, setOpen] = useState(false);

  const { translate } = useTranslateText({
    onData: (data) => {
      setOriginalText(inputText);
      setInputText(data);
    },
  });

  const handleSelectLanguage = (languageId: string) => {
    translate({
      text: inputText,
      targetLang: languageId,
    });

    setOpen(false);
  };

  const isTranslated = originalText !== "";

  return isTranslated ? (
    <Tooltip
      triggerAsChild
      trigger={
        <Button
          variant="select"
          onClick={() => {
            setInputText(originalText);
            setOriginalText("");
          }}
          className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
        >
          <Undo className="h-5 w-5" />
        </Button>
      }
      content="Undo Translation"
    />
  ) : (
    <Popover open={open} onOpenChange={setOpen}>
      <Tooltip
        triggerAsChild
        trigger={
          <PopoverTrigger asChild>
            <Button
              variant="select"
              role="combobox"
              aria-expanded={open}
              className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
            >
              <Languages className="h-5 w-5" />
            </Button>
          </PopoverTrigger>
        }
        content="Translate message"
      />
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search languages..." icon={Languages} />
          <CommandList>
            <CommandEmpty>
              <span>No languages found.</span>
            </CommandEmpty>
            <CommandGroup>
              {AVAILABLE_LANGUAGES.map((language) => (
                <CommandItem
                  key={language.id}
                  value={language.title}
                  onSelect={() => handleSelectLanguage(language.id)}
                >
                  {language.title}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
