import {
  useState,
  DragEvent,
  useMemo,
  ClipboardEvent,
  KeyboardEvent,
} from "react";
import { MessageInputContainer } from "./components/MessageInputContainer";
import { MessageHeader } from "./components/MessageHeader";
import { EmailMetadata } from "./components/EmailMetadata";
import { AttachmentList } from "./components/AttachmentList";
import { MessageIcon } from "./components/MessageIcon";
import { MacroRecommendations } from "../MacroRecommendations";
import { MacroAction } from "@be/modules/macros/macros.types";
import { DashboardConversation } from "./utils/input.types";
import { useDraftMessages } from "../../../../-context/DraftMessagesContext";
import { useFileUpload } from "../../../-hooks/useFileUpload";
import { useRefineExternalMessage } from "../../-hooks/useRefineExternalMessage";
import { useTranslateText } from "../../../-hooks/useTranslateText";
import { useSendExternalMessage } from "../../-hooks/useSendExternalMessage";
import { MessageTextArea } from "./components/MessageTextArea";
import { ActionList } from "./components/ActionList";
import { MessageToolbar } from "./components/MessageToolbar";
import { RouterOutput } from "@be/exports";
import { ExtractUnionMember } from "@dashboard/utils/types";

interface ExternalMessageInputProps {
  conversation: DashboardConversation;
}

type Message = ExtractUnionMember<
  RouterOutput["dashboard"]["conversations"]["get"]["parts"][0],
  "type",
  "message"
>;

export function ExternalMessageInput({
  conversation,
}: ExternalMessageInputProps) {
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [isRefining, setIsRefining] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const { getDraft, updateCurrentDraft, clearDraft } = useDraftMessages();

  const publicId = conversation.publicId;
  const draft = getDraft(publicId);

  const { uploadFiles } = useFileUpload();

  // Send message utils
  const sendMessage = useSendExternalMessage();

  const onClickSendMessage = ({ action }: { action?: MacroAction }) => {
    if (isRefining) return;
    if (!draft.current.text) return;

    // This ensures that we don't send duplicate actions if the action is already in the list
    let allActions = [...draft.current.actions];
    if (action) {
      allActions.push(action);
      allActions = allActions.filter(
        (v, i, a) => a.findIndex((t) => t.type === v.type) === i,
      );
    }

    sendMessage({
      text: draft.current.text,
      actions: allActions,
      fileIds: draft.current.attachments.map((f) => f.fileId),
    });

    clearDraft(publicId);
  };
  // End of Send message utils

  // Translation utils
  const { translate } = useTranslateText({
    onData: (data) => {
      updateCurrentDraft(publicId, {
        text: data,
        originalText: draft.current.text,
      });
    },
  });

  const targetLang = useMemo(() => {
    const messages = conversation.parts.filter(
      (part): part is Message => part.type === "message",
    );

    if (messages.length > 0) {
      const languageCounts = messages.reduce<Record<string, number>>(
        (acc, msg) => {
          if (!msg.language) return acc;

          const lang = msg.language;
          acc[lang] = (acc[lang] || 0) + 1;
          return acc;
        },
        {},
      );

      let mostFrequentLang = "";
      let highestCount = 0;

      Object.entries(languageCounts).forEach(([lang, count]) => {
        if (count > highestCount) {
          mostFrequentLang = lang;
          highestCount = count;
        }
      });

      return mostFrequentLang || undefined;
    }
  }, [conversation.parts]);

  const handleTranslate = () => {
    if (!targetLang) return;

    translate({ text: draft.current.text, targetLang });
  };
  // End of Translation utils

  // Dragging utils
  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragging(true);
    } else if (e.type === "dragleave" || e.type === "drop") {
      setIsDragging(false);
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length === 0) return;

    const uploadedFiles = await uploadFiles({
      files: droppedFiles,
      businessId: conversation.businessId,
    });

    updateCurrentDraft(publicId, {
      attachments: [
        ...draft.current.attachments,
        ...uploadedFiles.map((file) => ({
          fileId: file.fileId,
          filename: file.filename,
        })),
      ],
    });
  };

  // Key / Paste utils
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const isModifierKeyPressed = e.metaKey || e.ctrlKey;
    if (e.key === "Enter" && isModifierKeyPressed) {
      onClickSendMessage({});

      e.preventDefault();
    }
  };

  const handlePaste = async (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const files = Array.from(e.clipboardData.items)
      .filter((item) => item.kind === "file")
      .map((item) => item.getAsFile())
      .filter((file): file is File => file !== null);

    if (files.length === 0) return;

    e.preventDefault();

    const uploadedFiles = await uploadFiles({
      files,
      businessId: conversation.businessId,
    });

    updateCurrentDraft(publicId, {
      attachments: [
        ...draft.current.attachments,
        ...uploadedFiles.map((file) => ({
          fileId: file.fileId,
          filename: file.filename,
        })),
      ],
    });
  };
  // End of Key / Paste utils

  // Refining utils
  const refineMessage = useRefineExternalMessage();

  const onClickRefineMessage = () => {
    if (isRefining) return;

    if (!draft.current.text) return;

    setIsRefining(true);

    refineMessage({
      currentMessage: draft.current.text,
      onSuccess: (refinedMessage) => {
        updateCurrentDraft(publicId, { text: refinedMessage });
        setIsRefining(false);
      },
      onError: () => {
        setIsRefining(false);
      },
    });
  };
  // End of Refining utils

  return (
    <MessageInputContainer
      isFocused={inputIsFocused}
      isDragging={isDragging}
      handleDrag={handleDrag}
      handleDrop={(e) => void handleDrop(e)}
    >
      <MessageHeader icon={<MessageIcon />} title="Email" />

      <EmailMetadata
        from={conversation.emailIntegration?.address ?? ""}
        to={conversation.externalParticipantEmails.join(", ")}
        subject={conversation.subject}
        isEditable={false}
      />

      <MessageTextArea
        value={draft.current.text}
        onChange={(text) => updateCurrentDraft(publicId, { text })}
        onPaste={(e) => void handlePaste(e)}
        onKeyDown={handleKeyDown}
        onFocus={() => setInputIsFocused(true)}
        onBlur={() => setInputIsFocused(false)}
        disabled={isRefining}
        minRows={4}
      />

      {conversation.enableMacroRecommendations && (
        <MacroRecommendations
          setInput={(text) => updateCurrentDraft(publicId, { text })}
          setActions={(actions) => updateCurrentDraft(publicId, { actions })}
          setFiles={(files) =>
            updateCurrentDraft(publicId, { attachments: files })
          }
        />
      )}

      <ActionList
        actions={draft.current.actions}
        onRemove={(index) =>
          updateCurrentDraft(publicId, {
            actions: draft.current.actions.filter((_, i) => i !== index),
          })
        }
      />

      <AttachmentList
        attachments={draft.current.attachments}
        onRemove={(file) =>
          updateCurrentDraft(publicId, {
            attachments: draft.current.attachments.filter(
              (f) => f.fileId !== file.fileId,
            ),
          })
        }
      />

      <MessageToolbar
        setInput={(input) => updateCurrentDraft(publicId, { text: input })}
        setActions={(actions) => updateCurrentDraft(publicId, { actions })}
        setFiles={(files) =>
          updateCurrentDraft(publicId, { attachments: files })
        }
        onAttachmentUpload={(file) => {
          updateCurrentDraft(publicId, {
            attachments: [
              ...draft.current.attachments,
              {
                fileId: file.fileId,
                filename: file.filename,
              },
            ],
          });
        }}
        isTranslated={!!draft.current.originalText}
        onTranslate={handleTranslate}
        onUndo={() => {
          updateCurrentDraft(publicId, {
            text: draft.current.originalText,
            originalText: "",
          });
        }}
        showTranslate={!!targetLang && conversation.enableTranslation}
        onRefine={onClickRefineMessage}
        isRefining={isRefining}
        businessId={conversation.businessId}
        onClickSendMessage={onClickSendMessage}
        sendMessageDisabled={isRefining || !draft.current.text}
      />
    </MessageInputContainer>
  );
}
