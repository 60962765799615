import { Button } from "@dashboard/common/ui/button";
import { X } from "lucide-react";

import { useRemoveTag } from "../../../-hooks/useRemoveTag";

export function Tag({ title, id }: { title: string; id: string }) {
  const removeTag = useRemoveTag();

  return (
    <Button
      onClick={() => removeTag(id)}
      variant="outline"
      className="group relative flex gap-2 rounded-full"
    >
      <div className="group-hover:bg-accent absolute right-2 z-10 h-7 w-7 rounded-full bg-transparent blur-sm" />
      <X className="group-hover:bg-accent absolute right-2 z-20 h-4 w-4 rounded-full bg-transparent text-transparent transition-colors group-hover:text-black" />
      <span>{title}</span>
    </Button>
  );
}
