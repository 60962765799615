import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { Route } from "..";

export function IntroDialog() {
  const search = Route.useSearch();
  const [dialogOpen, setDialogOpen] = useState(search.openIntro);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Welcome to Octocom</DialogTitle>
          <DialogDescription>
            Let&apos;s get your bot up and running!
          </DialogDescription>
        </DialogHeader>
        <p className="text-sm">
          Follow the steps in the &quot;Setup&quot; tab to configure and launch
          your bot. You can test your bot&apos;s functionality in the &quot;Test
          Your Bot&quot; tab as you set it up.
        </p>

        <p className="bg-muted rounded-lg p-4 text-sm">
          Have questions? Our support team is ready to assist through the live
          chat in the lower right corner. We&apos;re committed to making your
          experience smooth and successful.
        </p>
      </DialogContent>
    </Dialog>
  );
}
