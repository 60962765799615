import {
  useState,
  DragEvent,
  KeyboardEvent,
  ClipboardEvent,
  useMemo,
} from "react";
import { ChannelType } from "@db";
import { useFileUpload } from "../../../-hooks/useFileUpload";
import { useDraftMessages } from "../../../../-context/DraftMessagesContext";
import { MessageInputContainer } from "./components/MessageInputContainer";
import { MessageHeader } from "./components/MessageHeader";
import { Pencil, Mail, MessageCircle } from "lucide-react";
import { MessageTextArea } from "./components/MessageTextArea";
import { ActionList } from "./components/ActionList";
import { AttachmentList } from "./components/AttachmentList";
import { MessageToolbar } from "./components/MessageToolbar";
import { useTranslateText } from "../../../-hooks/useTranslateText";
import { useRefineMessage } from "../../-hooks/useRefineMessage";
import { useSendMessage } from "../../-hooks/useSendMessage";
import { MacroAction } from "@be/modules/macros/macros.types";
import { MacroRecommendations } from "../MacroRecommendations";
import { DashboardConversation } from "./utils/input.types";
import { RouterOutput } from "@be/exports";
import { ExtractUnionMember } from "@dashboard/utils/types";
import { Select } from "@dashboard/common/ui/select";
import { useSendNote } from "./-hooks/useSendNote";
import {
  FaFacebook,
  FaFacebookMessenger,
  FaInstagram,
  FaWhatsapp,
  FaTelegram,
} from "react-icons/fa";

interface CustomerMessageInputProps {
  replyChannel: ChannelType;
  conversation: DashboardConversation;
  customInput: "setup-email-integration" | null;
}

type Message = ExtractUnionMember<
  RouterOutput["dashboard"]["conversations"]["get"]["parts"][0],
  "type",
  "message"
>;

const getChannelIcon = (channel: ChannelType) => {
  switch (channel) {
    case "email":
      return <Mail size={15} />;
    case "whatsapp":
      return <FaWhatsapp size={15} />;
    case "messenger":
      return <FaFacebookMessenger size={15} />;
    case "instagram":
      return <FaInstagram size={15} />;
    case "instagramComment":
      return <FaInstagram size={15} />;
    case "facebookComment":
      return <FaFacebook size={15} />;
    case "telegram":
      return <FaTelegram size={15} />;
    case "web":
      return <MessageCircle size={15} />;
    default:
      return <MessageCircle size={15} />;
  }
};

export function CustomerMessageInput({
  replyChannel,
  conversation,
  customInput,
}: CustomerMessageInputProps) {
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [isRefining, setIsRefining] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const {
    getDraft,
    updateCurrentDraft,
    clearDraft,
    updateNoteDraft,
    clearNoteDraft,
  } = useDraftMessages();

  const sendNote = useSendNote();

  const publicId = conversation.publicId;
  const draft = getDraft(publicId);

  const { uploadFiles } = useFileUpload();

  // Send message utils
  const sendMessage = useSendMessage();

  const onClickSendMessage = ({ action }: { action?: MacroAction }) => {
    if (isRefining || !draft.current.text) return;

    // This ensures that we don't send duplicate actions if the action is already in the list
    let allActions = [...draft.current.actions];
    if (action) {
      allActions.push(action);
      allActions = allActions.filter(
        (v, i, a) => a.findIndex((t) => t.type === v.type) === i,
      );
    }

    sendMessage({
      text: draft.current.text,
      channel: replyChannel,
      actions: allActions,
      fileIds: draft.current.attachments.map((f) => f.fileId),
    });

    clearDraft(publicId);
  };

  const onClickSendNote = ({ action }: { action?: MacroAction }) => {
    if (isRefining || !draft.note.text) return;

    let allActions = [...draft.note.actions];
    if (action) {
      allActions.push(action);
      allActions = allActions.filter(
        (v, i, a) => a.findIndex((t) => t.type === v.type) === i,
      );
    }

    sendNote({
      text: draft.note.text,
      actions: allActions,
      fileIds: draft.note.attachments.map((f) => f.fileId),
    });

    clearNoteDraft(publicId);
  };
  // End of Send message utils

  // Translation utils
  const { translate } = useTranslateText({
    onData: (data) => {
      if (messageType === "note") {
        updateNoteDraft(publicId, {
          text: data,
          originalText: draft.current.text,
        });
      } else {
        updateCurrentDraft(publicId, {
          text: data,
          originalText: draft.current.text,
        });
      }
    },
  });

  const targetLang = useMemo(() => {
    const messages = conversation.parts.filter(
      (part): part is Message => part.type === "message",
    );

    if (messages.length > 0) {
      const languageCounts = messages.reduce<Record<string, number>>(
        (acc, msg) => {
          if (!msg.language) return acc;

          const lang = msg.language;
          acc[lang] = (acc[lang] || 0) + 1;
          return acc;
        },
        {},
      );

      let mostFrequentLang = "";
      let highestCount = 0;

      Object.entries(languageCounts).forEach(([lang, count]) => {
        if (count > highestCount) {
          mostFrequentLang = lang;
          highestCount = count;
        }
      });

      return mostFrequentLang || undefined;
    }
  }, [conversation.parts]);

  const handleTranslate = () => {
    if (!targetLang) return;

    if (messageType === "note") {
      translate({ text: draft.note.text, targetLang });
    } else {
      translate({ text: draft.current.text, targetLang });
    }
  };
  // End of Translation utils

  // Dragging utils
  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragging(true);
    } else if (e.type === "dragleave" || e.type === "drop") {
      setIsDragging(false);
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length === 0) return;

    const uploadedFiles = await uploadFiles({
      files: droppedFiles,
      businessId: conversation.businessId,
    });

    if (messageType === "note") {
      updateNoteDraft(publicId, {
        attachments: [
          ...draft.note.attachments,
          ...uploadedFiles.map((file) => ({
            fileId: file.fileId,
            filename: file.filename,
          })),
        ],
      });
    } else {
      updateCurrentDraft(publicId, {
        attachments: [
          ...draft.current.attachments,
          ...uploadedFiles.map((file) => ({
            fileId: file.fileId,
            filename: file.filename,
          })),
        ],
      });
    }
  };
  // End of Dragging utils

  // Key / Paste utils
  const handleKeyDown = (e: KeyboardEvent) => {
    const isModifierKeyPressed = e.metaKey || e.ctrlKey;
    if (e.key === "Enter" && isModifierKeyPressed) {
      if (messageType === "note") {
        onClickSendNote({});
      } else {
        onClickSendMessage({});
      }

      e.preventDefault();
    }
  };

  const handlePaste = async (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const files = Array.from(e.clipboardData.items)
      .filter((item) => item.kind === "file")
      .map((item) => item.getAsFile())
      .filter((file): file is File => file !== null);

    if (files.length === 0) return;

    e.preventDefault();

    const uploadedFiles = await uploadFiles({
      files,
      businessId: conversation.businessId,
    });

    updateCurrentDraft(publicId, {
      attachments: [
        ...draft.current.attachments,
        ...uploadedFiles.map((file) => ({
          fileId: file.fileId,
          filename: file.filename,
        })),
      ],
    });
  };
  // End of Key / Paste utils

  // Refining utils
  const refineMessage = useRefineMessage();

  const onClickRefineMessage = () => {
    if (isRefining) return;

    if (!draft.current.text) return;

    setIsRefining(true);

    refineMessage({
      currentMessage:
        messageType === "note" ? draft.note.text : draft.current.text,
      replyChannel,
      onSuccess: (refinedMessage) => {
        messageType === "note"
          ? updateNoteDraft(publicId, { text: refinedMessage })
          : updateCurrentDraft(publicId, { text: refinedMessage });
        setIsRefining(false);
      },
      onError: () => {
        setIsRefining(false);
      },
    });
  };
  // End of Refining utils

  // Note utils
  const [messageType, setMessageType] = useState<"message" | "note">("message");

  const selectOptions = [
    {
      value: "message" as const,
      label: (
        <div className="flex flex-row items-center justify-center">
          <MessageHeader
            icon={getChannelIcon(replyChannel)}
            title={`Reply via ${replyChannel}`}
          />
        </div>
      ),
    },
    {
      value: "note" as const,
      label: (
        <div className="flex flex-row items-center justify-center">
          <MessageHeader icon={<Pencil size={15} />} title="Note" />
        </div>
      ),
    },
  ];
  // End of Note utils

  return (
    <MessageInputContainer
      isFocused={inputIsFocused}
      isDragging={isDragging}
      customInput={customInput}
      handleDrag={handleDrag}
      handleDrop={(e) => void handleDrop(e)}
      backgroundColor={messageType === "note" ? "bg-[#FEECAF]" : undefined}
    >
      <div className="flex flex-row items-center">
        <Select
          variant="dropdown"
          blueBg={false}
          side="top"
          className="hover:text-blue flex h-min flex-row items-center justify-center p-0"
          options={selectOptions}
          value={messageType}
          onChange={(value) => {
            setMessageType(value);
          }}
        />
        {inputIsFocused && replyChannel === "email" && (
          <span className="ml-2 text-sm text-gray-600">
            {conversation.customer?.email}
          </span>
        )}
      </div>

      <MessageTextArea
        value={messageType === "note" ? draft.note.text : draft.current.text}
        onChange={(text) =>
          messageType === "note"
            ? updateNoteDraft(publicId, { text })
            : updateCurrentDraft(publicId, { text })
        }
        onPaste={(e) => void handlePaste(e)}
        onKeyDown={handleKeyDown}
        onFocus={() => setInputIsFocused(true)}
        onBlur={() => setInputIsFocused(false)}
        disabled={isRefining}
        backgroundColor={messageType === "note" ? "bg-[#FEECAF]" : undefined}
      />

      {conversation.enableMacroRecommendations && (
        <MacroRecommendations
          setInput={(text) =>
            messageType === "note"
              ? updateNoteDraft(publicId, { text })
              : updateCurrentDraft(publicId, { text })
          }
          setActions={(actions) =>
            messageType === "note"
              ? updateNoteDraft(publicId, { actions })
              : updateCurrentDraft(publicId, { actions })
          }
          setFiles={(files) =>
            messageType === "note"
              ? updateNoteDraft(publicId, { attachments: files })
              : updateCurrentDraft(publicId, { attachments: files })
          }
        />
      )}

      <ActionList
        actions={
          messageType === "note" ? draft.note.actions : draft.current.actions
        }
        onRemove={(index) =>
          messageType === "note"
            ? updateNoteDraft(publicId, {
                actions: draft.note.actions.filter((_, i) => i !== index),
              })
            : updateCurrentDraft(publicId, {
                actions: draft.current.actions.filter((_, i) => i !== index),
              })
        }
      />

      <AttachmentList
        attachments={
          messageType === "note"
            ? draft.note.attachments
            : draft.current.attachments
        }
        onRemove={(file) =>
          messageType === "note"
            ? updateNoteDraft(publicId, {
                attachments: draft.note.attachments.filter(
                  (f) => f.fileId !== file.fileId,
                ),
              })
            : updateCurrentDraft(publicId, {
                attachments: draft.current.attachments.filter(
                  (f) => f.fileId !== file.fileId,
                ),
              })
        }
      />

      <MessageToolbar
        setInput={(input) =>
          messageType === "note"
            ? updateNoteDraft(publicId, { text: input })
            : updateCurrentDraft(publicId, { text: input })
        }
        setActions={(actions) =>
          messageType === "note"
            ? updateNoteDraft(publicId, { actions })
            : updateCurrentDraft(publicId, { actions })
        }
        setFiles={(files) =>
          messageType === "note"
            ? updateNoteDraft(publicId, { attachments: files })
            : updateCurrentDraft(publicId, { attachments: files })
        }
        onAttachmentUpload={(file) => {
          messageType === "note"
            ? updateNoteDraft(publicId, {
                attachments: [
                  ...draft.note.attachments,
                  {
                    fileId: file.fileId,
                    filename: file.filename,
                  },
                ],
              })
            : updateCurrentDraft(publicId, {
                attachments: [
                  ...draft.current.attachments,
                  {
                    fileId: file.fileId,
                    filename: file.filename,
                  },
                ],
              });
        }}
        isTranslated={
          messageType === "note"
            ? draft.note.originalText !== ""
            : draft.current.originalText !== ""
        }
        onTranslate={handleTranslate}
        onUndo={() => {
          messageType === "note"
            ? updateNoteDraft(publicId, {
                text: draft.current.originalText,
                originalText: "",
              })
            : updateCurrentDraft(publicId, {
                text: draft.current.originalText,
                originalText: "",
              });
        }}
        showTranslate={
          !!targetLang &&
          conversation.enableTranslation &&
          messageType !== "note"
        }
        onRefine={onClickRefineMessage}
        isRefining={isRefining}
        businessId={conversation.businessId}
        onClickSendMessage={
          messageType === "note" ? onClickSendNote : onClickSendMessage
        }
        sendMessageDisabled={
          isRefining ||
          (messageType === "note" ? !draft.note.text : !draft.current.text)
        }
        messageType={messageType}
      />
    </MessageInputContainer>
  );
}
