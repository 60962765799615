import { toast } from "sonner";
import { Route } from "..";
import { workflowExportSchema } from "../-utils/workflowExportSchema";
import { requestAlertDialogDisplay } from "@dashboard/common/GlobalAlertDialog/GlobalAlertDialog";
import { v4 as uuidv4 } from "uuid";
import { getOrThrow } from "@dashboard/utils/map.utils";

export function useImportWorkflows() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const [currentWorkflows] = trpc.dashboard.flows.getAll.useSuspenseQuery({
    businessSlug,
  });

  const [currentThreats] = trpc.dashboard.threats.getAll.useSuspenseQuery({
    businessSlug,
  });

  const importWorkflowsMutation = trpc.dashboard.flows.import.useMutation();

  return {
    importWorkflows: () => {
      void getJsonString().then((jsonString) => {
        if (!jsonString) return;
        const data = validateAndParseJsonString(jsonString);
        if (!data) {
          toast.error("Import error: Invalid JSON provided");
          return;
        }
        const { threats: importedThreats, workflows: importedWorkflows } = data;

        const importedIdToExistingId = new Map<string, string>();
        const originalImportedThreatIdToNewThreadId = new Map<string, string>(
          importedThreats.map((t) => [t.id, uuidv4()]),
        );
        const threatsToCreate: {
          id: string;
          title: string;
          description: string;
          sampleStatements: string[];
        }[] = [];

        // For identical threats, we want to use their existing IDs when creating workflows
        // For non-identical threats, we want to create new threat with a unique title
        for (const importedThreat of importedThreats) {
          let title = importedThreat.title;
          let counter = 0;
          let foundIdenticalThreat = false;
          for (;;) {
            const existingThreat = currentThreats.find(
              (t) => t.title === title,
            );
            if (!existingThreat) {
              break;
            }
            const sameDescription =
              existingThreat.description === importedThreat.description;
            const sameSampleStatements = existingThreat.sampleStatements.every(
              (s) => importedThreat.sampleStatements.includes(s),
            );
            if (sameDescription && sameSampleStatements) {
              importedIdToExistingId.set(importedThreat.id, existingThreat.id);
              foundIdenticalThreat = true;
              break;
            }

            counter++;
            title = `${importedThreat.title} (${counter})`;
          }

          if (!foundIdenticalThreat) {
            threatsToCreate.push({
              id: getOrThrow(
                originalImportedThreatIdToNewThreadId,
                importedThreat.id,
              ),
              title,
              description: importedThreat.description,
              sampleStatements: importedThreat.sampleStatements,
            });
          }
        }

        const workflowsToCreate: typeof importedWorkflows = [];
        for (const importedWorkflow of importedWorkflows) {
          let title = importedWorkflow.title;
          let counter = 1;
          while (currentWorkflows.some((w) => w.title === title)) {
            title = `${importedWorkflow.title} (${counter})`;
            counter++;
          }

          workflowsToCreate.push({
            ...importedWorkflow,
            title,
            variants: importedWorkflow.variants.map((v) => ({
              ...v,
              associatedThreatIds: v.associatedThreatIds.map(
                (id) =>
                  importedIdToExistingId.get(id) ??
                  getOrThrow(originalImportedThreatIdToNewThreadId, id),
              ),
              ignoredThreatIds: v.ignoredThreatIds.map(
                (id) =>
                  importedIdToExistingId.get(id) ??
                  getOrThrow(originalImportedThreatIdToNewThreadId, id),
              ),
            })),
          });
        }

        let alertDescription = "";
        if (threatsToCreate.length > 0) {
          alertDescription += "The following threats will be created:\n";
          for (const threat of threatsToCreate) {
            alertDescription += `- ${threat.title}\n`;
          }
        }

        if (importedIdToExistingId.size > 0) {
          if (alertDescription.length > 0) {
            alertDescription += "\n\n";
          }
          alertDescription +=
            "\n\nThe following imported threats will be linked to existing threats:\n";
          for (const [
            importedId,
            existingId,
          ] of importedIdToExistingId.entries()) {
            const existingThreat = currentThreats.find(
              (t) => t.id === existingId,
            );
            const importedThreat = importedThreats.find(
              (t) => t.id === importedId,
            );
            if (!existingThreat || !importedThreat)
              throw new Error("Threat not found");
            alertDescription += `- ${importedThreat.title} -> ${existingThreat.title}\n`;
          }
        }

        if (workflowsToCreate.length > 0) {
          if (alertDescription.length > 0) {
            alertDescription += "\n\n";
          }
          alertDescription += "The following workflows will be created:\n";
          for (const workflow of workflowsToCreate) {
            alertDescription += `- ${workflow.title}\n`;
          }
        }

        if (alertDescription.length === 0) {
          toast.error("Import error: No threats or workflows to import");
          return;
        }

        requestAlertDialogDisplay({
          title: "Import workflows",
          description: alertDescription,
          onAction: () => {
            void importWorkflowsMutation
              .mutateAsync({
                businessSlug,
                threats: threatsToCreate,
                workflows: workflowsToCreate,
              })
              .then(async () => {
                await Promise.all([
                  trpcUtils.dashboard.flows.getAll.invalidate({
                    businessSlug,
                  }),
                  trpcUtils.dashboard.threats.getAll.invalidate({
                    businessSlug,
                  }),
                ]);
                toast.success("Workflows imported successfully");
              })
              .catch((error) => {
                toast.error("Failed to import workflows");
                console.error(error);
              });
          },
          actionText: "Import",
        });
      });
    },
  };
}

function getJsonString() {
  return new Promise<string | null>((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";

    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) {
        resolve(null);
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result !== "string") {
          reject(new Error("File is not a string when using readAsText"));
          return;
        }
        resolve(result);
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };

      reader.readAsText(file);
    };

    input.oncancel = () => {
      resolve(null);
    };

    input.click();
  });
}

function validateAndParseJsonString(jsonString: string) {
  let parsedJson: unknown;
  try {
    parsedJson = JSON.parse(jsonString) as unknown;
  } catch (error) {
    return;
  }

  const jsonValidationResult = workflowExportSchema.safeParse(parsedJson);

  if (!jsonValidationResult.success) {
    return;
  }

  return jsonValidationResult.data;
}
