import clsxm from "@chatbot/utils/clsxm";
import {
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { Sender } from "@be/modules/conversations/conversations.types";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { useConversations } from "@chatbot/hooks/conversations.hooks";
import type { Conversation } from "@chatbot/hooks/conversations.hooks";
import { useRef, useState } from "react";
import { DeepReadonlyObject } from "@chatbot/utils/types.utils";
import DisclaimerButton from "./Disclaimer/DisclaimerButton";
import { ReadonlyConfig, useConfig } from "@chatbot/hooks/config.hooks";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import { Avatar, BotAvatar } from "./Avatar";
import { Check } from "lucide-react";

export default function Container() {
  const config = useConfig();
  const {
    conversations,
    isLoading: isLoadingConversations,
    isActive: isActiveConversation,
    activeConversationPublicId,
  } = useConversations();

  const messageContainerRef = useRef<HTMLDivElement>(null);

  const [userScrolled, setUserScrolled] = useState(false);

  const scrollToBottom = useCallback(() => {
    requestAnimationFrame(() => {
      if (!userScrolled) {
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTo({
            top: messageContainerRef.current.scrollHeight,
            behavior: "auto",
          });
        }
      }
    });
  }, [userScrolled, messageContainerRef]);

  useEffect(() => {
    scrollToBottom();
    // We don't want to scroll to the bottom when scrollToBottom changes, since
    // it changes whenever user scrolls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => scrollToBottom());

    if (messageContainerRef.current) {
      const { current } = messageContainerRef;
      resizeObserver.observe(current);

      return () => {
        resizeObserver.unobserve(current);
      };
    }
  }, [scrollToBottom]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!messageContainerRef.current) {
      return;
    }
    switch (event.key) {
      case "ArrowDown":
        messageContainerRef.current.scrollBy({ top: 20, behavior: "smooth" });
        break;
      case "ArrowUp":
        messageContainerRef.current.scrollBy({ top: -20, behavior: "smooth" });
        break;
      case "PageDown":
        messageContainerRef.current.scrollBy({ top: 200, behavior: "smooth" });
        break;
      case "PageUp":
        messageContainerRef.current.scrollBy({ top: -200, behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      tabIndex={0}
      ref={messageContainerRef}
      className="flex w-full flex-1 flex-col overflow-y-scroll"
      onScroll={(e) => {
        const { scrollHeight, clientHeight, scrollTop } = e.currentTarget;
        const isScrolledToBottom =
          Math.ceil(scrollTop) + clientHeight >= scrollHeight;
        setUserScrolled(!isScrolledToBottom);
      }}
    >
      {config.enableDisclaimer && (
        <div className="sticky right-0 top-2 ml-auto h-0 w-fit">
          <DisclaimerButton />
        </div>
      )}

      <div className="flex-1 px-6 pt-5">
        {isLoadingConversations ? (
          <div className="flex h-full w-full items-center justify-center">
            <TailSpin height={48} color="black" />
          </div>
        ) : (
          conversations.map((conversation, conversationIdx) => (
            <div key={conversationIdx}>
              {conversationIdx > 0 && (
                <ConversationSeparator createdAt={conversation.createdAt} />
              )}

              <Conversation
                conversation={conversation}
                isPending={
                  !isActiveConversation &&
                  conversationIdx === conversations.length - 1
                }
                isFirst={conversationIdx === 0}
                config={config}
              />
            </div>
          ))
        )}
      </div>

      <div className="shrink-0 pb-2 text-center">
        {/* {bot.feedbackMessage && <Feedback message={bot.feedbackMessage} />} */}

        {config.enableOctocomBranding && (
          <>
            <a
              href={`https://www.octocom.ai/?ref=web-chat`}
              target="blank"
              className="text-xs font-light text-gray-700 hover:text-black"
            >
              Powered by Octocom{" "}
            </a>
            <span className="text-xs font-light text-gray-700">
              {activeConversationPublicId
                ? `(id: ${activeConversationPublicId})`
                : ""}
            </span>
          </>
        )}
      </div>
    </div>
  );
}

function Conversation({
  conversation,
  isPending,
  isFirst,
  config,
}: {
  conversation: DeepReadonlyObject<Conversation>;
  isPending: boolean;
  isFirst: boolean;
  config: ReadonlyConfig;
}) {
  const { isGeneratingBotResponse, sendMessage, agreedToTermsAndConditions } =
    useConversations();

  return (
    <>
      {conversation.messages.map((message, messageIdx) => {
        if (message.sender === "bot" || message.sender === "agent") {
          if (
            message.sender === "bot" &&
            message.text === "" &&
            isGeneratingBotResponse
          ) {
            return <LoadingIndicator key={messageIdx} />;
          } else {
            const displayTermsAndConditionsAgreementBubble =
              config.requireTermsAndConditionsAgreement &&
              messageIdx === 0 &&
              isFirst;

            const displayEmailRequestBubble =
              config.requestEmailBeforeChat && messageIdx === 0 && isFirst;

            return (
              <>
                {message.text.split("\u200B").map((textPart, index) => (
                  <ReceivedMessage
                    aria-label="Customer support message"
                    key={`${messageIdx}-${index}`}
                    text={textPart}
                    files={index === 0 ? message.files : undefined}
                    sender={message.sender}
                    agentName={message.agentName}
                    nextSender={
                      index === message.text.split("\u200B").length - 1 &&
                      !displayTermsAndConditionsAgreementBubble &&
                      displayEmailRequestBubble
                        ? "bot"
                        : conversation.messages.at(messageIdx + 1)?.sender
                    }
                  />
                ))}
                {displayTermsAndConditionsAgreementBubble && (
                  <TermsAndConditionsAgreementBubble />
                )}
                {displayEmailRequestBubble &&
                  (!displayTermsAndConditionsAgreementBubble ||
                    agreedToTermsAndConditions) && <EmailRequestBubble />}
              </>
            );
          }
        } else {
          return (
            <SentMessage
              key={messageIdx}
              text={message.text}
              files={message.files}
            />
          );
        }
      })}
      {isPending && config.firstMessageSuggestions.length > 0 && (
        <div className="flex flex-wrap justify-end gap-x-2.5 gap-y-3">
          {config.firstMessageSuggestions.map((suggestion) => (
            <button
              key={suggestion.id}
              className="flex items-center justify-center rounded-full px-4 py-2.5 text-sm leading-none"
              style={{
                backgroundColor:
                  config.customerMessageBackgroundColor ?? "#000000",
                color: config.customerMessageTextColor ?? "#ffffff",
              }}
              onClick={() =>
                sendMessage({ text: suggestion.messageText, fileIds: [] })
              }
            >
              {suggestion.displayText}
            </button>
          ))}
        </div>
      )}
    </>
  );
}

function validateEmail(email: string) {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}

function TermsAndConditionsAgreementBubble() {
  const config = useConfig();
  const { closeChatWindow } = useUiState();
  const { setAgreedToTermsAndConditions, agreedToTermsAndConditions } =
    useConversations();

  return (
    <div className="mb-4 flex items-center">
      <div className="ml-auto flex gap-3">
        {[
          { value: "Yes", selected: agreedToTermsAndConditions },
          { value: "No", selected: agreedToTermsAndConditions === false },
        ].map((option) => (
          <button
            key={option.value}
            className="chatbot-message-container group relative max-w-[75%] break-words rounded-lg	px-[20px] py-[12px] text-sm leading-[1.4]"
            disabled={agreedToTermsAndConditions === true}
            onClick={() => {
              if (option.value === "Yes") {
                setAgreedToTermsAndConditions(true);
              } else {
                setAgreedToTermsAndConditions(false);
                closeChatWindow();
              }
            }}
            style={{
              backgroundColor:
                config.customerMessageBackgroundColor ??
                (option.selected ? "#000000" : "#000000A6"),
              color: config.customerMessageTextColor ?? "#ffffff",
            }}
          >
            <p>{option.value}</p>
          </button>
        ))}
      </div>
    </div>
  );
}

function EmailRequestBubble() {
  const [typedEmail, setTypedEmail] = useState("");
  const [error, setError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const { setEmail, email } = useConversations();

  const onSubmit = () => {
    if (!typedEmail) {
      return;
    } else if (!validateEmail(typedEmail)) {
      setError("Please enter a valid email address");
      return;
    }

    setEmail(typedEmail);
    setError("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const config = useConfig();

  return (
    <div className="mb-4 flex items-center">
      <BotAvatar />
      <div
        className="flex grow rounded-md px-6 pb-6 pt-7"
        style={{
          paddingBottom: "24px",
          paddingTop: "28px",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 15px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, rgba(0, 0, 0, 0.5) 0px 2px 0px 0px inset",
        }}
      >
        <div className="w-full">
          <label
            htmlFor="email"
            className="block text-sm leading-6 text-gray-600"
          >
            Email
          </label>
          <div className="relative w-full">
            <div className="mt-1 block  w-full ">
              <input
                ref={inputRef}
                type="email"
                name="email"
                id="email"
                className="box-border h-10 w-full rounded border border-[#e1e1e1] pb-[11px] pl-[16px] pr-[40px] pt-[11px] text-sm outline-0 ring-0 disabled:text-gray-500"
                style={{
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "40px",
                  paddingTop: "11px",
                  boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 3px 0px inset",
                }}
                placeholder="email@example.com"
                defaultValue={email ?? ""}
                disabled={!!email}
                onChange={(e) => setTypedEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button
                className="absolute bottom-0 right-0 top-0 flex w-10 items-center justify-center rounded-r-[3px] bg-black disabled:bg-gray-700"
                style={{
                  borderTopRightRadius: "3px",
                  borderBottomRightRadius: "3px",
                  backgroundColor:
                    config.customerMessageBackgroundColor ?? undefined,
                }}
                disabled={!!email}
                onClick={onSubmit}
              >
                <span className="m-auto">
                  {email ? (
                    <Check className="h-3 w-3 text-white" strokeWidth={3} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={3}
                      stroke="white"
                      className="h-3 w-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  )}
                </span>
              </button>
            </div>
          </div>
          {error && (
            <p className="mt-2 text-sm font-light text-red-500">{error}</p>
          )}
        </div>
      </div>
    </div>
  );
}

function ReceivedMessage({
  text,
  files,
  sender,
  agentName,
  nextSender,
}: {
  text: string;
  files?: DeepReadonlyObject<
    {
      url: string;
      name: string;
      contentType: string;
    }[]
  >;
  sender: Sender;
  agentName?: string;
  nextSender?: Sender;
}) {
  let content = text;
  if (files) {
    content +=
      "\n\n" +
      files
        .map((file) => {
          if (file.contentType.startsWith("image")) {
            return `![${file.name}](${file.url})`;
          } else {
            return `[${file.name}](${file.url})`;
          }
        })
        .join("\n");
  }

  const htmlContent = useMemo(() => {
    const rawHtml = marked(content);
    const sanitizedHtml = DOMPurify.sanitize(rawHtml);

    const doc = new DOMParser().parseFromString(sanitizedHtml, "text/html");

    doc.body.querySelectorAll("a").forEach((link) => {
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
      link.style.removeProperty("color");
      link.style.removeProperty("text-decoration");
    });

    const productLinks =
      doc.querySelectorAll<HTMLAnchorElement>("a[href*='/p/']");
    const images = doc.querySelectorAll<HTMLImageElement>("img");

    images.forEach((img, index) => {
      const title = img.getAttribute("title");

      if (title === "half-size") {
        img.style.width = "50%";
      }

      const productLink = productLinks[index] as HTMLAnchorElement | undefined;
      if (productLink && img.parentNode) {
        const clonedLink = productLink.cloneNode(true) as HTMLAnchorElement;
        clonedLink.innerHTML = "";
        img.parentNode.insertBefore(clonedLink, img);
        clonedLink.appendChild(img);
      }
    });

    // Add styling for code blocks
    doc.body.querySelectorAll("pre code").forEach((codeBlock) => {
      codeBlock.classList.add(
        "block",
        "p-4",
        "my-2",
        "overflow-x-auto",
        "rounded-md",
        "bg-gray-100",
        "font-mono",
        "text-sm",
        "w-full",
        "max-w-full",
      );

      // Add a wrapper div to handle overflow properly
      const wrapper = doc.createElement("div");
      wrapper.classList.add("max-w-full", "overflow-x-auto");
      codeBlock.parentNode?.insertBefore(wrapper, codeBlock);
      wrapper.appendChild(codeBlock);
    });

    // Add styling for inline code
    doc.body.querySelectorAll("code:not(pre code)").forEach((inlineCode) => {
      inlineCode.classList.add(
        "px-1.5",
        "py-0.5",
        "rounded",
        "bg-gray-100",
        "font-mono",
        "text-sm",
      );
    });

    return doc.body.innerHTML;
  }, [content]);

  const { isReadyToSendMessage } = useConversations();

  const config = useConfig();

  return (
    <MessageBase>
      <Avatar
        sender={sender}
        agentName={agentName ?? "agent"}
        invisible={nextSender === "bot"}
      />
      <MessageBubble
        backgroundColor={config.botMessageBackgroundColor ?? "#F5F5F5"}
        color={config.botMessageTextColor ?? "#000000"}
      >
        <div
          tabIndex={0}
          aria-label={`Customer support message: ${text}`}
          className={clsxm(
            "chatbot-message-container",
            isReadyToSendMessage ? "" : "hide-images",
          )}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </MessageBubble>
    </MessageBase>
  );
}

function MessageBase({ children }: { children: ReactNode }) {
  return <div className="mb-4 flex items-center">{children}</div>;
}

function SentMessage({
  text,
  files,
}: {
  text: string;
  files?: DeepReadonlyObject<
    {
      url: string;
      name: string;
      contentType: string;
    }[]
  >;
}) {
  let content = text;
  if (files) {
    content +=
      "\n\n" +
      files
        .map((file) => {
          if (file.contentType.startsWith("image")) {
            return `![${file.name}](${file.url})`;
          } else {
            return `[${file.name}](${file.url})`;
          }
        })
        .join("\n");
  }

  const htmlContent = useMemo(() => {
    const rawHtml = marked(content);
    const sanitizedHtml = DOMPurify.sanitize(rawHtml);

    const doc = new DOMParser().parseFromString(sanitizedHtml, "text/html");

    doc.body.querySelectorAll("a").forEach((link) => {
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
    });

    return doc.body.innerHTML;
  }, [content]);

  const config = useConfig();

  return (
    <MessageBase>
      <MessageBubble
        backgroundColor={config.customerMessageBackgroundColor ?? "#000000"}
        color={config.customerMessageTextColor ?? "#ffffff"}
        align="right"
      >
        {/* <p>{text}</p> */}
        <div
          tabIndex={0}
          aria-label={`Customer message: ${text}`}
          className={clsxm(
            "chatbot-message-container",
            // isReadyToSendMessage ? "" : "hide-images",
          )}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </MessageBubble>
    </MessageBase>
  );
}

function MessageBubble({
  children,
  backgroundColor,
  color,
  align = "left",
}: {
  children: ReactNode;
  backgroundColor: string;
  color: string;
  align?: "left" | "right";
}) {
  return (
    <div
      className={clsxm(
        "group relative max-w-[75%] break-words rounded-lg px-[20px]	py-[17px] text-sm leading-[1.4]",
        align === "left" ? "mr-auto" : "ml-auto",
      )}
      style={{
        backgroundColor,
        color,
      }}
    >
      {children}
    </div>
  );
}

function ConversationSeparator({ createdAt }: { createdAt: string }) {
  return (
    <div
      key="agent-separator"
      className="relative mb-8 mt-8"
      style={{ marginBottom: "32px", marginTop: "32px" }}
    >
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div
          className="w-full border-t-[0.5px] border-gray-300"
          style={{ borderTopWidth: "0.5px" }}
        />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-xs font-extralight text-gray-500">
          {formatLocalShortDateTime(createdAt)}
        </span>
      </div>
    </div>
  );
}

function formatLocalShortDateTime(isoString: string) {
  const date = new Date(isoString);

  return new Intl.DateTimeFormat(navigator.language, {
    dateStyle: "short",
    timeStyle: "short",
  }).format(date);
}

function LoadingIndicator() {
  return (
    <MessageBase>
      <BotAvatar />
      <div className="-ml-4 mb-3">
        <ThreeDots height={9} color="gray" />
      </div>
    </MessageBase>
  );
}
