import { useCallback, useEffect, useRef, useState } from "react";
import { Route } from "..";
import { useConversation } from "../-hooks/useConversation.hook";
import { Message } from "./Message/Message";
import { Event } from "./Event";
import { MessageInput } from "./Input/MessageInput";
import { ContentTopBar } from "./ContentTopBar";
import { determineReplyChannel } from "../-utils/showInput";
import { useSubscription } from "../-hooks/useSubscription";
import { FeedbackFormInput } from "./FeedbackFormInput";
import { useFeedback } from "../-hooks/useFeedback";
import { format } from "date-fns";
import { ConversationTabs } from "./ConversationTabs";
import { Note } from "./Note";

export function ConversationContent() {
  const { user, hasExternalTicketConfig } = Route.useRouteContext();
  const { publicId } = Route.useParams();

  const conversation = useConversation();

  const [activeTab, setActiveTab] = useState<"customer" | "external">(
    conversation.type,
  );
  const [externalTabValue, setExternalTabValue] = useState<string | null>(
    conversation.type === "external"
      ? publicId
      : conversation.externalConversations[0]?.publicId ?? null,
  );

  const feedback = useFeedback();
  const showFeedbackForm =
    !user.isAdmin &&
    conversation.enableFeedback &&
    !feedback &&
    activeTab === "customer";

  const [showFeedbackInput, setShowFeedbackInput] = useState(showFeedbackForm);

  const customerConversationId = conversation.customerConversationId;

  useEffect(() => {
    setExternalTabValue(
      conversation.type === "external"
        ? publicId
        : conversation.externalConversations[0]?.publicId ?? null,
    );
  }, [customerConversationId]);

  useEffect(() => {
    setShowFeedbackInput(showFeedbackForm);
  }, [showFeedbackForm, conversation]);

  const { showInput, replyChannel, customInput } = determineReplyChannel({
    activeTab,
    hasExternalTicketConfig,
    customerEmailAvailable: !!conversation.customer,
    emailIntegrationAvailable: !!conversation.emailIntegration,
    ...conversation,
  });

  const conversationContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    requestAnimationFrame(() => {
      if (conversationContainerRef.current) {
        conversationContainerRef.current.scrollTo({
          top: conversationContainerRef.current.scrollHeight,
          behavior: "auto",
        });
      }
    });
  }, [conversationContainerRef]);

  useSubscription();

  useEffect(() => {
    scrollToBottom();
  }, [conversation, scrollToBottom]);

  const handleOpenNewExternalThread = () => {
    setExternalTabValue("new");
    setActiveTab("external");
  };

  return (
    <div className="bg-background flex h-full flex-grow flex-col">
      <ContentTopBar
        handleOpenNewExternalThread={handleOpenNewExternalThread}
      />

      <ConversationTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        externalTabValue={externalTabValue}
        setExternalTabValue={setExternalTabValue}
      />

      <div
        className="flex-grow overflow-auto px-2 pb-5 pt-3"
        ref={conversationContainerRef}
      >
        {activeTab === "external" && externalTabValue === "new"
          ? null // we seriously need to find a fix for dates becoming strings when they are sent to the frontend!!!
          : //Or at the very least, decide if we want to send DATES that become strings, or turn them into strings in the backend
            conversation.parts.map((part) =>
              part.type === "message" ? (
                <Message key={part.id} message={part} />
              ) : part.type === "note" ? (
                <Note
                  key={part.id}
                  text={part.text}
                  createdAt={part.createdAt as unknown as string}
                  agentName={part.agentName}
                  files={part.files}
                />
              ) : (
                <Event
                  key={part.id}
                  event={part.event}
                  createdAt={part.createdAt}
                  metadata={part.metadata}
                />
              ),
            )}
        {conversation.snoozedUntil && (
          <span className="flex flex-row-reverse pr-10 pt-3 text-sm font-normal text-gray-500">
            Conversation Snoozed until{" "}
            {format(conversation.snoozedUntil, "MMM d, yyyy HH:mm")}
          </span>
        )}
      </div>

      {showFeedbackInput ? (
        <FeedbackFormInput showMessageInput={showInput} />
      ) : (
        showInput && (
          <MessageInput
            isNewExternalOpen={
              externalTabValue === "new" && activeTab === "external"
            }
            replyChannel={replyChannel}
            customInput={customInput}
          />
        )
      )}
    </div>
  );
}
