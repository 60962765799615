import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { ReactNode } from "react";

export function ConfigurationAccordionItem({
  value,
  title,
  children,
}: {
  value: string;
  title: string;
  children: ReactNode;
}) {
  return (
    <AccordionItem value={value} className="border-b-0 !shadow-none">
      <AccordionTrigger className="hover:bg-gray-light rounded-lg px-[12px] py-2">
        <span className="text-sm font-semibold text-black">{title}</span>
      </AccordionTrigger>
      <AccordionContent className="pb-0">{children}</AccordionContent>
    </AccordionItem>
  );
}
