import { createFileRoute } from "@tanstack/react-router";
import shopifyLogo from "@dashboard/assets/shopify.svg";
import { Button } from "@dashboard/common/ui/button";
import { Check } from "lucide-react";
import { useOrganization } from "./-hooks/useOrganization";
import { OnboardingNavigation } from "./-components/OnboardingNavigation";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/platform",
)({
  component: PlatformPage,
});

function PlatformPage() {
  const organization = useOrganization();

  const stepCompleted = organization.onboardingStatus !== "PLATFORM";

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Connect your Shopify store
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Octocom will connect with your Shopify store to get real-time product
        and order data.
      </h2>
      <div className="w-full max-w-3xl py-8">
        {stepCompleted ? (
          <Button
            variant="outline"
            className="h-10 w-full cursor-default justify-between border-[#041627] font-semibold"
          >
            <div className="flex items-center">
              <img
                src={shopifyLogo}
                alt="Shopify Logo"
                className="mr-4 h-6 w-6"
              />
              Shopify has been successfully connected
            </div>
            <Check className="h-6 w-6" strokeWidth={1.5} />
          </Button>
        ) : (
          <a
            href="https://apps.shopify.com/ai-support"
            target="_self"
            className="inline-flex h-10 w-full items-center justify-start rounded-md border border-[#041627] bg-white px-4 text-sm font-semibold text-[#041627] hover:bg-gray-50"
          >
            <img
              src={shopifyLogo}
              alt="Shopify Logo"
              className="mr-4 h-6 w-6"
            />
            Connect Shopify
          </a>
        )}
        <OnboardingNavigation
          nextPath="/organization/$organizationSlug/onboarding/tone"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}
