import {
  Accordion,
  AccordionTrigger,
  AccordionItem,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { Route } from "../../..";
import { useReturns } from "../../../-hooks/returns/useReturns";
import { Loader2 } from "lucide-react";
import { ReturnDetails } from "./ReturnDetails";
import { Separator } from "@dashboard/common/ui/separator";
export function Returns() {
  const { trpc } = Route.useRouteContext();
  const { publicId, organizationSlug } = Route.useParams();
  const { returns, isLoading } = useReturns();

  const [hasAftershipIntegration] =
    trpc.dashboard.conversations.returns.hasReturnsIntegration.useSuspenseQuery(
      {
        organizationSlug,
        publicId,
      },
    );

  if (!hasAftershipIntegration) {
    return null;
  }

  return (
    <Accordion
      type="multiple"
      defaultValue={["returns-details"]}
      className="w-full"
    >
      <AccordionItem
        value="returns-details"
        className="border-none !shadow-none"
      >
        <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
          Returns
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          {isLoading ? (
            <div className="flex items-center justify-center p-4 pb-0">
              <Loader2
                className="h-8 w-8 animate-spin text-gray-500"
                strokeWidth={1.5}
              />
              <span className="ml-2 text-sm text-gray-500">
                Loading returns...
              </span>
            </div>
          ) : !returns || returns.length === 0 ? (
            <div className="px-7 pt-4 text-sm text-gray-500">
              No returns found for this customer.
            </div>
          ) : (
            <div>
              <Accordion
                type="single"
                collapsible
                className="max-h-160 mt-5 flex flex-col gap-1 overflow-y-scroll pb-0 pl-4 pr-4"
              >
                {returns.map((returnItem, index) => (
                  <div key={returnItem.data.id}>
                    <ReturnDetails returnItem={returnItem} />
                    {index !== returns.length - 1 && (
                      <Separator className="bg-offWhite mx-3 w-auto" />
                    )}
                  </div>
                ))}
              </Accordion>
            </div>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
