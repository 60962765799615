import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";

import { Switch } from "@dashboard/common/ui/switch";
import { Button } from "@dashboard/common/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";

import {
  AccountSettingsForm,
  accountSettingsFormSchema,
} from "./-utils/accountSettingsFormSchema";

import { useSubmitForm } from "./-hooks/useSubmitForm";
import { useNotificationEffect } from "../../-hooks/useNotificationEffect";
import { useState } from "react";
import { Slider } from "@dashboard/common/ui/slider";
import { Select } from "@dashboard/common/ui/select";
import { Separator } from "@dashboard/common/ui/separator";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/account-settings/",
)({
  component: AccountSettingsPage,
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.users.getSupportedLanguages.ensureData({
      organizationSlug,
    });
  },
});

function AccountSettingsPage() {
  const [submitCount, setSubmitCount] = useState(0);
  const { user, trpc } = Route.useRouteContext();

  const { organizationSlug } = Route.useParams();

  const [languages] =
    trpc.dashboard.users.getSupportedLanguages.useSuspenseQuery({
      organizationSlug,
    });

  const defaultValues: AccountSettingsForm = {
    ...user,
    notificationSoundLevel: [user.notificationSoundLevel],
    translationLanguage: user.translationLanguage,
  };

  const form = useForm<AccountSettingsForm>({
    resolver: zodResolver(accountSettingsFormSchema),
    defaultValues: defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm({ setSubmitCount });

  const enableNotifications = form.watch("enableNotifications");
  const enableNotificationSound = form.watch("enableNotificationSound");
  const notificationSoundLevel = form.watch("notificationSoundLevel");

  useNotificationEffect({
    settings: { enableNotifications, enableNotificationSound },
    submitCount,
  });

  async function playNotificationSound() {
    const audio = new Audio(`/notification-${notificationSoundLevel[0]}.wav`);
    await audio.play();
  }

  return (
    <div className="relative py-0">
      <Form {...form}>
        <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">Your Account</h1>
            <Button type="submit" variant="publish" className="ml-auto">
              {form.formState.isSubmitting ? "Submitting..." : "Save"}
            </Button>
          </div>

          <div className="px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <div className="flex flex-col gap-5">
                <h3 className="text-lg font-medium text-black">
                  Live Chat Notification Settings
                </h3>
                <FormField
                  control={form.control}
                  name="enableNotifications"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-start gap-4 space-y-0">
                      <FormLabel className="w-64">
                        Enable Browser Notifications:
                      </FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="enableNotificationSound"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-start gap-4 space-y-0">
                      <FormLabel className="w-64">
                        Enable Browser Notifications Sound:
                      </FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="notificationSoundLevel"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-start gap-4 space-y-0">
                      <FormLabel className="w-64">
                        Browser Notification Sound Level:
                      </FormLabel>
                      <FormControl>
                        <Slider
                          className="w-40"
                          value={field.value}
                          onValueChange={field.onChange}
                          min={1}
                          max={5}
                          step={1}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  className="w-full"
                  type="button"
                  onClick={() => void playNotificationSound()}
                >
                  Test Notification Sound
                </Button>
              </div>

              <Separator />

              <div className="flex flex-col gap-5">
                <h3 className="text-lg font-medium text-black">
                  Ticket Assignment Notification Settings
                </h3>

                <FormField
                  control={form.control}
                  name="enableEmailNotifications"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-start gap-4 space-y-0">
                      <FormLabel className="w-64">
                        Enable Email Notifications:
                      </FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <Separator />

              <div className="flex flex-col gap-5">
                <h3 className="text-lg font-medium text-black">
                  Translation Settings
                </h3>
                <FormField
                  control={form.control}
                  name="translationLanguage"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-start gap-4 space-y-0">
                      <FormLabel className="w-64">
                        Translation language:
                      </FormLabel>
                      <FormControl>
                        <Select
                          value={field.value}
                          onChange={field.onChange}
                          options={Object.keys(languages).map((key) => ({
                            label: languages[key].nativeName,
                            value: key,
                          }))}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
