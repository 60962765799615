import { toast } from "sonner";
import { Route } from "..";
import { UseFormSetError } from "react-hook-form";
import { UserForm } from "../-utils/userFormSchema";

export function useUpdateUser({
  setError,
  setOpen,
}: {
  setError: UseFormSetError<UserForm>;
  setOpen: (open: boolean) => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const updateUserMutation = trpc.dashboard.users.update.useMutation({
    onSuccess: async (data) => {
      if (data.success) {
        await trpcUtils.dashboard.users.getOrgUsers.invalidate();
        toast.success("User updated successfully.");
        setOpen(false);
      } else {
        setError("email", {
          type: "manual",
          message: data.error,
        });
      }
    },
    onError: (error) => {
      toast.error("Failed to update user.");
      console.error(error);
    },
  });

  return {
    updateUser: ({
      id,
      fullName,
      email,
      isManager,
      isAgent,
    }: {
      id: string;
      fullName: string;
      email: string;
      isManager: boolean;
      isAgent: boolean;
    }) => {
      updateUserMutation.mutate({
        organizationSlug,
        data: { id, fullName, email, isManager, isAgent },
      });
    },
  };
}
