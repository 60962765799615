export function StatusDefinitions() {
  const statusDefinitions = [
    {
      status: "Unfulfilled",
      description:
        "All of the unfulfilled items in the order scheduled for fulfillment at later time",
    },
    {
      status: "On Hold",
      description: "All of the unfulfilled items in this order are on hold",
    },
    {
      status: "In Transit",
      description: "The order is in transit to the customer",
    },
    {
      status: "Delivered",
      description: "The order has been delivered to the customer",
    },
    {
      status: "Not Delivered",
      description: "The delivery was not successful",
    },
    {
      status: "Out for Delivery",
      description: "The order is in transit to the customer",
    },
    {
      status: "Closed",
      description: "The order has been completed and closed",
    },
    {
      status: "Attempted Delivery",
      description: "The courier has attempted to deliver the order, but failed",
    },
  ];

  return (
    <>
      <div className="bg-gray-lightest rounded-t-lg p-4">
        <h3 className="text-primary text-sm font-semibold">
          Order Status Definitions
        </h3>
      </div>
      <div className="max-h-[60vh] overflow-auto p-2">
        <table className="w-full">
          <tbody className="divide-y divide-gray-100">
            {statusDefinitions.map(({ status, description }) => (
              <tr key={status} className="transition-colors hover:bg-gray-50">
                <td className="text-primary whitespace-nowrap px-4 py-3 text-sm font-medium">
                  {status}
                </td>
                <td className="px-4 py-3 text-sm text-gray-600">
                  {description}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
