import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@dashboard/common/ui/dialog";

export function TemplateDialog({
  name,
  content,
}: {
  name: string;
  content: string;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="pl-0" variant="link">
          {name}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] overflow-scroll">
        <DialogHeader>
          <DialogTitle className="mx-auto">{name}</DialogTitle>
        </DialogHeader>
        <MarkdownContent content={content} />
      </DialogContent>
    </Dialog>
  );
}
