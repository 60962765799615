import { z } from "zod";

export const accountSettingsFormSchema = z.object({
  enableNotifications: z.boolean(),
  enableNotificationSound: z.boolean(),
  notificationSoundLevel: z.array(z.number().int().min(1).max(5)).length(1),
  enableEmailNotifications: z.boolean(),
  translationLanguage: z.string(),
});

export type AccountSettingsForm = z.infer<typeof accountSettingsFormSchema>;
