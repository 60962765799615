import { Link, createFileRoute, redirect } from "@tanstack/react-router";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { Button } from "@dashboard/common/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateTag } from "../-hooks/useUpdateTag";
import {
  customerTagFormSchema,
  CustomerTagForm,
} from "../-utils/tagFormSchema";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/customer-tags/$tagId/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug, tagId, organizationSlug },
  }) => {
    const tag = await trpcClientUtils.dashboard.customers.getTag.ensureData({
      businessSlug,
      id: tagId,
    });

    if (!tag) {
      throw redirect({
        to: "/organization/$organizationSlug/settings/$businessSlug/customer-tags",
        params: { organizationSlug, businessSlug },
      });
    }
  },
  component: EditCustomerTagPage,
});

function EditCustomerTagPage() {
  const { organizationSlug, businessSlug, tagId } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [tag] = trpc.dashboard.customers.getTag.useSuspenseQuery({
    businessSlug,
    id: tagId,
  });

  const form = useForm<CustomerTagForm>({
    resolver: zodResolver(customerTagFormSchema),
    defaultValues: {
      key: tag?.key ?? "",
      values: tag?.values.join(",") ?? "",
      llmInstructions: tag?.llmInstructions ?? "",
    },
  });

  const { onSubmit, onError } = useUpdateTag();

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}
        className="relative py-0"
      >
        <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
          <h1 className="text-xl font-medium text-black">
            Create a new customer tag
          </h1>
          <div className="flex items-center gap-2">
            <Link
              to="/organization/$organizationSlug/settings/$businessSlug/customer-tags"
              params={{ organizationSlug, businessSlug }}
            >
              <Button
                variant="secondary"
                className="flex items-center"
                type="button"
              >
                Back to tags
              </Button>
            </Link>

            <Button
              variant="publish"
              className="flex items-center"
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>

        <div className="flex gap-10 px-10 py-5">
          <div className="flex w-full max-w-xl flex-col gap-5">
            <FormField
              control={form.control}
              name="key"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tag Key</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription>
                    A unique key to identify the kind of information the tag
                    contains, e.g. &quot;preferred_brand&quot;,
                    &quot;gender&quot;, etc.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="llmInstructions"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Instructions & Rules for Tag Extraction</FormLabel>
                  <FormControl>
                    <Textarea
                      rows={5}
                      placeholder="Extract the customer's preferred brand from the conversation, only if the customer mentions it explicitly."
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Instructions on how the values for this tag should be
                    extracted from conversations with the customer
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="values"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Allowed Values</FormLabel>
                  <FormControl>
                    <Textarea
                      rows={5}
                      placeholder="Nike, Adidas, Puma, etc."
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    A list of possible values for this tag, separated by commas
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </form>
    </Form>
  );
}
