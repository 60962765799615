import {
  filtersSchema,
  viewSchema,
  statusSchema,
  sortBySchema,
} from "@be/modules/dashboard/conversations/conversations.schemas";
import { z } from "zod";

export const searchParamsSchema = z.object({
  searchQuery: z.string().optional().catch(undefined),
  filters: filtersSchema,
  status: statusSchema,
  sortBy: sortBySchema,
  view: viewSchema,
  businessSlug: z.string().optional(),
});

export type SearchParams = z.infer<typeof searchParamsSchema>;
