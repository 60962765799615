import { Outlet, createFileRoute } from "@tanstack/react-router";

import {
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "@dashboard/common/ui/command";

import { ConversationsFilters } from "./-components/ConversationFilters";
import { ConversationsList } from "./-components/ConversationsList";
import { ConversationTopBar } from "./-components/ConversationTopBar";
import { DraftMessagesProvider } from "./-context/DraftMessagesContext";
import { z } from "zod";
import { SelectionProvider, useSelection } from "./-context/SelectionContext";
import { CircleArrowOutUpRight, PanelBottomClose, X } from "lucide-react";
import { TooltipButton } from "@dashboard/common/TooltipButton/TooltipButton";
import { useEffect, useState } from "react";
import { useCloseConversations } from "./-hooks/actions/useCloseConversations";
import { useAssignConversations } from "./-hooks/actions/useAssignConversations";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList",
)({
  loaderDeps: ({ search: { view, filters, status, sortBy, searchQuery } }) => ({
    view,
    filters,
    status,
    sortBy,
    searchQuery,
  }),
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
    deps: { view, filters, status, sortBy, searchQuery },
  }) => {
    await Promise.all([
      trpcClientUtils.dashboard.conversations.getAll.ensureData({
        organizationSlug,
        view,
        filters,
        status,
        sortBy,
        searchQuery,
      }),

      trpcClientUtils.dashboard.conversations.getCount.ensureData({
        organizationSlug,
        view,
        status,
        filters,
        searchQuery,
      }),

      trpcClientUtils.dashboard.conversations.getSubscriptionToken.ensureData({
        organizationSlug,
        view,
        filters,
        status,
      }),

      trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
        organizationSlug,
      }),
    ]);
  },
  component: () => (
    <SelectionProvider>
      <ConversationListLayout />
    </SelectionProvider>
  ),
});

function ConversationListLayout() {
  const { selectedCards } = useSelection();

  return (
    <DraftMessagesProvider>
      <div className="flex h-full w-full">
        <ConversationsListSection />
        {selectedCards.size === 0 ? <Outlet /> : <SelectionPanel />}
      </div>
    </DraftMessagesProvider>
  );
}

function ConversationsListSection() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const search = Route.useSearch();
  const { selectedCards, deselectAll } = useSelection();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const [views] = trpc.dashboard.views.getAll.useSuspenseQuery({
    organizationSlug,
  });

  useEffect(() => {
    deselectAll();
  }, [search]);

  const title = getViewTitle({
    view: search.view,
    users,
    views,
  });

  return (
    <div className="bg-background border-r-border flex w-[24%] min-w-[250px] max-w-[460px] flex-col border-r">
      <ConversationTopBar>
        <h2 className="text-xl font-medium text-black">{title}</h2>
      </ConversationTopBar>

      {selectedCards.size > 0 ? (
        <SelectionModeFilters />
      ) : (
        <ConversationsFilters />
      )}
      <ConversationsList />
    </div>
  );
}

function SelectionPanel() {
  const { selectedCards } = useSelection();

  return (
    <div className="flex h-full flex-grow flex-col items-center justify-center gap-2 bg-gray-50 p-4">
      <div className="text-lg">{selectedCards.size} items selected</div>
      <div className="text-sm text-gray-500">
        You can bulk assign or close selected conversations.
      </div>
    </div>
  );
}

function SelectionModeFilters() {
  const { selectedCards, deselectAll } = useSelection();
  const [isCommandOpen, setIsCommandOpen] = useState(false);

  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { view, filters, status, searchQuery } = Route.useSearch();

  const [count] = trpc.dashboard.conversations.getCount.useSuspenseQuery({
    organizationSlug,
    view,
    status,
    filters,
    searchQuery,
  });

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const closeConversations = useCloseConversations();

  const handleCloseConversations = () => {
    deselectAll();
    closeConversations({
      publicIds: Array.from(selectedCards.values()),
    });
  };

  const assignConversations = useAssignConversations();

  const handleAssignConversations = (assigneeId: string) => {
    deselectAll();

    assignConversations({
      publicIds: Array.from(selectedCards.values()),
      assigneeId,
    });

    setIsCommandOpen(false);
  };

  return (
    <div className="flex h-[52px] w-full shrink-0 flex-row justify-between gap-2 px-[16px] py-2">
      <div className="flex items-center gap-2">
        <TooltipButton
          variant="ghost"
          onClick={deselectAll}
          buttonClassName="hover:text-blue hover:bg-blue-light h-auto w-auto p-1 text-black"
          tooltipContent="Clear selection"
        >
          <X strokeWidth={2} className="h-5 w-5" />
        </TooltipButton>
        <span className="text-sm font-medium text-gray-600">
          {selectedCards.size} / {count} selected
        </span>
      </div>
      <div className="flex gap-2">
        <TooltipButton
          variant="secondary"
          buttonClassName="p-2"
          onClick={() => setIsCommandOpen(true)}
          tooltipContent="Assign to agent"
        >
          <CircleArrowOutUpRight className="h-4 w-4" strokeWidth={2.5} />
        </TooltipButton>
        <TooltipButton
          variant="default"
          buttonClassName="p-2"
          onClick={handleCloseConversations}
          tooltipContent="Close"
        >
          <PanelBottomClose className="h-4 w-4" strokeWidth={2.5} />
        </TooltipButton>
      </div>
      <CommandDialog open={isCommandOpen} onOpenChange={setIsCommandOpen}>
        <CommandInput placeholder="Search for an agent..." />
        <CommandList>
          <CommandEmpty>No agents found.</CommandEmpty>
          <CommandGroup>
            {users.map((user) => (
              <CommandItem
                key={user.id}
                onSelect={() => handleAssignConversations(user.id)}
              >
                {user.fullName}
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </div>
  );
}

function getViewTitle({
  view,
  users,
  views,
}: {
  view: string | undefined;
  users: { id: string; fullName: string }[];
  views: { id: string; title: string }[];
}) {
  if (!view) return "Conversations";

  const { success } = z.string().uuid().safeParse(view);

  if (success) {
    const customView = views.find((v) => v.id === view);

    return customView?.title ?? "Conversations";
  }

  switch (view) {
    case "search":
      return "Search Results";
    case "your-inbox":
      return "Your Inbox";
    case "unassigned":
      return "Unassigned";
    case "handed-off":
      return "Human";
    case "bot":
      return "Bot";
    case "spam":
      return "Spam";
    case "threads":
      return "Threads";
    default:
      if (view.startsWith("user-")) {
        const user = users.find((u) => u.id === view.replace("user-", ""));
        return user ? `${user.fullName}'s Inbox` : "Conversations";
      }
      return "Conversations";
  }
}
