import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "../ui/button";
import { useNavigate } from "@tanstack/react-router";

interface PaginationProps {
  currentPage: number;
  pageCount: number;
  baseUrl: string;
}

export function Pagination({
  currentPage,
  pageCount,
  baseUrl,
}: PaginationProps) {
  const navigate = useNavigate();

  if (pageCount <= 1) return null;

  const handlePageChange = (newPage: number) => {
    void navigate({
      to: baseUrl,
      search: { pageNumber: newPage },
      replace: true,
    });
  };

  return (
    <div className="flex items-center gap-2">
      <Button
        variant="outline"
        size="icon"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage <= 1}
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>

      <div className="flex items-center gap-1">
        <span className="text-sm">
          Page {currentPage} of {pageCount}
        </span>
      </div>

      <Button
        variant="outline"
        size="icon"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage >= pageCount}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  );
}
