import { Switch } from "@dashboard/common/ui/switch";
import { useState, useEffect } from "react";
import { useUpdatePermissions } from "../-hooks/useUpdatePermissions";

interface AdminPermissionsProps {
  userId: string;
  isManager: boolean;
  isDisabled?: boolean;
}

export function AdminPermissions({
  userId,
  isManager,
  isDisabled = false,
}: AdminPermissionsProps) {
  const [isAdmin, setIsAdmin] = useState(isManager);

  const { updatePermissions } = useUpdatePermissions();

  const handleToggle = (checked: boolean) => {
    updatePermissions({ id: userId, isManager: checked });
    setIsAdmin(checked);
  };

  useEffect(() => {
    setIsAdmin(isManager);
  }, [isManager]);

  return (
    <div className="w-30 flex flex-row items-center gap-2">
      <Switch
        checked={isAdmin}
        onCheckedChange={(value) => handleToggle(value)}
        disabled={isDisabled}
      />
      <span>{isAdmin ? "Admin" : "Basic"}</span>
    </div>
  );
}
