import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsxm from "@chatbot/utils/clsxm";
import { baseUrl } from "@chatbot/utils/baseUrl";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import { useConfig } from "@chatbot/hooks/config.hooks";
import { KeyboardEvent } from "react";

export function TopBar() {
  const config = useConfig();
  const { loadedIntoContainer, isMobile, openSettings, closeChatWindow } =
    useUiState();

  const handleKeyOpenSettings = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      openSettings();
    }
  };

  const handleKeyCloseChatWindow = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      closeChatWindow();
    }
  };

  const isPlayground =
    window.location.host === "octocom.ai" ||
    window.location.host === "www.octocom.ai" ||
    window.location.host === "localhost:3500" ||
    window.location.host === "app.octocom.ai" ||
    window.location.host === "localhost:3200";

  const urlParams = new URLSearchParams(window.location.search);
  const hasOctocomDemoParam = urlParams.has("octocom-demo");

  return (
    <div
      className="flex h-[65px] items-center justify-between rounded-t-xl border-b border-[#0000001a] px-6 py-4"
      style={{
        backgroundColor: config.topBarBackgroundColor ?? undefined,
        borderWidth: !config.enableTopBarBorder ? "0" : undefined,
      }}
    >
      <div className="flex flex-row items-center gap-3">
        {isMobile &&
          !loadedIntoContainer &&
          (hasOctocomDemoParam || isPlayground) && (
            <div
              tabIndex={0}
              aria-label="Settings"
              className="cursor-pointer rounded-lg hover:bg-gray-100"
              onClick={() => openSettings()}
              onKeyDown={handleKeyOpenSettings}
            >
              <EllipsisVerticalIcon
                className="h-7 w-7 text-gray-800"
                style={{
                  color: config.topBarIconColor ?? undefined,
                }}
              />
            </div>
          )}

        <div>
          {/* eslint-disable-next-line */}
          <img
            className={clsxm(
              "mx-auto my-auto h-8",
              config.topBarTitle ? "w-8" : "",
            )}
            src={config.topBarImageFileUrl ?? `${baseUrl}/bot.svg`}
            alt=""
          />
        </div>

        {(!!config.topBarTitle || !config.topBarImageFileUrl) && (
          <div className="flex h-fit flex-col">
            <h5
              className="font-semibold leading-none text-[#1a1a1a]"
              style={{
                color: config.topBarTextColor ?? undefined,
              }}
            >
              {config.topBarTitle ?? "Chat with us"}
            </h5>
          </div>
        )}
      </div>
      {!isMobile && !loadedIntoContainer ? (
        <div className="flex items-center gap-2">
          {(hasOctocomDemoParam || isPlayground) && (
            <div
              tabIndex={0}
              aria-label="Settings"
              className="cursor-pointer rounded-lg hover:bg-gray-100"
              onKeyDown={handleKeyOpenSettings}
              onClick={() => openSettings()}
            >
              <EllipsisVerticalIcon
                className="h-7 w-7 text-gray-800"
                style={{
                  color: config.topBarIconColor ?? undefined,
                }}
              />
            </div>
          )}
          <div
            tabIndex={0}
            aria-label="Close chat window"
            className="cursor-pointer rounded-lg hover:bg-gray-100"
            onClick={closeChatWindow}
            onKeyDown={handleKeyCloseChatWindow}
          >
            <XMarkIcon
              className="h-7 w-7 text-gray-800"
              style={{
                color: config.topBarIconColor ?? undefined,
              }}
            />
          </div>
        </div>
      ) : !loadedIntoContainer ? (
        <div
          tabIndex={0}
          aria-label="Close chat window"
          className="cursor-pointer rounded-lg hover:bg-gray-100"
          onClick={closeChatWindow}
          onKeyDown={handleKeyCloseChatWindow}
        >
          <XMarkIcon
            className="h-7 w-7 text-gray-800"
            style={{
              color: config.topBarIconColor ?? undefined,
            }}
          />
        </div>
      ) : hasOctocomDemoParam || isPlayground ? (
        <div
          tabIndex={0}
          aria-label="Settings"
          className="cursor-pointer rounded-lg hover:bg-gray-100"
          onKeyDown={handleKeyOpenSettings}
          onClick={() => openSettings()}
        >
          <EllipsisVerticalIcon
            className="h-7 w-7 text-gray-800"
            style={{
              color: config.topBarIconColor ?? undefined,
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
